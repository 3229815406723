import {
  FETCH_SURVEYS,
  FETCH_SURVEYS_DONE,
  FETCH_SURVEYS_FAILED,
  FETCH_SURVEY_RESPONSES,
  FETCH_SURVEY_RESPONSES_DONE,
  FETCH_SURVEY_RESPONSES_FAILED,
  POST_SURVEY_RESPONSES,
  POST_SURVEY_RESPONSES_DONE,
  POST_SURVEY_RESPONSES_FAILED
} from '../actions/types';

const middleware = store => next => action => {
  switch (action.type) {
    case FETCH_SURVEYS:
      next(action);
      return fetchSurveys(next, store.dispatch, action.payload.token, action.payload?.user?._id);
    case FETCH_SURVEY_RESPONSES:
      next(action);
      return fetchResponsesBySurvey(next, action.payload.token, action.payload.patientId, action.payload.surveyId);
    case POST_SURVEY_RESPONSES:
      next(action);
      return sendSurveyResponses(next, action.payload.token, action.payload.responses, action.payload.patientId, action.payload.surveyId);
    default:
      return next(action);
  }
};

const sendSurveyResponses = (next, token, responses, patientId, surveyId) => {
  fetch(`/api/proxy/survey/survey/responses/${patientId}/${surveyId}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      responses
    })
  })
    .then(async res => {
      if (res.ok) {
        next({type: POST_SURVEY_RESPONSES_DONE});
      } else {
        console.error(`Failed to post survey responses for ${surveyId}: ${res.statusText}`);
        next({type: POST_SURVEY_RESPONSES_FAILED});
      }
    })
    .catch(error => {
      console.error(`Failed to post survey responses for ${surveyId} : ${error}`);
      next({type: POST_SURVEY_RESPONSES_FAILED});
    });
};

const fetchSurveys = (next, dispatch, token, patientId) => {
  fetch('/api/proxy/survey/survey/available', {
    credentials: 'include',
    headers: {
      Authorization: token
    }
  })
    .then(async res => {
      if (res.ok) {
        const surveys = await res.json();

        surveys.forEach(survey => {
          dispatch({
            type: FETCH_SURVEY_RESPONSES,
            payload: {
              surveyId: survey._id,
              patientId,
              token
            }
          });
        });

        next({
          type: FETCH_SURVEYS_DONE,
          payload: surveys
        });
      } else {
        console.error(`Failed to fetch surveys : ${res.statusText}`);
        next({
          type: FETCH_SURVEYS_FAILED,
          payload: res.statusText
        });
      }
    })
    .catch(error => {
      console.error(`Failed to fetch surveys : ${error}`);
      next({
        type: FETCH_SURVEYS_FAILED,
        payload: error
      });
    });
};

const fetchResponsesBySurvey = (next, token, patientId, surveyId) => {
  fetch(`/api/proxy/survey/survey/responses/${patientId}/${surveyId}`, {
    credentials: 'include',
    headers: {
      Authorization: token
    }
  })
    .then(async res => {
      if (res.ok) {
        const surveyResponses = await res.json();
        next({
          type: FETCH_SURVEY_RESPONSES_DONE,
          payload: {
            responses: surveyResponses.responses,
            surveyId,
            endDate: surveyResponses.endDate
          }
        });
      } else {
        console.error(`Failed to get survey responses for ${surveyId}: ${res.statusText}`);
        next({
          type: FETCH_SURVEY_RESPONSES_FAILED,
          payload: res.statusText
        });
      }
    })
    .catch(error => {
      console.error(`Failed to get survey responses for ${surveyId} : ${error}`);
      next({
        type: FETCH_SURVEY_RESPONSES_FAILED,
        payload: error
      });
    });
};

export default middleware;
