import moment from 'moment';
import React, {useState, useEffect} from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import Warning from '@material-ui/icons/Error';

import frLocale from 'date-fns/locale/fr';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';

import styles from './birthdate-picker.module.scss';

const localeMap = {
  en: enLocale,
  fr: frLocale
};

const formatMap = {
  en: 'MM/dd/yyyy',
  fr: 'dd/MM/yyyy'
};

const BirthdatePicker = ({locale, onChange, value, format, label, error, invalidDateMessage, minDateMessage, maxDateMessage, mobile}) => {
  const [date, setDate] = useState(null);
  const [pickerError, setPickerError] = useState(false);

  useEffect(() => {
    if (value) {
      const value_ = value ? moment(value, format) : moment();
      setDate(value_.toDate());
    }
  }, [format, value]);

  const handlePickerError = error => {
    setPickerError(error);
  };

  const handleDateChange = newDate => {
    if (!newDate) {
      return (onChange(''));
    }

    setDate(newDate);
    const dateInfo = new Date(newDate);
    const day = dateInfo.getDate();
    const month = dateInfo.getMonth() + 1;
    const year = dateInfo.getFullYear();
    const newBirthdate = year + (month < 10 ? '0' : '') + month + (day < 10 ? '0' : '') + day;
    if (!Number.isNaN(newBirthdate)) {
      return (onChange(newBirthdate));
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
      <KeyboardDatePicker
        disableFuture
        className={`dateInput ${mobile ? styles.MobileBirthdatePicker : styles.BirthdatePicker}`}
        value={date || null}
        label={<span>{label} <span className="red">*</span></span>}
        placeholder={label}
        error={error}
        format={formatMap[locale]}
        InputAdornmentProps={{position: 'start'}}
        variant="inline"
        maxDateMessage={maxDateMessage}
        minDateMessage={minDateMessage}
        invalidDateMessage={invalidDateMessage}
        InputProps={{
          dataTestId: 'birthdate-picker',
          endAdornment: (
            <>
              {(pickerError || error) &&
              <Warning className="red internError"/>}
            </>
          )
        }}
        onChange={newDate => handleDateChange(newDate)}
        onError={handlePickerError}
      />
    </MuiPickersUtilsProvider>
  );
};

export default BirthdatePicker;
