
import PreadmissionPanelComponent from './preadmission-panel-component';

const preadmissionPanel = {
  title: 'myPreadmission',
  icon: 'assignment',
  identity: 'preadmission',
  mainComponent: PreadmissionPanelComponent,
  customIcon: {
    params: 'user.deadlinePassed',
    condition: 1,
    icon: 'info',
    class: 'warningIcon'
  },
  customIconAlt: {
    params: 'user.deadlinePassed',
    condition: 2,
    icon: 'info',
    class: 'alertIcon'
  },
  customAction: () => {
    return {type: 'PREADMISSION_PARENT_ACTION', value: true};
  },
  pages: []
};

export default preadmissionPanel;
