import React from 'react';
import {useIntl} from 'react-intl';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Link} from 'react-router-dom';

const SmartBreadCrumbs = ({links}) => {
  const {formatMessage: f} = useIntl();
  const matches = useMediaQuery('(min-width:600px)');
  const startLinks = links.slice(0, links.length - 1);
  return (
    <>
      {matches && (
        <Breadcrumbs aria-label="breadcrumb">

          {startLinks.map(link => (
            <Link key={`link-${Math.random()}`} data-testid={`breadcrumb-${link.text}`} color="inherit" to={link.path}>
              {f({id: link.text})}
            </Link>
          ))}

          <Link data-testid={`breadcrumb-${links[links.length - 1].text}`} color="inherit" to={links[links.length - 1].path}>
            {f({id: links[links.length - 1].text})}
          </Link>

        </Breadcrumbs>
      )}
      {!matches && (
        <Breadcrumbs aria-label="breadcrumb">

          <Link color="inherit" to={links[0].path}>
            <span>...</span>
          </Link>

          <Link color="inherit" to={links[links.length - 1].path}>
            {f({id: links[links.length - 1].text})}
          </Link>

        </Breadcrumbs>
      )}
    </>
  );
};

export default SmartBreadCrumbs;
