import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {get} from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowBack from '@material-ui/icons/ArrowBack';
import InputAdornment from '@material-ui/core/InputAdornment';
import Warning from '@material-ui/icons/Error';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PhoneInput from 'react-phone-input-2';

import {checkEmail, signup, checkCode, fetchGTU, fetchGDPR, fetchActivationCodeMsg} from '../../../actions/authentication';
import {setUserFromAC} from '../../../actions/user';
import {error, info} from '../../../actions';

import FormInput from '../../shared/FormInput/form-input';
import ActivationCodeInput from '../ActivationCodeInput/activation-code-input';
import ContractIframe from '../ContractIframe/contract-iframe';

import styles from './signup.module.scss';
import {convertTextInMultilines} from '../../shared/convert-text-in-multilines';

const BlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#2196F3'
    }
  },
  checked: {}
})(props => <Checkbox {...props}/>);

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hospital: this.props.hospital || {},
      hospID: this.props.hospID || null,
      loading: false,
      valid: false,
      captcha: null,
      mail: '',
      password: '',
      passwordConfirm: '',
      personnalTph: '',
      countryCodePersonnalTph: '+33',
      code: '',
      name: '',
      validateGTU: false,
      validateRGPD: false,
      alert: {
        open: false,
        title: '',
        text: ''
      },
      message: null,
      errors: [],
      hasActivationCode: false,
      step: 1,
      gtu: {},
      gtuVisible: false,
      gdpr: {},
      gdprVisible: false,
      gtuMSVisible: false,
      customizedActivationCodeMsg: '',
      fromVad: props?.history?.location?.state?.fromVad || {}
    };

    this.passwordOk = password => {
      const pwdRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\\d).{8,}$');
      const isContentOk = pwdRegex.test(password);
      if (!password || password.length < 8 || !isContentOk) {
        return false;
      }

      return true;
    };

    this.isValid = () => {
      const personnalTphWithoutCodeFormat = this.state.personnalTph.substring(this.state.countryCodePersonnalTph.length);

      let errors = [];
      if (
        !this.state.mail.length ||
        !this.state.password.length ||
        !this.state.passwordConfirm.length

      ) {
        this.setState({valid: false});
      } else if (this.state.password !== this.state.passwordConfirm) {
        this.setState({
          valid: false
        });
        errors = [...errors, 'passwordNotMatch'];
      } else if (!this.passwordOk(this.state.password)) {
        this.setState({
          valid: false
        });
        errors = [...errors, 'emptyPassword'];
      } else if (!this.state.captcha) {
        this.setState({valid: false});
      } else if (this.state.personnalTph && personnalTphWithoutCodeFormat && personnalTphWithoutCodeFormat.length < 9) {
        this.setState({valid: false});
        errors = [...errors, 'wrongTelFormat'];
      } else {
        if (this.state.message) {
          this.setMessage(null);
        }

        if (!this.state.validateGTU || !this.state.validateRGPD) {
          this.setState({valid: false});
        } else {
          this.setState({valid: true});
        }
      }

      this.setState({errors});

      if (this.state.step === 2 && !this.state.code.length && !this.state.name.length) {
        this.setState({valid: false});
      } else if (this.state.step === 2 && this.state.code.length && this.state.name.length) {
        this.setState({valid: true});
      }
    };

    this.setMessage = value => {
      this.setState({message: value});
    };

    this.setCaptcha = value => {
      this.setState({captcha: value});
    };

    this.handleCode = event => {
      if (event) {
        this.setState({code: event.target.value});
      }
    };

    this.handleName = event => {
      if (event) {
        this.setState({name: event.target.value});
      }
    };

    this.checkForm = () => {
      let message;
      let errors = [];

      if (!this.state.hospID) {
        return;
      }

      const mailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.state.mail.match(mailRegexp)) {
        message = 'invalidMail';
        errors = [...errors, 'invalidMail'];
      }

      if (!this.passwordOk(this.state.password)) {
        message = 'emptyPassword';
        errors = [...errors, 'emptyPassword'];
      }

      if (this.state.password !== this.state.passwordConfirm) {
        message = 'passwordNotMatch';
        errors = [...errors, 'passwordNotMatch'];
      }

      if (message) {
        this.setState({message, errors});
      }
    };

    this.validate = async () => {
      this.checkForm();

      this.setState({errors: []});

      const isMailOk = await this.props.checkEmail(
        this.state.hospID,
        this.state.mail
      );
      if (isMailOk.result === 'ERROR') {
        if (isMailOk.msg === 'mailAlreadyUse') {
          this.setState({message: 'mailAlreadyUse', loading: false});
        }

        return;
      }

      if (this.state.step === 1 && this.state.hasActivationCode) {
        this.setState({step: 2});
        return;
      }

      if (this.state.step === 2 && this.state.hasActivationCode) {
        const codeOk = await this.props.checkCode(
          this.state.hospID,
          this.state.name,
          this.state.code
        );
        if (codeOk.result === 'ERROR') {
          this.setState({message: codeOk.msg, loading: false});

          return;
        }
      }

      const signUpForm = {
        mail: this.state.mail.toLowerCase(),
        password: this.state.password,
        hospID: get(this, 'state.hospID', null),
        type: 'customer',
        personnalTph: this.state.personnalTph.trim(),
        gtu: this.state.validateGTU ? new Date() : false,
        gdpr: this.state.validateRGPD ? new Date() : false,
        fromVad: Boolean(Object.keys(this.state.fromVad).length > 0)
      };

      if (this.state.hasActivationCode) {
        signUpForm.activationCode = this.state.code;
      }

      this.setState({loading: true});
      const response = await this.props.signup(signUpForm);
      if (response.result === 'ERROR') {
        if (response.msg === 'mailAlreadyUse') {
          this.setState({message: 'mailAlreadyUse', loading: false});
        } else {
          this.setState({
            loading: false
          });
        }

        this.props.error(this.props.intl.formatMessage({id: response.msg || 'message'}));
      } else if (response.status === 200) {
        this.setState({
          alert: {
            open: true,
            title: this.props.intl.formatMessage({
              id: response.title || 'accountCreated'
            }),
            text2: response.msgAdd ?
              this.props.intl.formatMessage({id: response.msgAdd}) :
              null,
            confirmText: this.props.intl.formatMessage({
              id: response.confirmText || 'Ok'
            }),
            redirect: true
          },
          loading: false
        });
        if (this.state.code.length > 0 && response.userId && response.token) {
          await this.props.setUserFromAC(
            this.state.code,
            response.userId,
            response.token
          );
        }
      }
    };
  }

  handleClose(redirect) {
    this.setState({
      alert: {
        open: false,
        title: '',
        text: '',
        text2: null,
        confirmText: 'Ok',
        redirect: null
      }
    });
    if (redirect) {
      this.props.onSignup();
    }
  }

  handleBack() {
    this.setState({step: 1});
  }

  handleMailChange(event) {
    if (event) {
      this.setState({mail: event.target.value});
    }
  }

  handlePasswordChange(event) {
    if (event) {
      this.setState({password: event.target.value});
    }
  }

  handleValidateGTU(event) {
    if (event) {
      this.setState({validateGTU: event.target.checked});
    }
  }

  handleValidateRGPD(event) {
    if (event) {
      this.setState({validateRGPD: event.target.checked});
    }
  }

  handlePasswordConfirmChange(event) {
    if (event) {
      this.setState({passwordConfirm: event.target.value});
    }
  }

  setHasActivationCode(value) {
    this.setState({hasActivationCode: value, code: '', name: ''});
  }

  setGtuVisible(value) {
    this.setState({gtuVisible: value});
  }

  setGdprVisible(value) {
    this.setState({gdprVisible: value});
  }

  setGtuMSVisible(value) {
    this.setState({gtuMSVisible: value});
  }

  componentDidUpdate(props, state) {
    if (
      state.password !== this.state.password ||
      state.passwordConfirm !== this.state.passwordConfirm ||
      state.mail !== this.state.mail ||
      state.validateGTU !== this.state.validateGTU ||
      state.validateRGPD !== this.state.validateRGPD ||
      state.step !== this.state.step ||
      state.name !== this.state.name ||
      state.code !== this.state.code ||
      state.captcha !== this.state.captcha ||
      state.personnalTph !== this.state.personnalTph
    ) {
      this.isValid();
    }
  }

  componentDidMount() {
    console.log('From VAD properties :', this.state.fromVad);

    // If we have activationCode from VAD, we set it
    if (Object.keys(this.state.fromVad).length > 0) {
      this.setHasActivationCode(true);
      this.setState({
        code: this.state.fromVad.activationCode,
        nameInput: this.state.fromVad.lastname,
        name: this.state.fromVad.lastname
      });
    }

    if (this.props.hospID) {
      fetchGTU(this.props.hospID).then(gtu => {
        if (gtu) {
          this.setState({gtu: {...gtu.data, type: 'gtu'}});
        }
      });
      fetchGDPR(this.props.hospID).then(gdpr => {
        if (gdpr) {
          this.setState({gdpr: {...gdpr.data, type: 'gdpr'}});
        }
      });
      fetchActivationCodeMsg(this.props.hospID).then(({data}) => {
        if (data) {
          this.setState({customizedActivationCodeMsg: data});
        }
      });
    }
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    return (
      <div className="page main login" data-testid="signupPage">
        <div className="pageActions withTop">
          <Link data-testid="signup-back" className="backButton" to="/login">
            <ArrowBack/> <span data-testid="signup-return" className="label">{f({id: 'return'})}</span>
          </Link>
        </div>
        <div className="pageContent noTop flex column">
          <div className="header">
            {this.state.step === 1 && (
              <>
                <h3>{f({id: 'accountCreation'})}</h3>
                {this.state.hospital?.config?.subscriptionMessage && <p style={{maxWidth: '50%'}}>{this.state.hospital.config.subscriptionMessage}</p>}
              </>
            )}
            {this.state.step === 2 && (
              <h3 data-testid="signup-text1">
                {f({id: 'accountCreation'})} - {f({id: 'activationCode'})}
              </h3>
            )}
          </div>

          <div className="loginForm self-center width30">
            <form noValidate className="root" autoComplete="off">
              {this.state.step === 1 && (
                <>
                  <FormInput
                    required
                    data-testid="signup-input1"
                    label={f({id: 'email'})}
                    placeholder={f({id: 'email'})}
                    type="text"
                    id="login"
                    error={
                      this.state.errors.includes('invalidMail') ||
                      this.state.message === 'mailAlreadyUse'
                    }
                    value={this.state.mail}
                    InputProps={{
                      endAdornment: (
                        <>
                          {this.state.errors.includes('invalidMail') && (
                            <InputAdornment position="end">
                              <Warning className="red"/>
                            </InputAdornment>
                          )}
                        </>
                      )
                    }}
                    onChange={event => this.handleMailChange(event)}
                  />
                  {this.state.errors.includes('invalidMail') && (
                    <span className={styles.ErrorMsg}>
                      {f({id: 'invalidMail'})}
                    </span>
                  )}
                  {this.state.message === 'mailAlreadyUse' && (
                    <span className={styles.ErrorMsg}>
                      {f({id: 'mailAlreadyUse'})}
                    </span>
                  )}

                  <br/>
                  <FormInput
                    required
                    data-testid="signup-input2"
                    label={f({id: 'password'})}
                    placeholder={f({id: 'password'})}
                    type="password"
                    id="password"
                    error={this.state.errors.includes('emptyPassword')}
                    value={this.state.password}
                    InputProps={{
                      endAdornment: (
                        <>
                          {this.state.errors.includes('emptyPassword') && (
                            <InputAdornment position="end">
                              <Warning className="red"/>
                            </InputAdornment>
                          )}
                        </>
                      )
                    }}
                    onChange={event => this.handlePasswordChange(event)}
                  />
                  <span
                    data-testid="signup-text2"
                    className={
                      this.state.errors.includes('emptyPassword') ?
                        styles.ErrorMsg :
                        styles.InfoMsg
                    }
                  >
                    {f({id: 'signupNote'})}
                  </span>
                  <br/>
                  <FormInput
                    required
                    data-testid="signup-input3"
                    label={f({id: 'passwordConfirm'})}
                    placeholder={f({id: 'passwordConfirm'})}
                    type="password"
                    id="passwordConfirm"
                    value={this.state.passwordConfirm}
                    error={this.state.errors.includes('passwordNotMatch')}
                    InputProps={{
                      endAdornment: (
                        <>
                          {this.state.errors.includes('passwordNotMatch') && (
                            <InputAdornment position="end">
                              <Warning className="red"/>
                            </InputAdornment>
                          )}
                        </>
                      )
                    }}
                    onChange={event => this.handlePasswordConfirmChange(event)}
                  />
                  {this.state.errors.includes('passwordNotMatch') && (
                    <span data-testid="signup-text3" className={styles.ErrorMsg}>
                      {f({id: 'passwordNotMatch'})}
                    </span>
                  )}
                  <br/>
                  <br/>

                  {
                    this.state?.hospital?.smsRegistrationPath?.enabled && (
                      <>
                        <label className={styles.PhoneLabel}>
                          {f({id: 'tel'})}
                        </label>
                        <PhoneInput
                          className={styles.PhoneNumber}
                          placeholder={f({id: 'tel'})}
                          label={f({id: 'tel'})}
                          country="fr"
                          autoFormat={false}
                          containerClass="react-tel-input"
                          inputProps={{
                            dataTestId: 'phone-input-signup'
                          }}
                          value={this.state.personnalTph}
                          onChange={(value, country) => {
                            const personnalTph = value[0] === '+' ? value : `+${value}`;
                            const countryCodePersonnalTph = country.format[0] + country.dialCode;
                            this.setState({personnalTph, countryCodePersonnalTph});
                          }}/>
                        {this.state.errors.includes('wrongTelFormat') && <div className={styles.ErrorMsg}>{f({id: 'wrongTelFormat'})}</div>}
                      </>
                    )
                  }
                  <br/>

                  <span data-testid="signup-text4" className={styles.ActivationCodeReq}>
                    {f({id: 'activationCodeReq'})}
                  </span>
                  <div className={styles.ActivationCodeDiv}>
                    <Button
                      data-testid="signup-button1"
                      id="activation-code-yes"
                      variant="outlined"
                      color="primary"
                      className={
                        this.state.hasActivationCode ?
                          styles.Selected :
                          styles.ActivationButton
                      }
                      onClick={() => this.setHasActivationCode(true)}
                    >
                      {f({id: 'activationCodeYes'})}
                    </Button>
                    <Button
                      data-testid="signup-button2"
                      disabled={this.state?.fromVad?.activationCode}
                      id="activation-code-no"
                      variant="outlined"
                      color="primary"
                      className={
                        !this.state.hasActivationCode ?
                          styles.Selected :
                          styles.ActivationButton
                      }
                      onClick={() => this.setHasActivationCode(false)}
                    >
                      {f({id: 'activationCodeNo'})}
                    </Button>
                  </div>
                  {this.state.customizedActivationCodeMsg?.length > 0 &&
                    <div className={styles.ActivationCodeMsgConteneur}>
                      <InfoOutlinedIcon className="grey"/>
                      {convertTextInMultilines(this.state.customizedActivationCodeMsg)}
                    </div>}
                  <br/>
                  <div className={styles.CGU}>
                    <BlueCheckbox
                      inputProps={{
                        'data-testid': 'signup-checkbox1'
                      }}
                      checked={this.state.validateGTU}
                      onChange={event => this.handleValidateGTU(event)}
                    />{' '}
                    <div data-testid="signup-text5" className={styles.CGUText}>
                      {f({id: 'legalCGUXP'})}
                      <span>&nbsp;</span>
                      <span
                        data-testid="signup-text6"
                        className={styles.Link}
                        onClick={() => this.setGtuVisible(true)}
                      >
                        {f({id: 'legalCGUXPLink'})}&nbsp;
                        {this.props.appName}&nbsp;
                      </span>
                      {this.props.hasMultimedia && (
                        <>
                          {f({id: 'legalCGUMS'})}
                          <span>&nbsp;</span>
                          <span
                            data-testid="signup-text7"
                            className={styles.Link}
                            onClick={() => this.setGtuMSVisible(true)}
                          >
                            {f({id: 'legalCGUMSLink'})}
                          </span>
                        </>
                      )}
                      .<span data-testid="signup-text8" className="red">*</span>
                    </div>
                  </div>
                  <div className={styles.CGU}>
                    <BlueCheckbox
                      inputProps={{
                        'data-testid': 'signup-checkbox2'
                      }}
                      checked={this.state.validateRGPD}
                      onChange={event => this.handleValidateRGPD(event)}
                    />
                    <div data-testid="signup-text9" className={styles.CGUText}>
                      {f({id: 'legalRGPD'})}
                      <span>&nbsp;</span>
                      <span
                        data-testid="signup-text10"
                        className={styles.Link}
                        onClick={() => this.setGdprVisible(true)}
                      >
                        {f({id: 'legalRGPDLink'})}<span data-testid="signup-text11" className="red">*</span>
                      </span>
                    </div>
                  </div>
                </>
              )}

              {this.state.step === 2 && (
                <ActivationCodeInput
                  nameInput={this.state.nameInput}
                  codeInput={this.state.code}
                  message={this.state.message}
                  onCodeChange={this.handleCode}
                  onNameChange={this.handleName}
                />
              )}

              <br/>

              <ReCAPTCHA
                data-testid="signup-captcha"
                hl={this.props.lang.slice(0, 2)}
                sitekey="6LcPcdEUAAAAAJjJEaH7lCEezlye8BPZiEMrVfi7"
                onChange={this.setCaptcha}
              />
              <br/>
              <div style={{display: 'flex', alignItems: 'baseline'}}>
                {this.state.step === 2 && (
                  <Button
                    className={styles.BackButton}
                    color="primary"
                    onClick={() => this.handleBack()}
                  >
                    {f({id: 'return'})}
                  </Button>
                )}

                <Button
                  data-testid="signup-button3"
                  id="validate"
                  className="flat blue noTop"
                  disabled={!this.state.valid}
                  onClick={this.validate}
                >
                  {f({id: 'createAccount'})}
                </Button>
              </div>
            </form>
            {this.state.loading && <CircularProgress className="loading"/>}
          </div>
          <div className={styles.InfoMsg}>
            <span data-testid="signup-mandatory" className="red">*&nbsp;</span>{f({id: 'mandatoryInformation'})}
          </div>
        </div>
        <Dialog
          open={this.state.alert.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={() => this.handleClose()}
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.alert.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alert.text}
            </DialogContentText>
            {this.state.alert.text2 && (
              <DialogContentText id="alert-dialog-description-more">
                {this.state.alert.text2}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="signup-button4"
              className="buttonColor blue"
              color="primary"
              onClick={() => this.handleClose(this.state.alert.redirect)}
            >
              {this.state.alert.confirmText || 'Ok'}
            </Button>
          </DialogActions>
        </Dialog>
        <ContractIframe
          item={this.state.gtu}
          hospID={this.state.hospID}
          visible={this.state.gtuVisible}
          onClose={() => this.setGtuVisible(false)}
        />
        <ContractIframe
          item={this.state.gdpr}
          hospID={this.state.hospID}
          visible={this.state.gdprVisible}
          onClose={() => this.setGdprVisible(false)}
        />
        <ContractIframe
          item={{url: `/api/proxy/si/${this.state.hospID}/contracts/fr_FR-eula`}}
          visible={this.state.gtuMSVisible}
          onClose={() => this.setGtuMSVisible(false)}
        />
      </div>
    );
  }
}

export default injectIntl(
  connect(null, {
    checkEmail,
    checkCode,
    signup,
    setUserFromAC,
    info,
    error
  })(Signup)
);
