import React, {useEffect, useState} from 'react';
import {Link as ButtonLink} from 'react-router-dom';
import {Breadcrumbs, Divider} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {useIntl} from 'react-intl';

import {fetchGTU, fetchGDPR} from '../../../actions/authentication';
import styles from './cgu-panel.module.scss';

import ContractIframe from '../../authentication/ContractIframe/contract-iframe';
import ViewButton from '../../shared/view-button';

const CGUPanel = ({hospital, applicationName, authorized}) => {
  const {formatMessage: f} = useIntl();
  const [gtu, setGtu] = useState({});
  const [gdpr, setGdpr] = useState({});
  const [gdprVisible, setGdprVisible] = useState(false);
  const [gtuVisible, setGtuVisible] = useState(false);
  const [gtuMSVisible, setGTUMSVisible] = useState(false);

  const isMediaAllowed = authorized.includes('multimedia') && authorized.includes('payment');

  function fetchData() {
    fetchGTU(hospital.hospID).then(gtu => {
      if (gtu) {
        setGtu({...gtu.data, type: 'gtu'});
      }
    });

    fetchGDPR(hospital.hospID).then(gdpr => {
      if (gdpr) {
        setGdpr({...gdpr.data, type: 'gdpr'});
      }
    });
  }

  useEffect(() => {
    if (hospital) {
      return fetchData();
    }
  }, [hospital]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="pageActions">
        <Breadcrumbs aria-label="breadcrumb">
          <ButtonLink color="inherit" to="/personalData">
            {f({id: 'myAccount'})}
          </ButtonLink>
          <ButtonLink
            color="textPrimary"
            to="/personalData/legal"
            aria-current="page"
          >
            {f({id: 'legal'})}
          </ButtonLink>
        </Breadcrumbs>
        <ButtonLink className="smallScreenBack" to="/personalData">
          <ArrowBack/>
        </ButtonLink>
      </div>
      <div className={styles.CGUItem}>
        <span className={styles.Title}>{f({id: 'gtu'})} {applicationName}</span>
        <ViewButton
          matchSize="600"
          icon="visibility"
          text={f({id: 'consult'})}
          onClick={() => setGtuVisible(true)}
        />

      </div>
      <Divider/>
      {isMediaAllowed && (
        <>
          <div className={styles.CGUItem}>
            <span className={styles.Title}>{f({id: 'legalCGUMSLink'})}</span>
            <ViewButton
              matchSize="600"
              icon="visibility"
              text={f({id: 'consult'})}
              onClick={() => setGTUMSVisible(true)}
            />
          </div>
          <Divider/>
        </>
      )}
      <Divider/>
      <div className={styles.CGUItem}>
        <span className={styles.Title}>{f({id: 'gdpr'})}</span>
        <ViewButton
          matchSize="600"
          icon="visibility"
          text={f({id: 'consult'})}
          onClick={() => setGdprVisible(true)}
        />
      </div>

      <ContractIframe
        item={gtu}
        hospID={hospital.hospID}
        visible={gtuVisible}
        onClose={() => setGtuVisible(false)}
      />
      <ContractIframe
        item={{url: `/api/proxy/si/${hospital.hospID}/contracts/fr_FR-eula`}}
        visible={gtuMSVisible}
        onClose={() => setGTUMSVisible(false)}
      />
      <ContractIframe
        item={gdpr}
        hospID={hospital.hospID}
        visible={gdprVisible}
        onClose={() => setGdprVisible(false)}
      />

    </>
  );
};

export default CGUPanel;
