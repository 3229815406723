import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/InfoRounded';
import moment from 'moment';

import SectionCard from './SectionCard/section-card';
import FormInput from './Field/form-input';
import UploaderForm from './UploaderForm/uploader-form';
import ActionBar from './ActionBar/action-bar';
import Header from './Header/header';
import SavingDialog from './SavingDialog/saving-dialog';

import {fetchPreadmission, saveSection, fetchProgress, fetchUserPreadmission} from '../../actions/preadmission';
import {error, success} from '../../actions';
import usePrevious from '../../hooks/use-previous';

import styles from './preadmission-panel-component.module.scss';

export default function PreadmissionPanel({user, onPageSelect}) {
  const {formatMessage: f} = useIntl();
  const matches = useMediaQuery('(min-width:600px)');
  const preadmission = useSelector(state => state.preadmission);
  const userProgress = useSelector(state => state.preadmission.progress);
  const preadmissionWatcher = useSelector(state => state.preadmission.preadmissionWatcher);
  const currentSection = useSelector(
    state => state.preadmission.currentSection
  );
  const prevSection = usePrevious(currentSection);

  const dispatch = useDispatch();

  const [files, setFiles] = useState({});
  const [fields, setFields] = useState({});
  const [current, setCurrent] = useState(null);
  const [refDate, setRefDate] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [lastPage, setLastPage] = useState(false);

  const updateField = (index, data) => {
    const copy = {...fields};
    if (data) {
      copy[index] = {data, type: 'field'};
    } else {
      delete copy[index];
    }

    setFields(copy);
  };

  const updateFiles = (index, data) => {
    const copy = {...files};
    if (data) {
      copy[index] = {
        data: data.name,
        file: data.file,
        type: 'file',
        fileType: data.fileType
      };
    } else {
      delete copy[index];
    }

    setFiles(copy);
  };

  const save = async finished => {
    const saved = {...fields, ...files};
    const res = await dispatch(saveSection(user, current.id, saved, finished));
    if (res.status === 200) {
      if (finished) {
        dispatch(success(f({id: 'dataSavedConfirm'})));
        setCurrent(null);
      }

      dispatch(fetchPreadmission(user));
      dispatch(fetchProgress(user));
    } else if (finished) {
      dispatch(error(f({id: 'dataSavedError'})));
    }
  };

  useEffect(() => {
    if (currentSection) {
      if (userProgress.global === 100) {
        setLastPage(true);
      } else {
        const copy = {...userProgress};
        delete copy.global;
        delete copy.finished;

        const remainingSection = Object.keys(copy).filter(section => {
          return copy[section] < 100;
        });
        const currentId = current ? current.id : undefined;
        if ((remainingSection.length === 1 && remainingSection[0] === currentId) || remainingSection.length === 0) {
          setLastPage(true);
        } else {
          setLastPage(false);
        }
      }
    }
  }, [userProgress]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchPreadmission(user));
    dispatch(fetchProgress(user));
  }, [preadmissionWatcher]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchPreadmission(user));
    dispatch(fetchProgress(user));
  }, [user.token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (current) {
      dispatch(fetchUserPreadmission(user, current.id));
      dispatch(fetchProgress(user));
      setFields({});
      setFiles({});
    }
  }, [current, user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user && user.refDate && preadmission.deadline) {
      const deadline = preadmission.deadline.date;
      const ref = preadmission.deadline.kind ? 7 : 1;
      const refDate = moment(user.refDate, 'YYYYMMDD');
      const date = refDate.subtract(deadline * ref, 'days');
      setRefDate(date.format('DD MMMM YYYY'));
    }
  }, [preadmission, user]);

  useEffect(() => {
    if (currentSection) {
      const tmpFields = {};
      const tmpFiles = {};
      for (const [key, value] of Object.entries(currentSection)) {
        switch (value.type) {
          case 'field':
            tmpFields[key] = value;
            break;
          case 'file':
            tmpFiles[key] = value;
            break;
          default:
            break;
        }
      }

      setFields(tmpFields);
      setFiles(tmpFiles);
    }
  }, [prevSection, currentSection]);

  return (
    <div
      id="tab-1-content"
      style={{display: 'flex', flexDirection: 'column'}}
    >
      <SavingDialog
        isOpen={openConfirm} onClose={() => setOpenConfirm(false)} onClickOkButton={() => {
          save(true);
          setOpenConfirm(false);
        }}/>
      <Header display={onPageSelect} current={current} matchSize="600" setCurrent={setCurrent} date={refDate} finished={userProgress.finished} save={save}/>
      {!current &&
      <>
        {user.refDate && preadmission.deadline && preadmission.deadline.date > 0 && (
          <span
            className={styles.RefDate}
            style={
              !matches ?
                {paddingLeft: '0.5rem', paddingRight: '0.5rem'} :
                {paddingLeft: '1.75rem'}
            }
          >
            {f({id: 'deadlineText'})} {refDate}
          </span>
        )}
        {refDate && preadmission.deadline && preadmission.deadline.date > 0 ? (
          <span
            className={styles.Subtitle}
            style={
              !matches ?
                {paddingLeft: '0.5rem', paddingRight: '0.5rem'} :
                {paddingLeft: '1.75rem'}
            }
          >
            {f({id: 'deadlineAdditional'})} {refDate}.
          </span>
        ) : (
          <span
            className={styles.Subtitle}
            style={
              !matches ?
                {paddingLeft: '0.5rem', paddingRight: '0.5rem'} :
                {paddingLeft: '1.75rem'}
            }
          >
            {f({id: 'deadlineAdditional2'})}
          </span>
        )}
        {Boolean(user.deadlinePassed) && user.deadlinePassed !== 0 && (
          <div
            className={`${user.deadlinePassed === 2 ? styles.ErrorMsg : styles.WarningMsg}`}
            style={
              !matches ?
                {paddingLeft: '0.5rem', paddingRight: '0.5rem', flexDirection: 'column'} :
                {paddingLeft: '1.75rem', flexDirection: 'row'}
            }
          >
            {user.deadlinePassed === 2 ?
              <>
                <ErrorIcon htmlColor="red" style={{marginRight: '0.5em'}}/>
                <span>{f({id: 'deadlinePassedNote'})}</span>
              </> :
              <>
                <InfoIcon htmlColor="#e46c31" style={{marginRight: '0.5em'}}/>
                <span>{f({id: 'deadlinePassedNote2'})}</span>
              </>}

          </div>
        )}
      </>}
      {current &&
      <span style={{marginLeft: '1em', fontWeight: 'bold'}}>{current.description}</span>}
      {preadmission.sections && !current && (
        <>
          <div
            className="userMenu"
            style={matches ? {paddingLeft: '1rem'} : {paddingLeft: 0}}
          >
            {preadmission.sections.map((item, index) => (
              <SectionCard
                key={item.id}
                progress={userProgress ? userProgress[item.id] : 0}
                title={item.identity}
                description={item.description}
                onTap={() => setCurrent({...item, index})}
              />
            ))}
          </div>
        </>
      )}
      {!preadmission.sections && (
        <span className={styles.Empty}>
          {f({id: 'noPreadmissionAvailable'})}
        </span>
      )}
      {current && (
        <div className={styles.SelectedSection}>
          <div className={styles.Content}>
            <div className={styles.Form}>
              {current.content.map(input => (
                <React.Fragment key={input.id}>
                  {input.type === 'field' ? (
                    <FormInput
                      label={input.title}
                      disabled={userProgress.finished}
                      helperText={input.description}
                      value={fields[input.id] ? fields[input.id].data : ''}
                      onChange={evt =>
                        updateField(input.id, evt.target.value)}
                    />
                  ) : (
                    <div>
                      <UploaderForm
                        input={input}
                        disabled={userProgress.finished}
                        index={input.id}
                        fileData={files[input.id]}
                        updateFiles={updateFiles}
                        user={user}
                        section={current.id}
                      />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          {!userProgress.finished &&
          <span
            className={styles.Subtitle}
            style={
              !matches ?
                {paddingLeft: '0.5rem', paddingRight: '0.5rem'} :
                {paddingLeft: '1.75rem'}
            }
          >
            {f({id: 'dataSaveNote'})}
          </span>}
          <ActionBar
            setCurrent={setCurrent}
            save={save}
            finish={() => setOpenConfirm(true)}
            progress={userProgress.global}
            lastPage={lastPage}
            disabled={userProgress.finished}
            files={files}
            fields={fields}
            matchSize="600"
            current={current}
            preadmission={preadmission}
          />
        </div>
      )}
    </div>
  );
}
