import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {connect} from 'react-redux';
import {Grid} from '@material-ui/core';
import Modal from 'react-awesome-modal';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import {fetchFile, deleteFile, getUserDocumentsMetadata} from '../../../actions/documents';
import {error, success} from '../../../actions';

import WhiteIconButton from '../../shared/white-icon-button';
import BlueIconButton from '../../shared/blue-icon-button';

import styles from './file-actions.module.scss';

/* global atob Blob */

const FileActions = ({
  data,
  user,
  getUserDocumentsMetadata,
  error,
  success,
  editFile,
  description,
  category,
  matchSize = 600
}) => {
  const matches = useMediaQuery(`(min-width:${matchSize}px)`);
  const {formatMessage: f} = useIntl();
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [img, setImg] = useState('test.png');
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setVisible(false);
    setConfirmVisible(false);
  };

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  };

  const displayFile = (content, filename) => {
    const blob = b64toBlob(content, 'application/pdf');
    const uriContent = URL.createObjectURL(blob);
    const newWindow = window.open('', filename);
    if (newWindow) {
      const blobHtmlElement = document.createElement('object');
      blobHtmlElement.style.position = 'fixed';
      blobHtmlElement.style.top = '0';
      blobHtmlElement.style.left = '0';
      blobHtmlElement.style.bottom = '0';
      blobHtmlElement.style.right = '0';
      blobHtmlElement.style.width = '100%';
      blobHtmlElement.style.height = '100%';
      blobHtmlElement.setAttribute('type', 'application/pdf');
      blobHtmlElement.setAttribute('data', uriContent);
      newWindow.document.title = filename;
      newWindow.document.body.append(blobHtmlElement);
    } else {
      newWindow.addEventListener('load', () => {
        const blobHtmlElement = document.createElement('object');
        blobHtmlElement.style.position = 'fixed';
        blobHtmlElement.style.top = '0';
        blobHtmlElement.style.left = '0';
        blobHtmlElement.style.bottom = '0';
        blobHtmlElement.style.right = '0';
        blobHtmlElement.style.width = '100%';
        blobHtmlElement.style.height = '100%';
        blobHtmlElement.setAttribute('type', 'application/pdf');
        blobHtmlElement.setAttribute('data', uriContent);
        newWindow.document.title = filename;
        newWindow.document.body.append(blobHtmlElement);
      });
    }

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(uriContent);
    }, 300);
  };

  const loadAndDisplay = async () => {
    if (user && data) {
      setLoading(true);
      const res = await fetchFile(user.token, data._id);
      if (res.file) {
        if (res.fileType === 'application/pdf') {
          const b64data = res.file.split(',')[1];
          displayFile(b64data, res.documentType);
          setLoading(false);
        } else {
          setImg(res.file);
          setVisible(true);
          setLoading(false);
        }
      }
    }
  };

  const removeConfirm = async () => {
    try {
      const res = await deleteFile(user.token, data._id);
      setConfirmVisible(false);
      if (res.status === 200) {
        getUserDocumentsMetadata(user.token, user._id);
        success(f({id: 'dataRemoveConfirm'}));
      } else {
        error(f({id: 'dataRemoveError'}));
      }
    } catch (error_) {
      if (error_) {
        setConfirmVisible(false);
        error(f({id: 'dataRemoveError'}));
      }
    }
  };

  const removeFile = () => {
    setConfirmVisible(true);
  };

  return (
    <div
      className={matches ? styles.FileDummyContent : styles.FileDummyContentMini}
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >

      <div className={styles.Wrapper}>
        <BlueIconButton icon="visibility" isDisabled={loading} matchSize="600" text={f({id: 'view'})} onClick={loadAndDisplay}/>
        {loading && (
          <CircularProgress
            size={24}
            className={styles.LoadingSpinner}
          />
        )}
      </div>
      <BlueIconButton icon="edit" matchSize="600" text={f({id: 'edit'})} onClick={() => editFile({type: data.documentType, description, edition: true, current: data.fileName, category})}/>

      <WhiteIconButton icon="delete" matchSize="600" text={f({id: 'remove'})} onClick={removeFile}/>
      <Modal
        visible={visible}
        effect="fadeInUp"
        width="0"
        height="0"
        onClickAway={() => closeModal()}
      >
        <img className={styles.ImagePreview} alt="clickedImg" src={img} onClick={closeModal}/>
      </Modal>
      <Modal
        visible={confirmVisible}
        effect="fadeInUp"
        width="350"
        height="200"
        onClickAway={() => closeModal()}
      >
        <Grid item md={12} xs={12} lg={12}>
          <div className={styles.SimpleCard}>
            <span className={styles.PopupTitle}>{f({id: 'popupTitle'})}</span>
            <div className={styles.TextCard}>
              <span>{f({id: 'confirmDocumentRemove'})} <span className={styles.FileTypeName}>{data.title}</span> ?</span>
            </div>
            <div className={styles.Actions}>
              <Button className={styles.WhiteDocumentButton} onClick={closeModal}>
                <span className={styles.Label}>{f({id: 'cancel'})}</span>
              </Button>
              <Button className={styles.DocumentButton} onClick={removeConfirm}>
                <span className={styles.Label}>{f({id: 'remove'})}</span>
              </Button>
            </div>
          </div>
        </Grid>
      </Modal>
    </div>
  );
};

FileActions.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
  getUserDocumentsMetadata: PropTypes.func,
  error: PropTypes.func,
  success: PropTypes.func
};

export default connect(null, {error, success, getUserDocumentsMetadata})(
  FileActions
);
