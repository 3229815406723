import axios from 'axios';

/* global FormData */

export function fetchTypes(user) {
  return function (dispatch) {
    return axios
      .get('/api/proxy/documents/documents/types', {
        headers: {
          'Content-Type': 'application/json',
          authorization: user.token ? user.token : ''
        }
      })
      .then(res => {
        if (res.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const types = res.data;
        const available = types.filter(type => {
          return type.enabled;
        });
        dispatch(setTypes(available));
        return {
          result: 'SUCCESS',
          status: 200,
          data: available
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export function postFile(user, file, type, fileType, fileName, category) {
  return async function () {
    const fd = new FormData();
    fd.append('document', file);
    fd.append('userID', user._id);
    fd.append('customerID', user.customerID);
    fd.append('documentType', type);
    fd.append('category', category);
    fd.append('fileType', fileType);
    fd.append('fileName', fileName);
    return axios
      .post('/api/proxy/documents/documents/upload', fd, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
          authorization: user.token ? user.token : ''
        },
        timeout: 30000
      })
      .then(res => {
        if (res.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        return {
          result: 'SUCCESS',
          status: 200,
          data: type
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

/**
 * Get user uploaded documents metadata
 * @param {String} token Token of the user
 */
export function getUserDocumentsMetadata(token, id) {
  return function (dispatch) {
    axios.get(`/api/proxy/documents/documents/${id}`, {
      withCredentials: true,
      headers: {Authorization: token}
    })
      .then(({data, status}) => {
        if (status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        dispatch(setUserFiles(data));
        return {
          result: 'SUCCESS',
          status: 200,
          data
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export const fetchFile = async (token, id) => {
  return new Promise(resolve => {
    axios.get(`/api/proxy/documents/documents/file/${id}`, {
      withCredentials: true,
      headers: {Authorization: token},
      timeout: 30000
    })
      .then(({data}) => resolve(data))
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  });
};

export const deleteFile = async (token, id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/api/proxy/documents/documents/file/${id}`, {
      withCredentials: true,
      headers: {Authorization: token}
    })
      .then(({data, status}) => {
        if (status !== 200) {
          reject({
            result: 'ERROR',
            msg: 'serverError'
          });
        }

        resolve({
          result: 'SUCCESS',
          status: 200,
          data
        });
      })
      .catch(() => {
        reject({
          result: 'ERROR',
          msg: 'serverError'
        });
      });
  });
};

function _cleanTypes(types) {
  return types.filter(type => {
    return type.typeName;
  });
}

function setUserFiles(data) {
  return {
    type: 'SET_USER_FILES',
    payload: {
      files: data
    }
  };
}

function setTypes(types) {
  return {
    type: 'SET_TYPES',
    payload: {
      types: _cleanTypes(types)
    }
  };
}

