import React from 'react';

import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {withStyles} from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';

import InputAdornment from '@material-ui/core/InputAdornment';
import Warning from '@material-ui/icons/Error';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {success, error} from '../../../actions';
import {updatePassword} from '../../../actions/authentication';

import BlueButton from '../../blue-button';

import styles from './password.module.scss';

const Input = withStyles({
  root: {
    width: '20em',
    marginBottom: '0.25em',
    '& label': {
      minWidth: '-webkit-fill-available',
      zIndex: '99',
      marginLeft: '0.5em',
      '& span': {
        color: 'red'
      }
    },

    '& label.Mui-focused': {
      minWidth: '20em'
    },
    '& label.MuiFormLabel-filled': {
      minWidth: '20em'
    },
    '& input': {
      minWidth: '-webkit-fill-available',
      borderRadius: '4px',
      backgroundColor: '#ededed',
      marginBottom: '0.5em',
      paddingTop: '0.5em',
      paddingBottom: '0.5em',
      paddingRight: '1em',
      paddingLeft: '1em',
      height: '2em'

    }
  }

})(TextField);

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      token: null,
      loading: false,
      valid: false,
      oldPassword: '',
      password: '',
      passwordConfirm: '',
      alert: {
        open: false,
        title: '',
        text: ''
      },
      message: null,
      passwordNotMatch: false,
      emptyPassword: false,
      oldPasswordIncorrect: false
    };

    this.isValid = () => {
      let emptyPassword = false;
      let passwordNotMatch = false;
      if (this.state.password.length && !this.passwordOk(this.state.password)) {
        emptyPassword = true;
        this.setState({valid: false});
      }

      if (this.state.password !== this.state.passwordConfirm) {
        if (this.state.passwordConfirm.length) {
          passwordNotMatch = true;
        }

        this.setState({valid: false});
      }

      if (
        !this.state.oldPassword.length ||
        !this.state.password.length ||
        !this.state.passwordConfirm.length
      ) {
        this.setState({valid: false});
      } else {
        this.setState({valid: !passwordNotMatch && !emptyPassword, passwordNotMatch, emptyPassword});
      }
    };

    this.setMessage = value => {
      this.setState({message: value});
    };

    this.passwordOk = password => {
      const pwdRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\\d).{8,}$');
      const isContentOk = pwdRegex.test(password);
      if (!password || password.length < 8 || !isContentOk) {
        return false;
      }

      return true;
    };

    this.validate = async event => {
      event.preventDefault();
      if (!this.state.user || !this.state.token) {
        return;
      }

      let message;
      let passwordNotMatch = false;
      let emptyPassword = false;

      if (!this.state.oldPassword) {
        message = 'forgottenOldPasswordProcess';
      }

      if (!this.passwordOk(this.state.password)) {
        message = 'emptyPassword';
        emptyPassword = true;
      }

      if (this.state.password !== this.state.passwordConfirm) {
        passwordNotMatch = true;
        message = 'passwordNotMatch';
      }

      if (message) {
        this.setState({message, passwordNotMatch, emptyPassword});
        return;
      }

      this.setState({loading: true});

      const response = await this.props.updatePassword({
        ...this.state.user,
        oldPassword: this.state.oldPassword,
        password: this.state.password,
        token: this.state.token
      });
      if (response.result === 'ERROR') {
        this.setState({loading: false});
        //  This.props.error(this.props.intl.formatMessage({id: response.msg}));

        if (response.msg === 'oldPasswordIncorrect') {
          this.setState({oldPasswordIncorrect: true});
        }
      } else if (response.status === 200) {
        this.props.success(
          this.props.intl.formatMessage({id: 'passwordUpdateConfirm'})
        );
        this.props.onUpdate('');
      }
    };
  }

  handleClose() {
    this.setState({
      alert: {
        open: false,
        title: '',
        text: ''
      }
    });
  }

  handleOldPasswordChange(event) {
    if (event) {
      this.setState({oldPassword: event.target.value, oldPasswordIncorrect: false});
    }
  }

  handlePasswordChange(event) {
    if (event) {
      this.setState({password: event.target.value});
    }
  }

  handlePasswordConfirmChange(event) {
    if (event) {
      this.setState({passwordConfirm: event.target.value});
    }
  }

  componentDidUpdate(props, state) {
    if (
      state.oldPassword !== this.state.oldPassword ||
      state.password !== this.state.password ||
      state.passwordConfirm !== this.state.passwordConfirm
    ) {
      this.isValid();
    }

    if (props.user && props.user._id !== get(this.state, 'user._id')) {
      this.setState({user: props.user});
    }

    if (props.token !== this.state.token) {
      this.setState({token: props.token});
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;
    if (nextProps.user) {
      newProps = newProps ?
        {...newProps, user: nextProps.user} :
        {user: nextProps.user};
    }

    if (nextProps.token) {
      newProps = newProps ?
        {...newProps, token: nextProps.token} :
        {token: nextProps.token};
    }

    return newProps;
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    return (
      <div className={styles.PersonalForm}>
        <div className="pageActions">
          <Breadcrumbs aria-label="breadcrumb">
            <Link data-testid="pf-back" color="inherit" to="/personalData">
              {f({id: 'myAccount'})}
            </Link>
            <Link
              data-testid="pf-current"
              color="textPrimary"
              to="/personalData/modifyPassword"
              aria-current="page"
            >
              {f({id: 'modifyPassword'})}
            </Link>
          </Breadcrumbs>
          <Link data-testid="pf-smallscreen-back" className="smallScreenBack" to="/personalData">
            <ArrowBack/>
          </Link>
        </div>
        <div className={styles.FormDiv}>
          <form noValidate autoComplete="off" className={styles.Form} onSubmit={this.validate}>
            <Input
              required
              data-testid="pf-input1"
              label={f({id: 'oldPassword'})}
              type="password"
              id="oldpassword"
              error={
                this.state.message === 'forgottenOldPasswordProcess' ||
                  this.state.message === 'oldPasswordIncorrect'
              }
              InputProps={{
                endAdornment: (
                  <>
                    {(this.state.message === 'forgottenOldPasswordProcess' || this.state.oldPasswordIncorrect) && (
                      <InputAdornment position="end">
                        <Warning className="red"/>
                      </InputAdornment>
                    )}
                  </>
                )
              }}
              value={this.state.oldPassword}
              onChange={event => this.handleOldPasswordChange(event)}
            />
            {this.state.oldPasswordIncorrect && (
              <span className={styles.ErrorMsg}>
                {f({id: 'oldPasswordIncorrect'})}
              </span>
            )}

            <br/>
            <Input
              required
              data-testid="pf-input2"
              label={f({id: 'newPassword'})}
              type="password"
              id="password"
              error={this.state.emptyPassword}
              value={this.state.password}
              InputProps={{
                endAdornment: (
                  <>
                    {this.state.emptyPassword && (
                      <InputAdornment position="end">
                        <Warning className="red"/>
                      </InputAdornment>
                    )}
                  </>
                )
              }}
              onChange={event => this.handlePasswordChange(event)}
            />
            <span
              data-testid="pf-text"
              className={
                this.state.emptyPassword ?
                  styles.ErrorMsg :
                  styles.InfoMsg
              }
            >
              {f({id: 'signupNote'})}
            </span>
            <br/>
            <Input
              required
              data-testid="pf-input3"
              label={f({id: 'newPasswordConfirm'})}
              type="password"
              id="passwordConfirm"
              value={this.state.passwordConfirm}
              error={this.state.passwordNotMatch}
              InputProps={{
                endAdornment: (
                  <>
                    {this.state.passwordNotMatch && (
                      <InputAdornment position="end">
                        <Warning className="red"/>
                      </InputAdornment>
                    )}
                  </>
                )
              }}
              onChange={event => this.handlePasswordConfirmChange(event)}
            />
            {this.state.passwordNotMatch && (
              <span className={styles.ErrorMsg}>
                {f({id: 'passwordNotMatch'})}
              </span>
            )}

            <br/>

            <div className={styles.Wrapper}>
              <BlueButton
                data-testid="pf-validate"
                variant="contained"
                disabled={!this.state.valid || this.state.loading}
                onClick={this.validate}
              >
                {f({id: 'validate'})}
              </BlueButton>
              {this.props.loading && (
                <CircularProgress
                  size={24}
                  className={styles.LoadingSpinner}
                />
              )}
            </div>
          </form>

          <br/>
          <div className={styles.InfoMsg}>
            <span data-testid="pf-mandatory" className="red">*&nbsp;</span>{f({id: 'mandatoryInformation'})}
          </div>
        </div>
        <Dialog
          open={this.state.alert.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={() => this.handleClose()}
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.alert.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alert.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testid="pf-button1" color="primary" onClick={() => this.handleClose()}>
              {this.state.alert.confirmText || 'Ok'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: get(state, 'user.user'),
  token: get(state, 'user.token')
});
export default injectIntl(
  connect(mapStateToProps, {
    updatePassword,
    success,
    error
  })(PasswordForm)
);
