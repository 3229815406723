import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

export default withStyles({
  root: {
    width: '100%',
    marginBottom: '0.25em',
    minWidth: '20em',
    '& label': {
      minWidth: '-webkit-fill-available',
      marginLeft: '0.5em',
      textAlign: 'start',
      cursor: 'text',
      '& span': {
        color: 'red'
      }
    },

    '& label.Mui-focused': {
      minWidth: '20em'
    },
    '& label.MuiFormLabel-filled': {
      minWidth: '20em'
    },
    '& input': {
      minWidth: '-webkit-fill-available',
      borderRadius: '4px',
      backgroundColor: '#b9b9b947',
      marginBottom: '0.5em',
      paddingTop: '0.5em',
      paddingBottom: '0.5em',
      paddingRight: '1em',
      paddingLeft: '1em'
    },
    '& div.MuiSelect-select': {
      minWidth: '-webkit-fill-available',
      borderRadius: '4px',
      backgroundColor: '#b9b9b947',
      marginBottom: '0.5em',
      paddingTop: '0.5em',
      paddingBottom: '0.5em',
      paddingRight: '1em',
      paddingLeft: '1em'
    },
    '& div.MuiInputAdornment-positionEnd': {
      marginLeft: '-30px',
      paddingBottom: '0.5em'
    }
  }

})(TextField);
