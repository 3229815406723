import React from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import CheckCircle from '@material-ui/icons/CheckCircle';

import styles from './section-card.module.scss';

export default function SectionCard(props) {
  const title = props.title || '';
  const description = props.description || null;
  const progress = props.progress || 0;

  return (
    <Card className="card">
      <CardActionArea
        className="area"
        onClick={props.onTap}
      >
        <CardContent style={{display: 'flex', alignItems: 'center'}}>
          {progress === 100 &&
          <CheckCircle style={{color: 'green'}}/>}
          {progress < 100 &&
            <CheckCircleOutline style={{htmlColor: 'green'}}/>}
          <div className={styles.Card}>
            <div style={{fontWeight: 'bold'}}>

              {title}
            </div>
            <span style={{}}>
              {description}
            </span>
          </div>

        </CardContent>
      </CardActionArea>
    </Card>
  );
}
