import React, {useEffect, useState, Fragment} from 'react';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {FormattedNumber, useIntl} from 'react-intl';
import styles from '../styles/main.scss';
import {CircularProgress, FormControl, Input, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {get} from 'lodash';
import CreditCardForm from './payment/CreditCardForm/credit-card-form';
import BlueButton from './blue-button';
import WhiteButton from './white-button';
import {loadPaybox3DSecure} from '../actions/submit-3d-secure';
import {useCookies} from 'react-cookie';
import {useSelector} from 'react-redux';

const availableLang = {
  en: 'en_US',
  fr: 'fr_FR'
};

const Valid3dsTransaction = ({lang, setCurrentPage, goToSignUpPage, disableTransactionMode}) => {
  const {hospID, transactionId} = useParams();
  const [currentCommand, setCurrentCommand] = useState(null);
  const [amountToAdd, setAmountToAdd] = useState(0);
  const [uniqID, setUniqID] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [step, setStep] = useState(0);
  const [card, setCard] = useState({});
  const [cardIsValid, setCardIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorFetchCommand, setErrorFetchCommand] = useState(false);
  const f = useIntl().formatMessage;
  const [cookies, setCookies] = useCookies(['hospID']);
  const locale = useSelector(state => state.default.locale);

  // Fetch informations of the transaction at the startup (user, content of the command, etc)
  useEffect(() => {
    setCurrentPage();
    console.info(`Using transaction ID ${transactionId} and hospID ${hospID}`);
    setCookies('hospID', hospID, {path: '/'});
    console.log('Current cookies', cookies);

    const fetchCurrentCommand = async id => {
      try {
        const {data} = await axios.get(`/api/proxy/vad/getCommand/${id}`);
        setCustomer(data.customer);
        setCurrentCommand(data.command);
        setAmountToAdd(data.command.amountToAdd || 0);
        setIsValid(data.valid);
        setUniqID(data.uniqID);
        setErrorFetchCommand(false);
      } catch (error) {
        setErrorFetchCommand(true);
        console.error('Failed to fetch current transaction', error);
        setErrorMessage(f({id: 'failedFetchCurrentTransaction'}));
      }
    };

    fetchCurrentCommand(transactionId);
  }, [f, transactionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderCommandIsValid = () => (
    <p className="validTransactionMessage">{f({id: 'transactionValid'})}</p>
  );

  const getPlanLabel = plan => {
    const label = plan.lang ? get(plan, `lang[${availableLang[lang]}]`, plan.label) : plan.label;
    return label || plan.plan;
  };

  const handleBackButtonClick = () => {
    if (step === 2) {
      setStep(0);
    } else {
      setStep(prev => prev - 1);
    }
  };

  const buyProcess = (customer, currentCommand, formatedCard, id3ds) => {
    buy(customer, currentCommand, formatedCard, id3ds)
      .then(() => {
        console.log('Buy is done');

        // Update transaction state
        axios.post(`/api/proxy/vad/updateCommandValidation/${uniqID}`, {valid: true}, {
          headers: {'Content-Type': 'application/json'}
        })
          .then(() => {
            setIsValid(true);
            setStep(prev => prev + 1);
          })
          .catch(() => {
            console.error('Failed to update state of transaction', uniqID);
            setErrorMessage(f({id: 'failedUpdateTransactionState'}));
          });
      })
      .catch(error => {
        if (error?.code === '3dsError') {
          setErrorMessage(error?.error ? f({id: '3dsErrorWithCode'}) + error?.error : f({id: '3dsError'}));
        } else if (error?.code === 'paymentError') {
          if (error?.message) {
            setErrorMessage(error?.message[lang]);
          } else {
            setErrorMessage(f({id: 'paymentError'}));
          }
        } else {
          setErrorMessage(f({id: 'paymentError'}));
        }
      });
  };

  const handleNextButtonClick = () => {
    if (step === 0) {
      setStep(prev => prev + 1);
    } else if (step === 1) {
      const formatedCard = {
        porteur: card?.number?.replace(/ /g, ''),
        dateval: card?.date?.replace(/\//g, ''),
        cvv: card?.crypto
      };
      buyProcess(customer, currentCommand, formatedCard, null);
    } else if (step === 2) {
      disableTransactionMode();
      goToSignUpPage({
        activationCode: customer?.activationCode,
        lastname: customer?.lastname
      });
    }
  };

  const checkNextButtonDisabled = () => {
    if (isLoading || errorFetchCommand) {
      return true;
    }

    if (step === 0) {
      return isValid;
    }

    if (step === 1) {
      return !cardIsValid;
    }

    return false;
  };

  const buy = (user, command, card, id3d) => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);

      // We adapt transaction about amount to add
      // (We need to consider regularization)
      const transaction = {amount: amountToAdd || command?.total, type: 'paybox', card: {...card, id3d}};

      try {
        await axios.post(`/api/proxy/payment/users/${user.uuid}/refill`, JSON.stringify(transaction), {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        await buyArticles(command, user);
        resolve();
      } catch (error) {
        // Handle 3DS Error
        if (error?.response?.data?.message === 'soft decline') {
          loadPaybox3DSecure({
            amount: ((command?.total || 0) * 100).toString(),
            cardNumber: card.porteur,
            cardExpiration: card.dateval,
            cardCvv: card.cvv,
            firstname: user.firstname,
            lastname: user.lastname,
            urlHttpDirect: window._env_.PAYMENT_3DS_URL_HTTP_DIRECT,
            urlRetour: window._env_.PAYMENT_3DS_URL_RETURN
          }, async id3d => {
            return buyProcess(user, command, card, id3d);
          }, error3DS => {
            reject({
              code: '3dsError',
              error: error3DS
            });
          });
        } else if (id3d) {
          reject({code: '3dsError'});
        } else {
          reject({
            code: 'paymentError',
            message: error?.response?.data?.error?.paymentMessages
          });
        }
      } finally {
        setIsLoading(false);
      }
    });
  };

  const getLabelNextButton = () => {
    if (step === 1) {
      return 'validate';
    }

    if (step === 2) {
      return 'createMyAccount';
    }

    return 'next';
  };

  const buyArticles = async (command, user) => {
    const transaction = {items: command.details.map(item => ({
      plan: item.plan,
      article: item.article,
      qty: item.qty,
      PUTC: item.PUTC || item.target.PUTC,
      total: item?.total || 0,
      tokens: item.tokens,
      type: item.type
    }))};

    await axios.post(`/api/proxy/payment/users/${user.uuid}/buy`, JSON.stringify(transaction), {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    );
  };

  // If we have 'amountToAdd' property in command,
  // the total is 'amountToAdd' value (total command + regularization)
  // Otherwise it is total command
  const getTotalAmount = () => amountToAdd ? amountToAdd.toFixed(2) : currentCommand.total.toFixed(2);

  const getRegularizationAmount = () => (amountToAdd - currentCommand.total).toFixed(2);

  // If there is regularization, we need to adapt new wallet amount
  const getNewWalletAmount = () => amountToAdd ? (customer.wallet + (amountToAdd - currentCommand.total)).toFixed(2) : customer.wallet.toFixed(2);

  const RowRecap = ({key, name, unitPrice, qty = 1, totalPrice}) => (
    <TableRow>
      <TableCell component="th" scope="row">
        <div>{name}</div>
      </TableCell>
      <TableCell align="right">
        <FormattedNumber value={unitPrice} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>
      </TableCell>
      <TableCell align="right">
        <FormControl>
          <Input
            key={`row-${key}`}
            disabled
            className="small"
            id={`quantity-${key}`}
            type="number"
            inputProps={{min: 0, max: 999}}
            placeholder="0"
            value={qty}
          />
        </FormControl>
      </TableCell>
      <TableCell align="right">
        <FormattedNumber value={totalPrice} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>
      </TableCell>
    </TableRow>
  );

  const renderRecapCommand = () => (
    <>
      <TableContainer component={Paper}>
        <Table className="table">
          <TableHead>
            <TableRow className="lightblue">
              <TableCell>{f({id: 'planTitle'})}</TableCell>
              <TableCell align="right">{f({id: 'planPriceLabel'})}</TableCell>
              <TableCell align="right">{f({id: 'quantityTitle'})}</TableCell>
              <TableCell align="right">{f({id: 'ITamountOfLabel'})}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentCommand.details.map(plan => (
              <React.Fragment key={`plan-${plan._id}`}>
                <RowRecap
                  name={getPlanLabel(plan)}
                  unitPrice={plan.target.PUTC.toFixed(2)}
                  qty={plan.qty || 0}
                  totalPrice={(plan.total || 0).toFixed(2)}/>
              </React.Fragment>
            ))}

            {/** If it is necessary we add a row to display regularization */}
            {
              amountToAdd > 0 && (
                <RowRecap
                  name={f({id: 'regularization'})}
                  unitPrice={getRegularizationAmount()}
                  totalPrice={getRegularizationAmount()}/>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <p className="text-align-right">
        {f({id: 'ITamountTotalOf'})}{' '}
        <span className="wallet bold">
          <FormattedNumber value={getTotalAmount()} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>
        </span>
      </p>
      <span className={styles.InfoMsg}>{f({id: 'PTTCnote'})}</span>
    </>
  );

  return (
    <div data-testid="transactionPage" className="transactionPage">
      {step === 0 && (
        <>
          <h1>{f({id: 'summary'})}</h1>

          {currentCommand && (isValid ? renderCommandIsValid() : renderRecapCommand())}
        </>
      )}

      {step === 1 && (
        <div style={{alignSelf: 'center'}}>
          <CreditCardForm
            lang={lang}
            setCardValid={setCardIsValid}
            setCard={setCard}/>
        </div>
      )}

      {step === 2 && (
        <div className="summary">
          <h1>{f({id: 'summary'})}</h1>

          <div className="summaryInfo">
            <InfoIcon style={{marginRight: 20}}/>
            <p className="summaryInfoText">{f({id: 'summaryInformationText'})}</p>
          </div>

          <div className="listOfPlans">
            <span>{f({id: 'paymentConfirmPlans'})} </span>
            <ul>
              {currentCommand.details.map((item, index) => (
                <Fragment key={index}>
                  {item.qty > 0 && (
                    <li key={`alertitemlist-${index}`} data-testid={`pc-li-${index}`}>
                      {item.qty} x {item.plan}
                    </li>
                  )}
                </Fragment>
              ))}

              {amountToAdd > 0 && (
                <li data-testid="pc-li-regularization">
                  {`1 x ${f({id: 'regularization'})}`}
                </li>
              )}
            </ul>
          </div>

          <p className="walletAmount">{f({id: 'paymentNewBalance'})} <FormattedNumber value={getNewWalletAmount()} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>.</p>

          <p className="activationCode">{f({id: 'activationCodeMessage'})} {customer.activationCode}</p>

          <p className="accessToXP">{f({id: 'summaryAccessToXP'})}</p>
        </div>
      )}

      <div className="actionButtons">
        { step === 0 ? <p/> : (
          <WhiteButton data-testid="backButton" variant="outlined" onClick={handleBackButtonClick}>
            {f({id: step === 2 ? 'close' : 'previous'})}
          </WhiteButton>
        )}
        <BlueButton
          data-testid="nextButton"
          color="primary"
          variant="contained"
          disabled={checkNextButtonDisabled()}
          onClick={handleNextButtonClick}
        >
          {f({id: getLabelNextButton()})}
        </BlueButton>
      </div>

      { isLoading && <CircularProgress className="loader"/>}

      <Snackbar
        data-testid="errorSnackbar"
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        message={errorMessage}
        onClose={() => setErrorMessage(null)}/>
    </div>
  );
};

export default Valid3dsTransaction;
