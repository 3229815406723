import React from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';

import styles from './home-page.module.scss';

export default function MenuCard(props) {
  const title = props.title || '';
  const icon = props.icon || null;
  const onClick = () => {
    props.onTap();
  };

  return (
    <Card className={styles.HomeCard2}>
      <CardActionArea
        className={styles.HomeArea}
        onClick={onClick}
      >
        <CardContent className={styles.HomeCardContent}>
          <Icon className={styles.HomeIcon}>{icon}</Icon>
          <span className={styles.HomeCardTitle}>{title}</span>

        </CardContent>
      </CardActionArea>
    </Card>
  );
}
