import React from 'react';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {get} from 'lodash';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBack from '@material-ui/icons/ArrowBack';

import InputAdornment from '@material-ui/core/InputAdornment';
import Warning from '@material-ui/icons/Error';
import {withStyles} from '@material-ui/core/styles';

import {error, success} from '../../../actions';
import {newPassword} from '../../../actions/authentication';

import styles from './new-password.module.scss';

const PasswordConfirm = withStyles({
  root: {
    width: '20em',
    marginBottom: '0.25em',
    '& label': {
      minWidth: '-webkit-fill-available',
      zIndex: '99',
      marginLeft: '0.5em',
      textAlign: 'start',
      '& span': {
        color: 'red'
      }
    },

    '& label.Mui-focused': {
      minWidth: '20em'
    },
    '& label.MuiFormLabel-filled': {
      minWidth: '20em'
    },
    '& input': {
      minWidth: '-webkit-fill-available',
      borderRadius: '4px',
      backgroundColor: '#ededed',
      marginBottom: '0.5em',
      paddingTop: '0.5em',
      paddingBottom: '0.5em',
      paddingRight: '1em',
      paddingLeft: '1em',
      height: '2em'
    },
    '& div.MuiInputAdornment-positionEnd': {
      marginLeft: '-30px',
      paddingBottom: '0.5em'
    }
  }

})(TextField);

class NewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      token: '',
      loading: false,
      valid: false,
      password: '',
      passwordConfirm: '',
      message: null
    };

    this.passwordOk = password => {
      const pwdRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\\d).{8,}$');
      const isContentOk = pwdRegex.test(password);
      if (!password || password.length < 8 || !isContentOk) {
        return false;
      }

      return true;
    };

    this.isValid = () => {
      if (!this.state.password.length || !this.state.passwordConfirm.length) {
        this.setState({valid: false});
      } else if (this.state.password !== this.state.passwordConfirm) {
        if (!this.state.message) {
          this.setMessage('passwordNotMatch');
        }

        this.setState({valid: false});
      } else {
        if (this.state.message) {
          this.setMessage(null);
        }

        this.setState({valid: true});
      }
    };

    this.setMessage = value => {
      this.setState({message: value});
    };

    this.validate = async () => {
      if (!this.state.user) {
        return;
      }

      let message;

      if (!this.passwordOk(this.state.password)) {
        message = 'emptyPassword';
      } else if (this.state.password !== this.state.passwordConfirm) {
        message = 'passwordNotMatch';
      }

      if (message) {
        this.setState({message});
        return;
      }

      this.setState({loading: true});

      const response = await this.props.newPassword({
        ...this.state.user,
        password: this.state.password,
        token: this.state.token
      });
      if (response.result === 'ERROR') {
        this.props.error(this.props.intl.formatMessage({id: response.msg}));
      } else if (response.status === 200) {
        this.props.success(
          this.props.intl.formatMessage({id: 'passwordResetConfirm'})
        );
        this.props.onUpdate();
      }
    };
  }

  handleOldPasswordChange(event) {
    if (event) {
      this.setState({oldPassword: event.target.value});
    }
  }

  handlePasswordChange(event) {
    if (event) {
      this.setState({password: event.target.value});
    }
  }

  handlePasswordConfirmChange(event) {
    if (event) {
      this.setState({passwordConfirm: event.target.value});
    }
  }

  componentDidUpdate(props, state) {
    if (props.user && props.user._id !== get(this.state, 'user._id')) {
      this.setState({user: props.user});
    }

    if (props.token && props.token !== get(this.state, 'token')) {
      this.setState({token: props.token});
    }

    if (
      state.password !== this.state.password ||
      state.passwordConfirm !== this.state.passwordConfirm
    ) {
      this.isValid();
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;
    if (nextProps.user) {
      newProps = newProps ? {...newProps, user: nextProps.user} : {user: nextProps.user};
    }

    if (nextProps.token) {
      newProps = newProps ? {...newProps, token: nextProps.token} : {token: nextProps.token};
    }

    return newProps;
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    return (
      <div className="page login withoutMenu">
        <div className="pageActions withTop">
          <div data-testid="np-back" className="backButton" onClick={this.props.onUpdate}>
            <ArrowBack/> <span className="label">{f({id: 'return'})}</span>
          </div>
        </div>
        <div className="pageHeader flex center">
          <h3> {f({id: 'resetPassword'})} </h3>
        </div>
        <div className="pageContent flex column">
          <div className="personnalDataForm self-center width30">
            <form noValidate className="root no-left" autoComplete="off">
              <PasswordConfirm
                required
                data-testid="np-input1"
                placeholder={f({id: 'newPassword'})}
                label={f({id: 'newPassword'})}
                type="password"
                id="password"
                error={this.state.message === 'emptyPassword'}
                value={this.state.password}
                InputProps={{
                  endAdornment: (
                    <>
                      {this.state.message === 'emptyPassword' && (
                        <InputAdornment position="end">
                          <Warning className="red"/>
                        </InputAdornment>
                      )}
                    </>
                  )
                }}
                onChange={event => this.handlePasswordChange(event)}
              />

              <span data-testid="np-text" className={this.state.message === 'emptyPassword' ? styles.ErrorMsg : styles.InfoMsg}>
                {f({id: 'signupNote'})}
              </span>
              <br/>
              <PasswordConfirm
                required
                data-testid="np-input2"
                placeholder={f({id: 'newPasswordConfirm'})}
                label={f({id: 'newPasswordConfirm'})}
                type="password"
                id="passwordConfirm"
                value={this.state.passwordConfirm}
                error={
                  this.state.message === 'emptyPassword' ||
                  this.state.message === 'passwordNotMatch'
                }
                InputProps={{
                  endAdornment: (
                    <>
                      {(this.state.message === 'emptyPassword' ||
                        this.state.message === 'passwordNotMatch') && (
                        <InputAdornment position="end">
                          <Warning className="red"/>
                        </InputAdornment>
                      )}
                    </>
                  )
                }}
                onChange={event => this.handlePasswordConfirmChange(event)}
              />
              {this.state.message === 'passwordNotMatch' && (
                <span className={styles.ErrorMsg}>
                  {f({id: 'passwordNotMatch'})}
                </span>
              )}

              <br/>
              <Button
                data-testid="np-button"
                id="validate"
                className="flat blue"
                disabled={!this.state.valid}
                onClick={this.validate}
              >
                {f({id: 'validate'})}
              </Button>
            </form>
            {this.state.loading && <CircularProgress className="loading"/>}
            <br/>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: get(state, 'user.user'),
  token: get(state, 'user.token')
});
export default injectIntl(
  connect(mapStateToProps, {
    newPassword,
    success,
    error
  })(NewPassword)
);
