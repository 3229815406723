import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const primaryColor = '#2196F3';
export const secondaryColor = '#1565c0';
export const thirtyColor = '#90caf9';

export default withStyles({
  root: {
    backgroundColor: primaryColor,
    borderColor: '#007bff',
    color: 'white',
    fontWeight: 'bold',
    minWidth: '8em',
    margin: '0.5em',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: secondaryColor,
      borderColor: thirtyColor,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: thirtyColor,
      borderColor: '#005cbf'
    },
    '&:disabled': {
      backgroundColor: 'lightGrey',
      borderColor: '#007bff',
      color: 'grey'
    }
  }
})(Button);
