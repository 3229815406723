import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import {clearSnackbar} from '../actions';
import MuiAlert from '@material-ui/lab/Alert';
import {withStyles} from '@material-ui/core/styles';
const GreyAlert = withStyles({
  filledSuccess: {
    color: '#fff',
    backgroundColor: 'rgb(50, 50, 50)'
  },
  /* Styles applied to the root element if `variant="filled"` and `color="info"`. */
  filledInfo: {
    color: '#fff',
    backgroundColor: 'rgb(50, 50, 50)'
  },
  /* Styles applied to the root element if `variant="filled"` and `color="warning"`. */
  filledWarning: {
    color: '#fff',

    backgroundColor: 'rgb(50, 50, 50)'
  },
  /* Styles applied to the root element if `variant="filled"` and `color="error"`. */
  filledError: {
    color: '#fff',
    backgroundColor: 'rgb(50, 50, 50)'
  }

})(MuiAlert);

function Alert(props) {
  return <GreyAlert elevation={6} variant="filled" {...props}/>;
}

export default function Notifications() {
  const dispatch = useDispatch();

  const {snackbarMessage, snackbarOpen, snackbarType} = useSelector(
    state => state.ui
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={snackbarOpen}
      autoHideDuration={5000}
      aria-describedby="client-snackbar"
      onClose={handleClose}
    >
      <Alert severity={snackbarType} onClose={handleClose}>{snackbarMessage}</Alert>

    </Snackbar>
  );
}
