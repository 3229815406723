import axios from 'axios';
import {get} from 'lodash';

import {loadPaybox3DSecure, loadPayline3DSecure} from './submit-3d-secure';
import {setUser} from './user';

export const clearCommand = () => {
  return function (dispatch) {
    dispatch(
      {
        type: 'CLEAR_COMMAND'
      }
    );
  };
};

function setHistory(history) {
  return {
    type: 'SET_HISTORY',
    payload: {
      history: history.items,
      pgMax: history.pg
    }
  };
}

function setStatus(status) {
  return {
    type: 'SET_ACCOUNT_STATUS',
    payload: {status}
  };
}

function setPlans(plans) {
  return {
    type: 'SET_PLANS',
    payload: {
      plans
    }
  };
}

export const setCommand = command => {
  return function (dispatch) {
    dispatch(updateCommand(command));
  };
};

function updateCommand(command) {
  return {
    type: 'SET_COMMAND',
    payload: {
      command
    }
  };
}

export const error = message => {
  return dispatch => {
    dispatch({type: 'SNACKBAR_ERROR', message});
  };
};

export const success = message => {
  return dispatch => {
    dispatch({type: 'SNACKBAR_SUCCESS', message});
  };
};

export const warning = message => {
  return dispatch => {
    dispatch({type: 'SNACKBAR_WARNING', message});
  };
};

export const fetchPaymentData = (user, pg) => {
  return function (dispatch) {
    return axios
      .get(`/api/proxy/payment/users/${user.customerID}/history?pg=${pg}`, {
        headers: {authorization: user.token ? user.token : ''}
      })
      .then(res => {
        if (res.status !== 200) {
          return {
            result: 'ERROR',
            title: 'error',
            msg: 'serverError'
          };
        }

        const history = res.data;
        dispatch(setHistory(history));
        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(error_ => {
        if (get(error_, 'response.status') === 503) {
          return {
            result: 'ERROR',
            title: 'error',

            msg: 'unavailableError'
          };
        }

        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
};

export const getUserStatus = user => {
  return function (dispatch) {
    return axios
      .get(`/api/users/cloud/${user._id}`, {
        headers: {
          authorization: user.token ? user.token : '',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const user = response.data;

        dispatch(setStatus(user.status));
      })
      .catch(error_ => {
        if (get(error_, 'response.status') === 503) {
          return {
            result: 'ERROR',
            title: 'error',

            msg: 'unavailableError'
          };
        }

        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
};

export function fetchWallet(user) {
  return function () {
    return axios
      .get(`/api/proxy/payment/users/${user.customerID}/wallet`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: user.token ? user.token : ''
        }
      })
      .then(res => {
        if (res.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const wallet = res.data;
        return {
          result: 'SUCCESS',
          status: 200,
          data: wallet
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export const fetchAvailablePlans = user => {
  return function (dispatch) {
    return axios
      .get(`/api/proxy/payment/users/${user.customerID}/availableplans`, {
        headers: {authorization: user.token ? user.token : ''}
      })
      .then(res => {
        if (res.status !== 200) {
          return {
            result: 'ERROR',
            title: 'error',
            msg: 'serverError'
          };
        }

        const plans = res.data;
        dispatch(setPlans(plans));
        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(error_ => {
        if (get(error_, 'response.status') === 503) {
          return {
            result: 'ERROR',
            title: 'error',

            msg: 'unavailableError'
          };
        }

        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
};

export const buy = (command, user, token, tvCoaxProcess) => {
  return function (dispatch) {
    const transaction = {items: command.details};

    return axios
      .post(
        `/api/proxy/payment/users/${user.customerID}/buy`,
        JSON.stringify(transaction),
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: token ? token : ''
          }
        }
      )
      .then(res => {
        if (res.status !== 200) {
          return {
            result: 'ERROR',
            title: 'error',
            msg: 'serverError'
          };
        }

        if ((tvCoaxProcess?.code?.isEnabled || tvCoaxProcess?.location?.isEnabled) && user.location?.locID && user.service?.servID) {
          return axios.post(`/api/users/si/location/${user._id}`,
            {customerID: user.customerID, hospID: user.hospID, location: user.location},
            {
              headers: {
                authorization: token ? token : ''
              }
            });
        }
      })
      .then(() => {
        return axios.get(`/api/users/cloud/${user._id}`, {
          headers: {
            authorization: token ? token : ''
          }
        });
      })
      .then(res => {
        if (res.status === 200) {
          const userData = res.data;
          dispatch(setUser({
            ...userData,
            isFilled: true,
            token
          }));

          return {
            result: 'SUCCESS',
            status: 200
          };
        }
      })
      .catch(error_ => {
        console.log('catch error');
        if (get(error_, 'response.status') === 503) {
          return {
            result: 'ERROR',
            title: 'error',

            msg: 'unavailableError'
          };
        }

        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
};

export const refill = (command, user, token, card, paymentType, id3d = null) => {
  return function () {
    const transaction = {amount: command.total, type: paymentType, card: {...card, id3d}};
    return new Promise(async resolve => {
      const doRefill = transaction => axios.post(`/api/proxy/payment/users/${user.customerID}/refill`, JSON.stringify(transaction), {
        headers: {'Content-Type': 'application/json', authorization: token ? token : ''}
      }
      );

      try {
        await doRefill(transaction);
        resolve({
          result: 'SUCCESS',
          status: 200
        });
      } catch (error_) {
        if (get(error_, 'response.status') === 503) {
          resolve({
            result: 'ERROR',
            title: 'error',

            msg: 'unavailableError'
          });
        }

        let result;
        try {
          // First parse
          result = JSON.parse(error_?.response?.data);
        } catch (_) {
          result = error_?.response?.data;
        }

        try {
          // Second parse
          result = JSON.parse(result.message);
        } catch (_) {}

        if (result.message === 'soft decline') {
          const success3ds = async (id3d, session) => {
            const newTransaction = {amount: command.total, type: paymentType, card: {...card, session, id3d}};

            try {
              await doRefill(newTransaction);
              resolve({
                result: 'SUCCESS',
                status: 200
              });
            } catch (error) {
              resolve({
                result: 'ERROR',
                title: 'error',
                msg: error?.data?.error?.paymentMessages || '3dsError'
              });
            }
          };

          const error3ds = error3DS => {
            resolve({
              result: 'ERROR',
              title: 'error',
              msg: '3dsError',
              error3DS
            });
          };

          if (paymentType === 'paybox') {
            loadPaybox3DSecure({
              amount: (command.total * 100).toString(),
              cardNumber: card.porteur,
              cardExpiration: card.dateval,
              cardCvv: card.cvv,
              firstname: user.firstname,
              lastname: user.lastname,
              urlHttpDirect: window._env_.PAYMENT_3DS_URL_HTTP_DIRECT,
              urlRetour: window._env_.PAYMENT_3DS_URL_RETURN
            }, success3ds, error3ds);
          } else if (paymentType === 'payline') {
            loadPayline3DSecure(result.authentication, window._env_.PAYMENT_3DS_URL_HTTP_DIRECT, success3ds, error3ds);
          }
        } else {
          let msg;
          try {
            msg = result?.paymentMessages || result.message;
          } catch (_) {
            msg = result.message;
          }

          resolve({
            result: 'ERROR',
            title: 'error',
            msg
          });
        }
      }
    });
  };
};

export async function getPaymentMethod(hospID, user) {
  try {
    const {data} = await axios.get(`/api/proxy/si/${hospID}/paymentmethod`, {
      headers: {
        authorization: user.token ? user.token : '',
        'Content-Type': 'application/json'
      }
    });
    return data;
  } catch (error_) {
    console.error('Failed to fetch payment method', error_);
    return null;
  }
}

/**
  * During payment process, we only suggest to user to add money if the wallet still in
  * authorized overdraft after the buy.
  * From wallet, hospital informations and amount of the command, we calculate
  * the amount of necessary refill (to be above the authorized overdraft) and
  * the suggested refill if the wallet after buy is over authorized overdraft, to be over 0
  * Example : authorized wallet = -10, wallet = 0, command = 5 -> suggestRefill = 5, needRefill = 0
  * Example 2 : authorized wallet = -10, wallet = 0, command = 15 -> suggestRefill = 15, needRefill = 5
  * @param {Number} wallet Customer wallet
  * @param {Number} amountCommand Total of command
  * @param {Number} authorizedOverdraft Authorized overdraf
  */
export function getAmountToRefill(wallet = 0, amountCommand = 0, authorizedOverdraft = 0) {
  let suggestRefill = 0;
  let needRefill = 0;

  if (wallet < amountCommand) {
    if (wallet - amountCommand < authorizedOverdraft) {
      needRefill = amountCommand - wallet + authorizedOverdraft;
      needRefill = Math.round((needRefill + Number.EPSILON) * 100) / 100;
    }

    // If the authorized overdraft is not 0, we suggest refill until 0
    if (authorizedOverdraft < 0) {
      suggestRefill = amountCommand - wallet;
      suggestRefill = Math.round((suggestRefill + Number.EPSILON) * 100) / 100;
    }
  }

  return {suggestRefill, needRefill};
}

export function formatPayment(date) {
  if (date) {
    const month = date.substring(0, 2);
    const year = date.substring(2);
    return `${month}/${year}`;
  }
}
