import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {CookiesProvider} from 'react-cookie';
import './index.css';
import App from './app';
import store from './store';

const renderApp = () => {
  console.log('Using following env variables :');
  console.log(`MAX_IDLING_TIME: ${window._env_.MAX_IDLING_TIME}`);
  console.log(`PAYMENT_3DS_URL_HTTP_DIRECT: ${window._env_.PAYMENT_3DS_URL_HTTP_DIRECT}`);
  console.log(`PAYMENT_3DS_URL_RETURN: ${window._env_.PAYMENT_3DS_URL_RETURN}`);

  ReactDOM.render(
    <Provider store={store}>
      <CookiesProvider>
        <App/>
      </CookiesProvider>
    </Provider>,
    document.querySelector('#root')
  );
};

let renderIsDone = false;

setTimeout(() => {
  // Avoid block app
  if (!renderIsDone) {
    renderApp();
    console.warn('Failed to register service worker in an acceptable time.');
  }
}, 1000);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/worker.js`)
      .then(registration => {
        console.log('Worker registration successful', registration.scope);
        renderIsDone = true;
        renderApp();
      }, err => {
        console.log('Worker registration failed', err);
        renderIsDone = true;
        renderApp();
      })
      .catch(error => {
        console.log(error);
        renderIsDone = true;
        renderApp();
      });
  });
} else {
  console.log('Service Worker is not supported by browser.');
  renderIsDone = true;
  renderApp();
}

