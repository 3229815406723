import {formatPayment} from './payment';
import axios from 'axios';
import {get} from 'lodash';

export function setUser(data) {
  let isFilled = false;
  if (
    data.firstname &&
    data.lastname &&
    data.birthdate &&
    data.address &&
    data.address.street &&
    data.address.postal &&
    data.address.city
  ) {
    isFilled = true;
  }

  const payload = {
    user: {
      ...data,
      paymentExp: data.paymentval ? formatPayment(data.paymentval) : null,
      isFilled
    }
  };
  if (data.token) {
    payload.token = data.token;
  }

  return {
    type: 'SET_USER',
    payload
  };
}

export function setUserFromAC(AC, user, token) {
  return function (dispatch) {
    return axios
      .get(`/api/users/si/${user}`, {
        params: {
          code: AC
        },
        headers: {
          'Content-Type': 'application/json',
          authorization: token ? token : ''
        }
      })
      .then(response => {
        if (response.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        dispatch(setTemporaryUser(response.data));

        return {
          result: 'SUCCESS',
          status: 200,
          data: user
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

function setTemporaryUser(data) {
  const isFilled = false;
  const payload = {
    user: {
      ...data,
      isFilled
    }
  };

  return {
    type: 'SET_TMP_USER',
    payload
  };
}

export function setKnownUser(data) {
  const updatedData = JSON.parse(JSON.stringify({...data}));
  const payload = {
    user: {
      ...updatedData,
      paymentExp: data.paymentval ? formatPayment(data.paymentval) : null,
      isFilled: true
    }
  };

  return {
    type: 'COMPLETED_INFO',
    payload
  };
}

function setUserLocation(data) {
  return {
    type: 'SET_USER_LOCATION',
    payload: data
  };
}

export function updateUser(user, hospital) {
  return function (dispatch) {
    return axios
      .put(`/api/users/cloud/${user._id}`, {...user, hospital: hospital.name}, {
        headers: {
          authorization: user.token ? user.token : '',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const updatedUser = {...response.data, location: (user.location || response.data.location)};
        dispatch(setKnownUser(updatedUser));
        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(error => {
        return {
          result: 'ERROR',
          msg: error.response.status === 409 ? 'conflictEmail' : 'serverError'
        };
      });
  };
}

export function validateUserSubscription(user, hospital) {
  return function (dispatch) {
    return axios
      .post(`/api/users/subscription/validate/${user._id}`, {...user, hospital: hospital.name}, {
        headers: {
          authorization: user.token ? user.token : '',
          'Content-Type': 'application/json'
        }
      })
      .then(({data}) => {
        const updatedUser = {...data, location: (user.location || data.location)};
        dispatch(setKnownUser(updatedUser));
        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(error => {
        if (error.response.status === 409) {
          return {result: 'ERROR', msg: 'conflictEmail'};
        }

        return {result: 'ERROR', msg: 'serverError'};
      });
  };
}

export function checkUserValidity(user, hospital) {
  return function () {
    return axios
      .post(`/api/users/subscription/check-user-validity/${user._id}`, {...user, hospital: hospital.name}, {
        headers: {
          authorization: user.token ? user.token : '',
          'Content-Type': 'application/json'
        }
      })
      .then(() => ({result: 'SUCCESS', status: 200}))
      .catch(error => {
        if (error.response.status === 400 && error.response.data.message === 'User not exists in PMS') {
          return {result: 'ERROR', msg: 'userNotExistsInPMS'};
        }

        return {result: 'ERROR', msg: 'serverError'};
      });
  };
}

export const fetchServices = hospID => {
  return new Promise(resolve => {
    axios
      .get(`/api/proxy/si/${hospID}/services?offset=1000`, {
        withCredentials: true
      })
      .then(response => {
        if (response.status !== 200) {
          resolve(false);
        } else {
          resolve({
            result: 'SUCCESS',
            data: response.data
          });
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const fetchLocations = (hospID, servID) => {
  return new Promise(resolve => {
    axios
      .get(`/api/proxy/si/${hospID}/services/${servID}/locations?offset=1000`, {
        withCredentials: true
      })
      .then(response => {
        if (response.status !== 200) {
          resolve(false);
        } else {
          resolve({
            result: 'SUCCESS',
            data: response.data
          });
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export function updateUserLocation(userID, user) {
  return function (dispatch) {
    return axios
      .put(`/api/users/cloud/location/${userID}`, user, {
        headers: {
          authorization: user.token ? user.token : '',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const location = response.data;
        dispatch(setUserLocation(location));
        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export function allocateUserLocation(userID, token, location) {
  return function () {
    return axios
      .post(`/api/users/si/location/${userID}`, location, {
        headers: {
          authorization: token ? token : '',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export function fetchUserPlans(user) {
  return function () {
    return axios
      .get(`/api/proxy/payment/users/${user.customerID}/userplans`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: user.token ? user.token : ''
        }
      })
      .then(response => {
        if (response.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const plans = get(response, 'data', {});
        return {
          result: 'SUCCESS',
          status: 200,
          data: plans
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export const fetchLocationMsg = async hospID => {
  return new Promise(resolve => {
    axios
      .get(`/api/hospitals/${hospID}/customized-msg/location`)
      .then(response => {
        if (response.status !== 200) {
          resolve(false);
        } else {
          resolve({
            result: 'SUCCESS',
            data: response.data
          });
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};

/**
 * Get the associated location of a television from his code
 * @param {String} code Code of the television
 * @param {String} token Authorization bearer token
 * @param {String} hospID ID of the hospital
 * @returns Location of the television
 */
export const getAssociatedLocationFromTvCode = async (code, token, hospID) => {
  console.log('Get associated location from TV code', code);

  const {data: television} = await axios.get(`/api/proxy/si/televisions/code/${code}`, {
    headers: {Authorization: `Bearer ${token}`}
  });

  if (television?.locations?.length <= 0) {
    throw new Error('No location found to found television');
  }

  const updatedLocations = await Promise.all(television.locations.map(async location => {
    if (location.service) {
      const {data: service} = await axios.get(`/api/proxy/si/${hospID}/services/${location.service}`, {
        headers: {Authorization: `Bearer ${token}`}
      });
      return {...location, service};
    }

    return location;
  }));

  return updatedLocations;
};

export const fetchTvCodeMsg = async hospID => {
  return new Promise(resolve => {
    axios
      .get(`/api/hospitals/${hospID}/customized-msg/tv-code`)
      .then(response => {
        if (response.status !== 200) {
          resolve(false);
        } else {
          resolve({
            result: 'SUCCESS',
            data: response.data
          });
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export function getUser(userId) {
  return function (dispatch) {
    return axios
      .get(`/api/users/cloud/${userId}`, {
        headers: {'Content-Type': 'application/json'},
        withCredentials: true
      })
      .then(({data}) => {
        dispatch(setUser(data));
        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

/**
 * Check if a room has an associated TV with a code
 * @param {String} roomId ID of the room
 * @param {String} hospID ID of the hospital
 * @param {String} token Authorization token
 * @returns {Boolean} Romm has code with TV
 */
export const isRoomHasTv = async (roomId, hospID, token) => {
  if (!roomId) {
    return false;
  }

  try {
    const {data: location} = await axios.get(`/api/proxy/si/${hospID}/locations/${roomId}`, {headers: {Authorization: `Bearer ${token}`}});

    if (location?.television?.code) {
      return true;
    }
  } catch (error) {
    console.error('Failed to check if room has TV with code', error);
    return false;
  }

  return false;
};

export const fetchCommandsSuccess = response => {
  return {type: 'FETCH_COMMANDS_SUCCESS', payload: response};
};

export const fetchCommandsFailure = error => {
  return {type: 'FETCH_COMMANDS_FAILURE', payload: error.message};
};

export const fetchCommands = userID => {
  return function (dispatch) {
    return axios.get(`/api/proxy/si/customers/${userID}/commands`, {
      withCredentials: true
    })
      .then(response => {
        dispatch(fetchCommandsSuccess(response));
      })
      .catch(error => {
        dispatch(fetchCommandsFailure(error));
      });
  };
};

export const fetchApiceaCustomer = userID => {
  return function (dispatch) {
    return axios.get('/api/proxy/admin/config.js')
      .then(configResponse => {
        // eslint-disable-next-line no-new-func
        const adminConfig = new new Function(configResponse.data)(); // Trick with new new Function to load config file in js
        if (adminConfig.config.intercoApicea.activated === 'true') {
          return axios.get(`/api/proxy/apicea/apicea/customer/${userID}`)
            .then(response => {
              dispatch(fetchApiceaCustomerDone(response));
            });
        }

        dispatch(fetchApiceaCustomerDone({data: null}));
      })
      .catch(() => {
        dispatch(fetchApiceaCustomerDone({data: null}));
      });
  };
};

export const fetchApiceaCustomerDone = payload => {
  return {type: 'FETCH_APICEA_CUSTOMER_DONE', payload};
};

export const checkUserServiceStatus = (userID, service) => {
  return function (dispatch) {
    return axios.get(`/api/proxy/apps/services/name/${service}?customerId=${userID}`)
      .then(() => {
        dispatch(checkUserServiceStatusDone({service, status: true}));
      })
      .catch(() => {
        dispatch(checkUserServiceStatusDone({service, status: false}));
      });
  };
};

export const checkUserServiceStatusDone = payload => {
  return {type: 'CHECK_USER_SERVICE_STATUS_DONE', payload};
};
