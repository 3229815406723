import React, {useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';

export default function RadioInput({possibilities, setResponse, currentResponse, isReadOnly}) {
  const [value, setValue] = useState((currentResponse && currentResponse.value && currentResponse.value[0]) || '');

  const handleChange = evt => {
    setResponse([evt.target.value]);
    setValue(evt.target.value);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup value={value} onChange={handleChange}>
        {possibilities.map((item, index) => (
          <FormControlLabel key={index} data-testid={`ri-text1-${index}`} value={item} control={<Radio color="primary" disabled={isReadOnly && value !== item}/>} label={item}/>
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RadioInput.propTypes = {
  possibilities: PropTypes.arrayOf(PropTypes.string).isRequired,
  setResponse: PropTypes.func.isRequired,
  currentResponse: PropTypes.object,
  isReadOnly: PropTypes.bool
};
