import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {useIntl} from 'react-intl';

const primaryColor = '#2196F3';
const secondaryColor = '#0069d9';
const thirtyColor = '#0062cc';

const BlueButton = withStyles({
  root: {
    backgroundColor: primaryColor,
    border: `2px solid ${primaryColor}`,
    color: 'white',
    margin: '0.5em',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: secondaryColor,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: thirtyColor
    },
    '&:disabled': {
      backgroundColor: '#f1f1f1'
    }
  }
})(Button);

const WhiteButton = withStyles({
  root: {
    border: `2px solid ${primaryColor}`,
    color: primaryColor,
    backgroundColor: 'white',
    margin: '0.5em',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#f1f1f1',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none'
    }
  }
})(Button);

const SavingDialog = ({isOpen, onClose, onClickOkButton}) => {
  const {formatMessage: f} = useIntl();

  return (
    <Dialog aria-labelledby="question-dialog-title" open={isOpen} onClose={onClose}>
      <DialogTitle id="question-dialog-title">{f({id: 'preadmissionValidation'})}</DialogTitle>
      <DialogContent>
        <DialogContentText id="question-dialog-description">
          <span>{f({id: 'preadmissionValidationNote'})}</span>
          <br/>
          <span>{f({id: 'preadmissionValidationNote2'})}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <WhiteButton variant="contained" onClick={onClose}>{f({id: 'cancel'})}</WhiteButton>
        <BlueButton variant="contained" onClick={onClickOkButton}>{f({id: 'validate'})}</BlueButton>
      </DialogActions>
    </Dialog>
  );
};

SavingDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickOkButton: PropTypes.func.isRequired
};

export default SavingDialog;
