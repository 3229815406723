import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmationCancelFillResponses({isOpen, handleClose, handleConfirm}) {
  const {formatMessage: f} = useIntl();

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{f({id: 'exitSurvey'})}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span data-testid="ccfr-text1" style={{margin: 0, display: 'block'}}>{f({id: 'doYouConfirmLeaveSurvey'})}</span>
          <span data-testid="ccfr-text2" style={{margin: 0, display: 'block'}}>{f({id: 'doYouConfirmLeaveSurveyInfos'})}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid="ccfr-button1" color="primary" onClick={handleClose}>
          {f({id: 'cancel'})}
        </Button>
        <Button autoFocus data-testid="ccfr-button2" color="primary" onClick={handleConfirm}>
          {f({id: 'exit'})}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationCancelFillResponses.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired
};
