import React from 'react';
import {injectIntl} from 'react-intl';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {get} from 'lodash';

import {fetchTypes, postFile, getUserDocumentsMetadata, fetchFile} from '../../../actions/documents';
import {error, success} from '../../../actions';
import FileUploader from '../../shared/FileUploader/file-uploader';
import NotifyDialog from '../../shared/notify-dialog';
import Modal from '../modal';
import DocumentListItem from '../DocumentListItem/documents-list-item';

import styles from './documents-list.module.scss';

class DocumentsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: {},
      types: [],
      user: {},
      userfiles: [],
      token: '',
      openUpload: false,
      openNotify: false,
      uploadParams: {},
      notify: null
    };

    this.displayNotify = (notify, title) => {
      this.setState({openNotify: true, notify: {...notify, title}});
    };

    this.reader = new FileReader();

    this.reader.onloadend = function () {
      if (get(this.reader, 'error.message')) {
        console.error(get(this.reader, 'error.message'));
      }
    };

    this.reader.addEventListener('load', async () => {
      const res = await this.props.postFile(
        this.state.user,
        this.reader.result,
        this.state.loadingType,
        get(this.state, 'loadingFileType'),
        get(this.state, 'loadingFileName'),
        get(this.state, 'uploadParams.category')
      );
      if (res.status === 200) {
        this.props.success(this.props.intl.formatMessage({id: 'dataAddConfirm'}));
        this.props.getUserDocumentsMetadata(this.state.user.token, this.state.user._id);
        const updatedParams = {
          ...this.state.uploadParams,
          uploadProgress: false
        };
        this.setState({uploadParams: updatedParams, openUpload: false});
      } else {
        this.props.error(this.props.intl.formatMessage({id: res.msg || 'error'}));
        this.setState({openUpload: false});
      }

      this.setState({loadingType: null, loadingFileType: null, loadingFileName: null});
    });

    this.handleClose = () => {
      this.setState({openUpload: false, openNotify: false, notify: null});
    };

    this.onFileSubmit = (file, type) => {
      this.setState({loadingType: type, loadingFileType: file.type, loadingFileName: file.name});
      this.reader.readAsDataURL(file);

      const updatedParams = {
        ...this.state.uploadParams,
        uploadProgress: true
      };
      this.setState({uploadParams: updatedParams});
    };

    this.triggerUpload = params => {
      const updatedParams = {
        ...params,
        onClose: this.handleClose,
        onSubmit: this.onFileSubmit
      };
      this.setState({openUpload: true, uploadParams: updatedParams});
    };
  }

  componentDidMount() {
    if (this.state.user) {
      this.props.fetchTypes(this.state.user);
      this.props.getUserDocumentsMetadata(
        get(this.state, 'user.token'),
        get(this.state, 'user._id')
      );
    }
  }

  componentDidUpdate(props) {
    if (props.user && props.user._id !== get(this.state, 'user._id')) {
      this.setState({user: props.user});
    }

    if (props.token !== this.state.token) {
      this.setState({token: props.token});
    }

    if (this.state.userfiles !== props.userfiles) {
      const files = {};
      const userFiles = this.state.userfiles;
      userFiles.forEach(file => {
        files[file.documentType] = {...file, title: file.documentType, fileType: file.type, notify: file.notify};
        this.setState({files});
      });
    }

    if (props.userfiles && this.state.userfiles !== props.userfiles) {
      const files = {};
      const userFiles = this.state.userfiles;
      userFiles.forEach(file => {
        files[file.documentType] = {...file, title: file.documentType, fileType: file.type, notify: file.notify};
        this.setState({files});
      });

      if (Object.keys(files).length === this.state.userfiles.length && this.state.userfiles.length === 0) {
        this.setState({files: {}});
      }
    }

    if (props.types && props.types !== this.state.types) {
      this.setState({types: props.types});
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;
    if (nextProps.user) {
      newProps = newProps ?
        {...newProps, user: nextProps.user} :
        {user: nextProps.user};
    }

    if (nextProps.token) {
      newProps = newProps ?
        {...newProps, token: nextProps.token} :
        {token: nextProps.token};
    }

    if (nextProps.userfiles) {
      newProps = newProps ?
        {...newProps, userfiles: nextProps.userfiles} :
        {userfiles: nextProps.userfiles};
    }

    if (nextProps.types) {
      newProps = newProps ?
        {...newProps, types: nextProps.types} :
        {types: nextProps.types};
    }

    return newProps;
  }

  render() {
    const {formatMessage: f} = this.props.intl;

    return (
      <>
        <div className="pageActions">
          <Breadcrumbs aria-label="breadcrumb">
            <Link data-testid="dl-back" color="inherit" to="/documents">
              {f({id: 'myDocuments'})}
            </Link>
            <Link
              data-testid="dl-current"
              color="textPrimary"
              to="/documents/myPersonalDocuments"
              aria-current="page"
            >
              {f({id: 'myPersonalDocuments'})}
            </Link>
          </Breadcrumbs>
          <Link data-testid="dl-small-screen-back" className="smallScreenBack" to="/documents">
            <ArrowBack/>
          </Link>
        </div>
        <div className={styles.Page}>
          {this.state.types.length === 0 && this.state.userfiles.length === 0 && (
            <p>{f({id: 'noAvailableDocument'})}</p>
          )}

          {this.state.types.map((type, index) => (
            <DocumentListItem key={`item-${index}`} type={type} user={this.state.user} files={this.state.files} notify={this.displayNotify} onUpload={this.triggerUpload}/>
          ))}
          <Modal
            content={{
              component: FileUploader,
              params: this.state.uploadParams
            }}
            open={this.state.openUpload}
            handleClose={this.handleClose}
          />
          {this.state.notify && (
            <NotifyDialog
              isOpen={this.state.openNotify}
              data={this.state.notify}
              onClose={this.handleClose}
            />
          )}

        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  types: get(state, 'documents.types'),
  userfiles: get(state, 'documents.userFiles'),
  token: get(state, 'user.token'),
  user: get(state, 'user.user')
});
export default injectIntl(
  connect(mapStateToProps, {
    fetchTypes,
    postFile,
    fetchFile,
    getUserDocumentsMetadata,
    error,
    success
  })(DocumentsList)
);
