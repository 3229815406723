import React, {useEffect, useMemo, useState} from 'react';
import {FormattedNumber, useIntl} from 'react-intl';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import {getAmountToRefill} from '../../../../actions/payment';
import {useSelector} from 'react-redux';

export default function RefillForm({refillOnly, wallet, total = 0, authorizedOverdraft = 0, onNextButtonDisabledUpdated, handleSupplyChange, onUpdateUserSupply}) {
  const {formatMessage: f} = useIntl();
  const locale = useSelector(state => state.default.locale);
  const {needRefill, suggestRefill} = useMemo(() => getAmountToRefill(wallet, total, -Math.abs(authorizedOverdraft)), [wallet, total, authorizedOverdraft]);
  const [amountToAdd, setAmountToAdd] = useState(0);
  const [soldAfterRefill, setSoldAfterRefill] = useState(0);
  const isOnlySuggestRefill = useMemo(() => needRefill === 0 && suggestRefill > 0, [needRefill, suggestRefill]);

  useEffect(() => {
    const amountToAdd = isOnlySuggestRefill ? total : needRefill;
    setAmountToAdd(amountToAdd);
    handleSupplyChange(amountToAdd);
    onUpdateUserSupply(amountToAdd);
  }, [needRefill, total, wallet]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const soldAfterRefill = wallet - total + amountToAdd;
    setSoldAfterRefill(soldAfterRefill);
    onNextButtonDisabledUpdated(soldAfterRefill < -Math.abs(authorizedOverdraft));
  }, [wallet, total, amountToAdd]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateAmountToAdd = evt => {
    const value = evt.target.value || 0;
    const parsedNumber = Number.parseFloat(value);
    handleSupplyChange(parsedNumber);
    setAmountToAdd(parsedNumber);
    onUpdateUserSupply(parsedNumber);
  };

  /**
   * Return React component to display sold after refill
   * @returns NodeElement
   */
  const RenderSoldAfterRefill = () => {
    const amount = (Math.round(soldAfterRefill * 100) / 100).toFixed(2);
    return (
      <span data-testid="rf-text9" className={`bold ${amount >= 0 ? 'green' : 'red'}`}>
        <FormattedNumber value={amount} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>
      </span>

    );
  };

  return (
    <>
      {(needRefill > 0 || suggestRefill >= 0) && !refillOnly && (
        <>
          <p className="hasCard left">
            <span data-testid="rf-text1" className="bold red">{f({id: 'refillRequired'})}</span>
            <br/>
            <br/>
            {isOnlySuggestRefill ? f({id: 'suggestRefill'}) : f({id: 'refillRequired2'})}
            <span>&nbsp;</span>
            {!isOnlySuggestRefill && (
              <span data-testid="rf-text2" className="red">
                <FormattedNumber value={needRefill} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>
              </span>
            )}
          </p>
          <TableContainer data-testid="rf-table" component={Paper}>
            <Table className="table" aria-label="customized table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <span data-testid="rf-text3" className="bold">{f({id: 'commandTotal'})} </span>
                  </TableCell>
                  <TableCell align="right">
                    <span data-testid="rf-text4">
                      <FormattedNumber value={total} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <span data-testid="rf-text5">{f({id: 'walletBeforeSupply'})} </span>
                  </TableCell>
                  <TableCell align="right">
                    <span data-testid="rf-text6">
                      <FormattedNumber value={wallet} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <span data-testid="rf-text7" className="bold">
                      {f({id: 'transactionAmount'})}
                    </span>
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      data-testid="rf-textfield"
                      id="supplyAccount"
                      type="number"
                      value={amountToAdd}
                      placeholder="0"
                      InputProps={{
                        inputProps: {min: 0, step: 0.01},
                        endAdornment: <>€</>
                      }}
                      onChange={handleUpdateAmountToAdd}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    <span data-testid="rf-text8" className="bold">{f({id: 'walletAfterSupply'})} </span>
                  </TableCell>
                  <TableCell align="left">
                    <RenderSoldAfterRefill/>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

