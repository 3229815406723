import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';

import MenuCard from '../PurchasesPanel/menu-card';
import SupplyAccount from './SupplyAccount/supply-account';
import BuyDay from './BuyDay/buy-day';
import PaymentValidation from './PaymentValidation/payment-validation';
import PaymentConfirmation from './payment-confirmation';
import {getUserStatus, error} from '../../../actions/payment';

function MultimediaPanel({user, onPageSelect}) {
  const {formatMessage: f} = useIntl();
  const dispatch = useDispatch();
  const accountStatus = useSelector(state => state.payment.status);
  const availableDisplay = [
    {value: 'supplyWallet', icon: 'euro_symbol'},
    {value: 'buyDay', icon: 'add_shopping_cart'}
  ];

  useEffect(() => {
    dispatch(getUserStatus(user));
  }, [dispatch, user]);

  useEffect(() => {
    if (accountStatus === 'closed' || accountStatus === 'abandonned') {
      dispatch(error(f({id: 'multimediaServicesClosedNote'})));
      onPageSelect('');
    }
  }, [accountStatus, dispatch, f, onPageSelect]);

  const redirect = page => {
    onPageSelect(`multimedia/${page}`);
  };

  return (
    <div id="tab-1-content" className="pluginPage">
      <h1 data-testid="mb-title" className="headerTitle">{f({id: 'multimediaBooking'})}</h1>
      <div className="userMenu">
        {availableDisplay.map(item => (
          <MenuCard
            key={item.value}
            value={item.value}
            className="card"
            icon={item.icon}
            title={item.value ? f({id: item.value}) : null}
            onTap={() => redirect(item.value)}
          />
        ))}
      </div>
    </div>
  );
}

const multimediaPanel = {
  title: 'multimediaBooking',
  icon: 'shopping_basket',
  identity: 'multimedia',
  mainComponent: MultimediaPanel,
  pages: [
    {
      title: 'supplyWallet',
      component: SupplyAccount,
      parent: 'multimedia'
    },
    {
      title: 'buyDay',
      component: BuyDay,
      parent: 'multimedia'
    },
    {
      title: 'paymentValidation',
      component: PaymentValidation,
      parent: 'multimedia'
    },
    {
      title: 'paymentConfirmation',
      component: PaymentConfirmation,
      parent: 'multimedia'
    }
  ]
};

export default multimediaPanel;
