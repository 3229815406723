import {useRef, useEffect} from 'react';

/**
 * UsePrevious Hook to keep previous value of a data
 */
export default function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
