import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

export default function ProgressBar({total, current}) {
  const {formatMessage: f} = useIntl();

  return (
    <div style={styles.container}>
      <div style={styles.progressBar}>
        <div data-testid="pb-progress-bar" style={{width: `${current / total * 100}%`, height: 10, backgroundColor: 'rgb(33, 150, 243)', borderRadius: 10}}/>
      </div>
      <span>{`${f({id: 'page'})} ${current} ${f({id: 'on'})} ${total}`}</span>
    </div>
  );
}

ProgressBar.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8em',
    width: '100%',
    height: 30,
    paddingRight: 10,
    borderRadius: 2,
    backgroundColor: '#DCDCDC',
    marginTop: 10
  },
  progressBar: {
    flex: 1,
    height: 10,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  }

};
