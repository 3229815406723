import React from 'react';
import {Link} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBack from '@material-ui/icons/ArrowBack';

import InputAdornment from '@material-ui/core/InputAdornment';
import Error from '@material-ui/icons/Error';

import FormInput from '../../shared/FormInput/form-input';

import {forgotPassword, resendActivationMail} from '../../../actions/authentication';
import {success, info} from '../../../actions';

import styles from './forgotten-password.module.scss';

class ForgottenPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hospID: this.props.hospID || null,
      loading: false,
      mail: '',
      alert: {
        open: false,
        title: '',
        text: ''
      },
      message: null
    };

    this.isValid = () => {
      if (this.state.mail.length === 0) {
        return true;
      }

      return false;
    };

    this.sendMail = () => {
      this.props.resendActivationMail(this.state.mail, this.state.hospID, this.props.intl.formatMessage({id: 'mailError'}), this.props.intl.formatMessage({id: 'mailSent'}));
      this.handleClose();
      this.props.onUpdate('login');
    };

    this.connect = async event => {
      event.preventDefault();
      const mailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.state.mail.match(mailRegexp)) {
        this.setState({message: 'invalidMail'});
        return;
      }

      const response = await this.props.forgotPassword({
        email: this.state.mail.toLowerCase(),
        hospID: this.state.hospID
      });
      if (response.result === 'ERROR') {
        if (response.msg === 'serverError') {
          this.props.info(this.props.intl.formatMessage({id: 'errorMailRequest'}));
        }

        if (response.msg === 'wrongMail') {
          this.setState({message: 'wrongMail'});
        }
      } else if (response.result === 'NOTACTIVATED') {
        this.setState({
          alert: {
            open: true,
            title: this.props.intl.formatMessage({id: response.title || 'error'}),
            text: this.props.intl.formatMessage({id: response.msg || 'ok'}),
            cancelText: this.props.intl.formatMessage({id: 'cancel'}),
            confirmText: this.props.intl.formatMessage({id: 'resendActivationMail'}),
            confirmAction: () => this.sendMail()
          }
        });
      } else if (response.status === 200) {
        this.setState({
          alert: {
            open: true,
            title: this.props.intl.formatMessage({id: response.title || 'confirm'}),
            text: this.props.intl.formatMessage({id: response.msg || 'ok'}),
            cancelText: 'Ok'
          }
        });
        this.props.success(this.props.intl.formatMessage({id: response.msg}));
        this.props.onSuccess();
      }
    };
  }

  handleClose() {
    this.setState({
      alert: {
        open: false,
        title: '',
        text: ''
      }
    });
  }

  handleMailChange(event) {
    if (event) {
      this.setState({mail: event.target.value, message: null});
    }
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    return (
      <div className="page main login">
        <div className="pageActions withTop">
          <Link data-testid="forgotten-back" className="backButton" to="/login">
            <ArrowBack/> <span data-testid="forgotten-return" className="label">{f({id: 'return'})}</span>
          </Link>
        </div>
        <div className="pageContent flex column">
          <div className="header">
            <h3 data-testid="forgotten-title">{f({id: 'forgottenPassword'})}</h3>
            <span data-testid="forgotten-process-begin"> {f({id: 'forgottenProcessBegin'})} </span>
            <span data-testid="forgotten-process-end"> {f({id: 'forgottenProcessEnd'})} </span>
          </div>

          <div className="loginForm self-center width30 marginTop">
            <form noValidate className="root" autoComplete="off" onSubmit={this.connect}>
              <FormInput
                required
                data-testid="forgotten-input"
                placeholder={f({id: 'email'})}
                label={f({id: 'email'})}
                type="text"
                id="login"
                error={this.state.message === 'invalidMail'}
                value={this.state.mail}
                InputProps={{
                  endAdornment: (
                    <>
                      {this.state.message === 'invalidMail' && (
                        <InputAdornment position="end">
                          <Error className="red"/>
                        </InputAdornment>
                      )}
                    </>
                  )
                }}
                onChange={event => this.handleMailChange(event)}
              />
              {this.state.message === 'invalidMail' && (
                <span data-testid="forgotten-error1" className={styles.ErrorMsg}>{f({id: 'invalidMail'})}</span>
              )}
              {this.state.message === 'wrongMail' && (
                <span data-testid="forgotten-error2" className={styles.ErrorMsgIcon}><Error className={styles.Icon}/>{f({id: 'wrongMail'})}</span>
              )}
              <br/>

              <Button
                data-testid="forgotten-button"
                id="validate"
                className="flat blue"
                disabled={this.isValid()}
                type="submit"
              >
                {f({id: 'validate'})}
              </Button>
            </form>
            {this.state.loading && <CircularProgress className="loading"/>}
          </div>
        </div>
        <Dialog
          open={this.state.alert.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={() => this.handleClose()}
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.alert.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alert.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testid="forgotten-button2" className="buttonColor white" color="primary" onClick={() => this.handleClose()}>
              {this.state.alert.cancelText}
            </Button>
            {this.state.alert.confirmText && (
              <Button
                data-testid="forgotten-button3"
                className="buttonColor blue"
                color="primary"
                onClick={this.state.alert.confirmAction}
              >
                {this.state.alert.confirmText}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default injectIntl(
  connect(null, {
    forgotPassword,
    success,
    info,
    resendActivationMail
  })(ForgottenPassword)
);
