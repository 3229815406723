import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Divider from '@material-ui/core/Divider';
import {useIntl} from 'react-intl';
import {FETCH_SURVEYS} from '../../../actions/types';
import usePrevious from '../../../hooks/use-previous';
import SurveyListItem from './survey-list-item';
import ResponsesSentSnackbar from '../responses-sent-snackbar';

const ListSurveys = ({onPageSelect}) => {
  const dispatch = useDispatch();
  const {formatMessage: f} = useIntl();
  const surveys = useSelector(state => state.surveys.surveys);
  const user = useSelector(state => state.user.user);
  const token = useSelector(state => state.user.token);
  const surveyResponsesSent = useSelector(state => state.surveys.surveyResponsesSent);
  const prevSurveyResponsesSent = usePrevious(surveyResponsesSent);
  const [snackbarSentResponsesOkOpen, setSnackbarSentResponsesOkOpen] = useState(false);
  const errorSendSurveyResponses = useSelector(state => state.surveys.errorSendSurveyResponses);
  const prevErrorSendSurveyResponses = usePrevious(errorSendSurveyResponses);
  const [snackbarSentResponsesKoOpen, setSnackbarSentResponsesKoOpen] = useState(false);

  useEffect(() => {
    if (surveyResponsesSent && prevSurveyResponsesSent === false) {
      setSnackbarSentResponsesOkOpen(true);
    }
  }, [surveyResponsesSent, prevSurveyResponsesSent]);

  useEffect(() => {
    if (errorSendSurveyResponses && prevErrorSendSurveyResponses === false) {
      setSnackbarSentResponsesKoOpen(true);
    }
  }, [errorSendSurveyResponses, prevErrorSendSurveyResponses]);

  useEffect(() => {
    // Fetch surveys and responses at the startup
    dispatch({type: FETCH_SURVEYS, payload: {token, user}});
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <h1 style={styles.title}>{f({id: 'mySurveys'})}</h1>
      { surveys.length === 0 && <p style={styles.noSurveysMessage}>{f({id: 'noAvailableSurveys'})}</p>}
      {surveys.map(survey => (
        <div key={survey._id}>
          <SurveyListItem survey={survey} onPageSelect={onPageSelect}/>
          <Divider/>
        </div>
      ))}
      <ResponsesSentSnackbar
        isOpen={snackbarSentResponsesOkOpen}
        message={f({id: 'surveyHasBeenSent'})}
        handleClose={() => setSnackbarSentResponsesOkOpen(false)}/>
      <ResponsesSentSnackbar
        isOpen={snackbarSentResponsesKoOpen}
        message={f({id: 'errorOccuredSendingSurveyResponses'})}
        handleClose={() => setSnackbarSentResponsesKoOpen(false)}/>
    </>
  );
};

ListSurveys.propTypes = {
};

const styles = {
  title: {
    marginLeft: '1em'
  },
  noSurveysMessage: {
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center'
  }
};

export default ListSurveys;
