export default function (state = {}, action = {}) {
  switch (action.type) {
    case 'CLEAN_STATE':
      return {...state, history: [], plans: []};
    case 'SET_HISTORY':
      return {...state, history: action.payload.history};
    case 'SET_PLANS':
      return {...state, plans: action.payload.plans};
    case 'SET_COMMAND':
      sessionStorage.setItem('command', JSON.stringify(action.payload.command));
      return {...state, command: action.payload.command};
    case 'CLEAR_COMMAND':
      sessionStorage.removeItem('command');
      return {...state, command: null};
    case 'SET_ACCOUNT_STATUS':
      return {...state, status: action.payload.status};
    default:
      return state;
  }
}
