import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {useHistory, useLocation} from 'react-router-dom';
import {SET_RESPONSES_SELECTED_SURVEY} from '../../../actions/types';
import {postSurveyResponses} from '../../../actions/survey';
import ResponsesSentSnackbar from '../responses-sent-snackbar';
import usePrevious from '../../../hooks/use-previous';
import QuestionPage from './question-page';
import ConfirmationSendResponses from '../confirmation-send-responses';
import ConfirmationCancelFillResponses from '../confirmation-cancel-fill-responses';

export default function SurveyPage() {
  const dispatch = useDispatch();
  const survey = useSelector(state => state.surveys.selectedSurvey);
  const user = useSelector(state => state.user.user);
  const token = useSelector(state => state.user.token);
  const history = useHistory();
  const location = useLocation();
  const {readOnly} = location.state;
  const currentResponses = useSelector(state => state.surveys.selectedSurvey.responses);
  const errorSendSurveyResponses = useSelector(state => state.surveys.errorSendSurveyResponses);
  const prevErrorSendSurveyResponses = usePrevious(errorSendSurveyResponses);
  const [currentQuestion, setCurrentQuestion] = useState(getFirstQuestion(survey));
  const [modalConfirmationIsOpen, setModalConfirmationIsOpen] = useState(false);
  const [modalCancelIsOpen, setModalCancelIsOpen] = useState(false);
  const [snackbarSentResponsesKoOpen, setSnackbarSentResponsesKoOpen] = useState(false);
  const {formatMessage: f} = useIntl();

  useEffect(() => {
    if (errorSendSurveyResponses && !prevErrorSendSurveyResponses) {
      setSnackbarSentResponsesKoOpen(true);
    }
  }, [errorSendSurveyResponses, prevErrorSendSurveyResponses]);

  const onSetResponses = (responses, order) => {
    if (readOnly) {
      return;
    }

    const indexUsedQuestion = currentResponses.findIndex(elt => elt.question === order);
    if (indexUsedQuestion === -1) {
      dispatch({
        type: SET_RESPONSES_SELECTED_SURVEY,
        payload: [...currentResponses, {question: order, value: responses}]
      });
    } else {
      const updatedResponses = [...currentResponses];
      updatedResponses[indexUsedQuestion] = {question: order, value: responses};
      dispatch({
        type: SET_RESPONSES_SELECTED_SURVEY,
        payload: updatedResponses
      });
    }
  };

  const dispatchActionToSendResponses = toAddResponse => {
    // Only send responses already validated
    const toSendResponses = currentResponses.filter(res => res.question <= currentQuestion.order);

    if (toAddResponse) {
      toSendResponses.push(toAddResponse);
    }

    // Handle case of optional last question
    if (toSendResponses.length === survey.questions.length - 1 && survey.questions[survey.questions.length - 1].mandatory === false) {
      toSendResponses.push({question: survey.questions.length - 1, value: []});
    }

    dispatch(postSurveyResponses(toSendResponses, user._id, survey._id, token));
  };

  const onSubmitResponses = () => {
    if (readOnly) {
      history.goBack();
    } else {
      setModalConfirmationIsOpen(true);
    }
  };

  const onCancelSurvey = () => {
    if (readOnly) {
      history.goBack();
    } else {
      setModalCancelIsOpen(true);
    }
  };

  const exitSurvey = () => {
    setModalCancelIsOpen(false);
    history.goBack();
  };

  const validateSendResponses = () => {
    if (!readOnly) {
      dispatchActionToSendResponses();
    }

    setModalConfirmationIsOpen(false);
    history.goBack();
  };

  const handleDismissValidateResponses = () => {
    setModalCancelIsOpen(false);
  };

  const onNextQuestion = toAddResponse => {
    if (!readOnly) {
      dispatchActionToSendResponses(toAddResponse);
    }

    setCurrentQuestion(current => getNextQuestion(survey, current));
  };

  const onPreviousQuestion = () => {
    setCurrentQuestion(current => getPreviousQuestion(survey, current));
  };

  return (
    <>
      <QuestionPage
        isReadOnly={readOnly}
        question={currentQuestion}
        isFirstQuestion={currentQuestion.order === 0}
        isLastQuestion={currentQuestion.order === survey.questions.length - 1}
        onNextQuestion={onNextQuestion}
        onPreviousQuestion={onPreviousQuestion}
        onSubmitResponses={onSubmitResponses}
        onCancelSurvey={onCancelSurvey}
        onSetResponses={onSetResponses}/>
      <ConfirmationSendResponses
        isOpen={modalConfirmationIsOpen}
        handleConfirm={validateSendResponses}
        handleClose={() => setModalConfirmationIsOpen(false)}/>
      <ConfirmationCancelFillResponses
        isOpen={modalCancelIsOpen}
        handleConfirm={exitSurvey}
        handleClose={handleDismissValidateResponses}/>
      <ResponsesSentSnackbar
        isOpen={snackbarSentResponsesKoOpen}
        message={f({id: 'errorOccuredSendingSurveyResponses'})}
        handleClose={() => setSnackbarSentResponsesKoOpen(false)}/>
    </>
  );
}

function getFirstQuestion(survey) {
  if (survey.responseDate) {
    return survey.questions.find(q => q.order === 0);
  }

  let indexFirstQuestion = survey.responses ? survey.responses.length : 0;
  // Avoid out of index
  if (indexFirstQuestion === survey.questions.length) {
    indexFirstQuestion -= 1;
  }

  return survey.questions.find(q => q.order === indexFirstQuestion);
}

function getNextQuestion(survey, currentQuestion) {
  const index = currentQuestion.order + 1;
  return survey.questions.find(q => q.order === index);
}

function getPreviousQuestion(survey, currentQuestion) {
  const index = currentQuestion.order - 1;
  return survey.questions.find(q => q.order === index);
}
