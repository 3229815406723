export default function (state = {progress: {}}, action = {}) {
  switch (action.type) {
    case 'CLEAN_STATE':
      return {...state, preadmission: {sections: []}, currentSection: {}};
    case 'SET_USER_PREADMISSION':
      return {...state, currentSection: action.payload.preadmission};
    case 'SET_PROGRESS':
      return {...state, progress: action.payload.progress};
    case 'SET_PREADMISSION':
      return {...state, sections: action.payload.preadmission.sections, deadline: action.payload.preadmission.deadline};
    case 'PREADMISSION_PARENT_ACTION':
      return {...state, openDialog: action.payload.value, redirect: action.payload.next};
    case 'USER_PREADMISSION_UPDATED':
      return {...state, preadmissionWatcher: Math.random()};
    default:
      return state;
  }
}
