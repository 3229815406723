import React from 'react';
import {useIntl} from 'react-intl';
import MenuCard from '../menu-card';
import DocumentsList from './DocumentsList/documents-list';

const MainComponent = ({onPageSelect}) => {
  const {formatMessage: f} = useIntl();
  const availableDisplay = [
    {value: 'myPersonalDocuments', icon: 'attach_file'}
  ];

  return (
    <div id="tab-1-content" className="pluginPage">
      <h1 className="headerTitle">{f({id: 'myDocuments'})}</h1>
      <div className="userMenu">
        {availableDisplay.map(item => (
          <MenuCard
            key={item.value}
            className="card"
            icon={item.icon}
            title={f({id: item.value})}
            onTap={() => onPageSelect(`documents/${item.value}`)}
          />
        ))}
      </div>
    </div>
  );
};

const documentsPanel = {
  title: 'myDocuments',
  icon: 'attachment',
  identity: 'documents',
  mainComponent: MainComponent,
  pages: [
    {
      title: 'myPersonalDocuments',
      component: DocumentsList,
      parent: 'documents'
    }
  ]
};

export default documentsPanel;
