import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {get, reduce, find} from 'lodash';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';

import moment from 'moment';

import MenuCard from './menu-card';
import LocationForm from '../shared/LocationForm/location-form';
import TvCodeDialog from './TvCodeDialog/tv-code-dialog';
import {getUser, isRoomHasTv} from '../../actions/user';

class PersonnalData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hospital:
        null,

      loading: false,
      user: {},
      token: '',
      display: '',
      authorized: this.props.authorized || ['personalData', 'payment'],
      isLocationDialogOpen: false,
      isTvCodeDialogOpen: false,
      hasAlreadyRoomId: false
    };
  }

  onUpdateLocation = (location, service) => {
    this.setState(prev => ({...prev, user: {...prev.user, location, service}}));
    isRoomHasTv(location?.locID, this.props.user.hospID, this.props.token).then(hasAlreadyRoomId => {
      console.log('HasAlreadyRoomId', hasAlreadyRoomId);
      this.setState({hasAlreadyRoomId});
    });
  }

  componentDidMount() {
    isRoomHasTv(this.props.user?.location?.locID, this.props.user.hospID, this.props.token).then(hasAlreadyRoomId => {
      console.log('HasAlreadyRoomId', hasAlreadyRoomId);
      this.setState({hasAlreadyRoomId});
    });
  }

  componentDidUpdate(props) {
    if (props.hospital && props.hospital.hospID !== get(this.state, 'hospital.hospID')) {
      this.setState({hospital: props.hospital});
    }

    if (props.user && props.user._id !== get(this.state, 'user._id')) {
      isRoomHasTv(props.user?.location?.locID, props.user.hospID, props.token).then(hasAlreadyRoomId => {
        console.log('HasAlreadyRoomId', hasAlreadyRoomId);
        this.setState({hasAlreadyRoomId});
      });

      this.setState({
        user: props.user,
        civility: props.user.civility || 'M.',
        firstname: props.user.firstname || '',
        lastname: props.user.lastname || '',
        birthdate: props.user.birthdate || moment().toDate(),
        personnalTph: props.user.personnalTph || '',
        address: get(props, 'user.address.street'),
        postCode: get(props, 'user.address.postal'),
        city: get(props, 'user.address.city')
      });
    }

    if (props.token && props.token !== this.state.token) {
      this.setState({token: props.token});
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;
    if (nextProps.hospital) {
      newProps = newProps ? {...newProps, hospital: nextProps.hospital} : {hospital: nextProps.hospital};
    }

    if (nextProps.user) {
      newProps = newProps ? {...newProps, user: nextProps.user} : {user: nextProps.user};
    }

    if (nextProps.token) {
      newProps = newProps ? {...newProps, token: nextProps.token} : {token: nextProps.token};
    }

    return newProps;
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    const availableDisplay = [
      {value: 'myInformations', icon: 'person'},
      {value: 'modifyPassword', icon: 'lock'},
      {value: 'myActivationCode', icon: 'vpn_key'},
      {value: 'myActiveServices', icon: 'apps', use: 'payment'},
      {value: 'paymentHistory', icon: 'format_list_bulleted'},
      {value: 'myInvoices', icon: 'receipt'},
      {value: 'legal', icon: 'menu_book'}
    ];

    const allowedDisplay = reduce(availableDisplay, (acc, item) => {
      if (item.use) {
        if (find(this.state.authorized, allowed => {
          return allowed === item.use;
        })) {
          acc.push(item);
        }
      } else {
        acc.push(item);
      }

      return acc;
    }, []);

    const handleCloseLocationDialog = () => {
      this.setState({isLocationDialogOpen: false});
    };

    const handleCloseTvCodeDialog = () => this.setState({isTvCodeDialogOpen: false});

    return (
      <div id="tab-1-content" className="">
        <h1 className="headerTitle">{f({id: 'myAccount'})}</h1>
        {this.props.tvCoaxProcess?.location?.isEnabled &&
          <div>
            <Dialog fullWidth maxWidth="sm" open={this.state.isLocationDialogOpen} onClose={handleCloseLocationDialog}>
              <LocationForm
                {...this.props}
                handleOpenLocationPage={handleCloseLocationDialog}
                onUpdateLocation={this.onUpdateLocation}
              />
            </Dialog>
            <div className="userMenu">
              <MenuCard
                key="myLocation"
                dataTestId="menu-card-my-location"
                className="card"
                icon="room"
                title={this.state.user.location ? f({id: 'modifyLocation'}) : f({id: 'selectLocation'})}
                onTap={() => this.setState({isLocationDialogOpen: true})}
              />
            </div>
            <Divider/>
          </div>}
        {this.props.tvCoaxProcess?.code?.isEnabled &&
          <div>
            <Dialog fullWidth maxWidth="sm" open={this.state.isTvCodeDialogOpen} onClose={handleCloseTvCodeDialog}>
              <TvCodeDialog
                hospital={this.props.hospital}
                user={this.props.user}
                token={this.props.token}
                handleClose={handleCloseTvCodeDialog}
                hasAlreadyTv={this.state.hasAlreadyRoomId}
                onUpdateLocation={this.onUpdateLocation}
              />
            </Dialog>
            <div className="userMenu">
              <MenuCard
                key="myTvCode"
                dataTestId="menu-card-my-code"
                className="card"
                icon={this.state.hasAlreadyRoomId ? 'trending_flat' : 'password'}
                title={f({id: this.state.hasAlreadyRoomId ? 'changeRooms' : 'selectTvCode'})}
                onTap={() => this.setState({isTvCodeDialogOpen: true})}
              />
            </div>
            <Divider/>
          </div>}
        <div className="userMenu">
          {allowedDisplay.map(item => (
            <MenuCard
              key={item.value}
              className="card"
              icon={item.icon}
              title={f({id: item.value})}
              onTap={() => this.props.onPageSelect(`personalData/${item.value}`)}
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hospital: get(state, 'default.hospital.hospital'),
    user: get(state, 'user.user'),
    token: get(state, 'user.token'),
    tvCoaxProcess: get(state, 'default.tvCoaxProcess')
  };
};

export default injectIntl(connect(mapStateToProps, {
  getUser
})(PersonnalData));
