import React from 'react';
import {Link} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {get} from 'lodash';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from '@material-ui/icons/Error';

import {login, resendActivationMail} from '../../../actions/authentication';
import {error} from '../../../actions';
import FormInput from '../../shared/FormInput/form-input';

import styles from './login.module.scss';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hospID: this.props.hospID || null,
      loading: false,
      mail: '',
      password: '',
      error: undefined,
      alert: {
        open: false,
        title: '',
        text: ''
      }
    };

    this.sendMail = () => {
      this.props.resendActivationMail(this.state.mail, this.state.hospID, this.props.intl.formatMessage({id: 'mailError'}), this.props.intl.formatMessage({id: 'mailSent'}));
      this.handleClose();
    };

    this.isValid = () => {
      if (this.state.mail.length === 0 || this.state.password.length === 0) {
        return true;
      }

      return false;
    };

    this.handleClose = () => {
      this.setState({
        alert: {
          open: false,
          title: '',
          text: ''
        }
      });
    };

    this.connect = async event => {
      event.preventDefault();
      try {
        const response = await this.props.login({
          mail: this.state.mail.toLowerCase(),
          password: this.state.password,
          hospID: this.state.hospID
        });
        if (response.result === 'NOTACTIVATED') {
          this.setState({
            alert: {
              open: true,
              title: this.props.intl.formatMessage({id: response.title || 'error'}),
              text: this.props.intl.formatMessage({id: response.msg || 'ok'}),
              cancelText: this.props.intl.formatMessage({id: 'cancel'}),
              confirmText: this.props.intl.formatMessage({id: 'resendActivationMail'}),
              confirmAction: () => this.sendMail()
            }
          });
        } else if (response.result === 'ERROR') {
          if (response.msg) {
            const message = this.props.intl.formatMessage({id: response.msg});
            this.setState({error: message});
          }
        } else if (response.status === 200) {
          this.props.onLogin();
        }
      } catch (error_) {
        if (error_) {
          this.props.error('An error occured');
        }
      }
    };
  }

  handleMailChange(event) {
    if (event) {
      this.setState({mail: event.target.value});
    }
  }

  handlePasswordChange(event) {
    if (event) {
      this.setState({password: event.target.value});
    }
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    const {goBack, location} = this.props.source;
    const from = get(location, 'state.from');

    return (
      <div className="page main login">
        <div className="pageActions withTop">
          {from === 'group' &&
          <Button className="backButton" onClick={goBack}>
            <ArrowBack/> <span className="label">{f({id: 'selectReturn'})}</span>
          </Button>}

        </div>
        <div className="pageContent noTop flex column">
          <div className="header">
            {this.state.hospID && (
              <img
                id="logo"
                className={styles.Logo}
                src={`/api/hospitals/${this.props.hospital.hospID}/application/logo`}
                alt="Logo"
                data-testid="login-image1"
              />
            )}
          </div>

          {this.props.isLogoutByTimeout && <p data-testid="login-text1" className="self-center" style={{fontWeight: 'bold'}}>{f({id: 'sessionExpired'})}</p>}

          <div className="loginForm self-center">

            <form noValidate className={styles.Form} autoComplete="off" onSubmit={this.connect}>
              {this.state.error && <span data-testid="login-error" className={styles.ErrorMsg}><WarningIcon className={styles.Icon}/> {this.state.error}</span>}
              <FormInput
                className={styles.FormInput}
                placeholder={f({id: 'email'})}
                label={f({id: 'email'})}
                type="text"
                id="login"
                data-testid="login-input1"
                value={this.state.mail}
                onChange={event => this.handleMailChange(event)}
              />
              <br/>

              <FormInput
                className={styles.FormInput}
                placeholder={f({id: 'password'})}
                label={f({id: 'password'})}
                type="password"
                id="password"
                data-testid="login-input2"
                value={this.state.password}
                onChange={event => this.handlePasswordChange(event)}
              />
              <br/>
              <Link data-testid="login-button1" className="backButton" to="/changepassword">
                <span id="forgotten">{f({id: 'forgottenPassword'})}</span>
              </Link>

              <br/>
              <Button
                id="validate"
                data-testid="login-button2"
                className="flat blue"
                disabled={this.isValid()}
                type="submit"
              >
                {f({id: 'connect'})}
              </Button>
            </form>
            {this.state.loading && <CircularProgress className="loading"/>}

            <Divider/>
            <div className="marginTop">
              <Link data-testid="login-button3" className="backButton marginTop" to="/signup">
                <span className="label"> {f({id: 'signup'})} </span>
              </Link>
            </div>
          </div>
        </div>
        <Dialog
          open={this.state.alert.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={() => this.handleClose()}
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.alert.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alert.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testid="login-button4" className="buttonColor white" color="primary" onClick={this.handleClose}>
              {this.state.alert.cancelText}
            </Button>
            {this.state.alert.confirmText && (
              <Button
                data-testid="login-button5"
                className="buttonColor blue"
                color="primary"
                onClick={this.state.alert.confirmAction}
              >
                {this.state.alert.confirmText}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hospital: get(state, 'default.hospital.hospital')
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    login,
    error,
    resendActivationMail
  })(Login)
);
