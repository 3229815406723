import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link as ButtonLink} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {get} from 'lodash';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Edit from '@material-ui/icons/Edit';
import {Divider} from '@material-ui/core';

import 'moment/locale/fr';
import moment from 'moment';
import styles from './personal-data-form.module.scss';

import {fetchApiceaCustomer, checkUserServiceStatus} from '../../../actions/user';

const PersonnalDataShow = ({hospital, user = {}, apiceaUser, services, fetchApiceaCustomer, checkUserServiceStatus, intl, lang}) => {
  const [wifiAccount, setWifiAccount] = useState();
  const {formatMessage: f} = intl;

  useEffect(() => {
    if (user.customerID) {
      fetchApiceaCustomer(user.customerID);
      checkUserServiceStatus(user.customerID, 'wifi');
    }
  }, [user.customerID]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (services?.wifi) {
      if (apiceaUser) {
        setWifiAccount({
          login: apiceaUser.login,
          code: apiceaUser.password
        });
      } else {
        setWifiAccount({
          accessPoint: user?.location?.accessPoint,
          login: user?.wifiLogin,
          code: user?.keyCode
        });
      }
    } else {
      setWifiAccount(null);
    }
  }, [apiceaUser, services]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkDisplayExternalPhoneNumber = hospital => {
    return hospital?.preferences?.phoneParams?.outgoing?.displayType === 'externalNum';
  };

  const formatDate = date => {
    return moment(date, 'YYYYMMDD').locale(lang || 'fr').format('LL');
  };

  const birthdateString = formatDate(user.birthdate);

  const formatFirstName = (name = '') => {
    const splitString = name.toLowerCase().split('-');
    for (let i = 0; i < splitString.length; i++) {
      splitString[i] = splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
    }

    return splitString.join('-');
  };

  const formatLastName = (name = '') => {
    return name.toUpperCase();
  };

  const parsePhone = user => {
    const tel = user.phoneNumber;
    const returnValue = [];

    if (tel && !/\s/.test(tel)) {
      for (let i = 0, {length} = tel; i < length; i += 2) {
        returnValue.push(tel.substr(i, 2));
      }

      return returnValue.join(' ');
    }

    return tel;
  };

  return (
    <div
      className="personalForm"
    >
      <div className="pageActions">
        <Breadcrumbs aria-label="breadcrumb">
          <ButtonLink data-testid="pds-back" color="inherit" to="/personalData">
            {f({id: 'myAccount'})}
          </ButtonLink>
          <ButtonLink
            data-testid="pds-current"
            color="textPrimary"
            to="/personalData/myInformations"
            aria-current="page"
          >
            {f({id: 'myInformations'})}
          </ButtonLink>
        </Breadcrumbs>
        <ButtonLink data-testid="pds-smallscreen-back" className="smallScreenBack" to="/personalData">
          <ArrowBack/>
        </ButtonLink>

      </div>
      <div className={styles.Actions}>
        <ButtonLink data-testid="pds-edit" className={styles.ModifyButton} to="/personalData/edit">
          <Edit/> <span data-testid="pds-edit-text" className="label">{f({id: 'modify'})}</span>
        </ButtonLink>
      </div>

      <div className="pageContent">
        <div className="personnalDataShow">
          <div className="data-display">
            <p>
              <span data-testid="pds-civility-label" className="bold">{f({id: 'civilityLabel'})}</span>
              <span data-testid="pds-civility-value">{user.civility ? f({id: user.civility}) : null}</span>
            </p>
            <p>
              <span data-testid="pds-lastname-label" className="bold">{f({id: 'lastnameLabel'})}</span>
              <span data-testid="pds-lastname-value">{formatLastName(user.lastname)}</span>
            </p>
            <p>
              <span data-testid="pds-firstname-label" className="bold">{f({id: 'firstnameLabel'})}</span>
              <span data-testid="pds-firstname-value">{formatFirstName(user.firstname)}</span>
            </p>
            <p>
              <span data-testid="pds-birthdate-label" className="bold">{f({id: 'birthdateLabel'})}</span>
              <span data-testid="pds-birthdate-value">{birthdateString}</span>
            </p>
            <p>
              <span data-testid="pds-email-label" className="bold">{f({id: 'emailLabel'})}</span>
              <span data-testid="pds-email-value">{user.mail}</span>
            </p>
          </div>

          <div className="data-display">
            <p>
              <span data-testid="pds-address-label" className="bold">{f({id: 'addressLabel'})}</span>
              <span data-testid="pds-address-value">{get(user, 'address.street')}</span>
            </p>
            <p>
              <span data-testid="pds-postal-label" className="bold">{f({id: 'postalLabel'})}</span>
              <span data-testid="pds-postal-value">{get(user, 'address.postal')}</span>
            </p>
            <p>
              <span data-testid="pds-city-label" className="bold">{f({id: 'cityLabel'})}</span>
              <span data-testid="pds-city-value">{get(user, 'address.city')}</span>
            </p>
            <p>
              <span data-testid="pds-tel-label" className="bold">{f({id: 'telLabel'})}</span>
              <span data-testid="pds-tel-value">{user.personnalTph}</span>
            </p>
          </div>
        </div>

        <Divider/>

        <div className="personnalDataShow">
          <div className="data-display">
            <h2>{f({id: 'telephony'})}</h2>

            <p>
              <span className="bold">{f({id: 'toCallYouFromOutside'})}</span>
              <span>{parsePhone(user) || '/'}</span>
            </p>

            {user.phoneNumberOutgoing && !checkDisplayExternalPhoneNumber(hospital) && (
              <div>
                <div>
                  <span className="bold">{f({id: 'toCallOutside'})}</span>

                  <div className="telephonyProcessItem">
                    <span>{f({id: 'composeCallServer'})}</span>
                    <span className="telephonyProcessItemValue">{user.phoneNumberOutgoing}</span>
                  </div>

                  <div className="telephonyProcessItem">
                    <span>{f({id: 'composeYourCode'})}</span>
                    <span className="telephonyProcessItemValue">{user.tphCode}</span>
                  </div>

                  <div className="telephonyProcessItem">
                    <span>{f({id: 'composeCorrespondentNumber'})}</span>
                  </div>
                </div>
              </div>
            )}

            {user.phoneDisplayExternal && (
              <div>
                <span className="bold">{f({id: 'toCallOutside'})}</span>

                <div className="telephonyProcessItem">
                  <span>{f({id: 'composeCorrespondentNumber'})}</span>
                </div>

                <div className="telephonyProcessItem">
                  <span>{f({id: 'composeYourCode'})}</span>
                  <span className="telephonyProcessItemValue">{user.tphCode}</span>
                </div>
              </div>
            )}

          </div>
        </div>

        <Divider/>

        {hospital.process.accountDisplayedTabs.wifi &&
          <div className="personnalDataShow">
            <div className="data-display">
              <h2>{f({id: 'wifi'})}</h2>
              {wifiAccount ?
                <>
                  {wifiAccount.accessPoint &&
                  <p>
                    <span className="bold">{f({id: 'wifiAccessPoint'})}</span>
                    <span>{wifiAccount.accessPoint}</span>
                  </p>}
                  {wifiAccount.login &&
                  <p>
                    <span className="bold">{f({id: 'wifiLogin'})}</span>
                    <span>{wifiAccount.login}</span>
                  </p>}
                  {wifiAccount.code &&
                  <p>
                    <span className="bold">{f({id: 'wifiCode'})}</span>
                    <span>{wifiAccount.code}</span>
                  </p>}
                </> :
                <>
                  {f({id: 'wifiUnavailable'})}
                </>}
            </div>
          </div>}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    hospital: get(state, 'default.hospital.hospital'),
    user: get(state, 'user.user'),
    token: get(state, 'user.token'),
    apiceaUser: get(state, 'user.apiceaUser'),
    services: get(state, 'user.services')
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    fetchApiceaCustomer,
    checkUserServiceStatus
  })(PersonnalDataShow)
);
