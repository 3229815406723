import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {FormControl, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {useDispatch} from 'react-redux';

import styles from './tv-code-dialog.module.scss';
import BlueButton from '../../blue-button';
import WhiteButton from '../../white-button';

import {} from '../../../actions/authentication';
import {updateUser, fetchUserPlans, allocateUserLocation, updateUserLocation, fetchTvCodeMsg, getAssociatedLocationFromTvCode} from '../../../actions/user';
import {success, error} from '../../../actions';

import {convertTextInMultilines} from '../../shared/convert-text-in-multilines';

const TvCodeDialog = ({hospital, user, token, handleClose, hasAlreadyTv, onUpdateLocation}) => {
  const {formatMessage: f} = useIntl();
  const [tvCode, setTvCode] = useState('');
  const [availableLocations, setAvailableLocations] = useState([]);
  const [associatedLocation, setAssociatedLocation] = useState(null);
  const [tvCodeMessage, setTvCodeMessage] = useState('');
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTvCodeMsg(user?.hospID || hospital?.hospID)
      .then(({result, data}) => {
        if (result === 'SUCCESS' && data) {
          setTvCodeMessage(data);
        }
      });
  }, [hospital, user]);

  const handleValidate = async () => {
    try {
      const locations = await getAssociatedLocationFromTvCode(tvCode, token, hospital?.hospID);
      setAvailableLocations(locations);

      if (locations.length === 1) {
        setAssociatedLocation(locations[0]);
      }

      setStep(1);
    } catch (error_) {
      console.error('Failed to get associated location', error_);
      handleClose();
      dispatch(error(f({id: 'tvCodeNotValid'})));
    }
  };

  const handleConfirm = async () => {
    const isSuccess = await updateUser(user);
    if (isSuccess && associatedLocation) {
      const location = {locID: associatedLocation.locID, room: associatedLocation.room};
      const service = {servID: associatedLocation?.service.servID, name: associatedLocation?.service?.name};

      const body = {token: token || '', hospID: hospital?.hospID, location, service};
      const {status, msg} = await dispatch(updateUserLocation(user._id, body));

      if (status === 200) {
        dispatch(success(f({id: 'successUpdateTvCode'}, {room: associatedLocation?.room || '/'})));
        onUpdateLocation(location, service);

        if (user.plans?.length > 0) {
          const response = await dispatch(fetchUserPlans({customerID: user.uuid, token}));
          if (response.data?.items?.length > 0) {
            const body = {customerID: user.uuid, hospID: hospital.hospID, location};
            await dispatch(allocateUserLocation(user._id, token, body));
          }
        }
      } else {
        dispatch(error(f({id: msg || 'error'})));
      }
    }

    handleClose();
  };

  const renderTypeCode = () => (
    <>
      <h1 className={styles.title}>{f({id: hasAlreadyTv ? 'roomChange' : 'typeTvCode'})}</h1>
      <p className={styles.subtitle}>{f({id: hasAlreadyTv ? 'roomChangeDescription' : 'fillTvCodeDescription'})}</p>

      <TextField
        required
        style={{width: 200, margin: 20}}
        value={tvCode}
        variant="filled"
        placeholder={`${f({id: 'tvCode'})} *`}
        onChange={evt => setTvCode(evt.target.value)}/>

      {tvCodeMessage &&
        <div className={styles.tvCodeMsgContainer}>
          <InfoOutlinedIcon className="grey"/>
          {convertTextInMultilines(tvCodeMessage)}
        </div>}

      <p className={styles.mandatoryInfo}><span className={styles.mandatoryInfoSymbol}>*</span>{f({id: 'indicateMandatoryInformation'})}</p>

      <div className={styles.buttons}>
        <WhiteButton
          className={styles.button}
          variant="outlined"
          onClick={handleClose}
        >
          {f({id: 'cancel'})}
        </WhiteButton>
        {tvCode.length > 0 ? (
          <BlueButton className={styles.button} variant="contained" onClick={handleValidate}>
            {f({id: 'validate'})}
          </BlueButton>
        ) : <div/>}
      </div>
    </>
  );

  const renderValidateRoom = () => (
    <>
      <h1 className={styles.checkRoomTitle}>{f({id: 'checkingTheRoom'})}</h1>
      {
        availableLocations.length > 1 ? (
          <p style={{fontWeight: 'bold'}}>{f({id: 'checkingTheRoomDescriptionDouble'}, {service: availableLocations[0]?.service?.name || '/'})}</p>
        ) : (
          <>
            <p style={{fontWeight: 'bold'}}>{f({id: 'checkingTheRoomDescription1'}, {room: associatedLocation?.room || '/', service: associatedLocation?.service?.name || '/'})}</p>
            <p>{f({id: 'checkingTheRoomDescription2'})}</p>
            <p>{f({id: 'checkingTheRoomDescription3'})}</p>
          </>
        )
      }
      {
        availableLocations.length > 1 && (
          <FormControl className={styles.roomSelector}>
            <InputLabel>{f({id: 'room'})}</InputLabel>
            <Select value={associatedLocation} onChange={evt => setAssociatedLocation(evt.target.value)}>
              {
                availableLocations.map(elt => (
                  <MenuItem key={elt._id} value={elt}>{elt.room}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        )
      }

      { availableLocations.length > 1 && <p>{f({id: 'checkingTheRoomDoubleRoomInfo'})}</p>}

      <div className={styles.buttons}>
        <WhiteButton
          variant="outlined" onClick={() => {
            setAvailableLocations([]);
            setStep(0);
          }}
        >
          {f({id: 'cancel'})}
        </WhiteButton>
        <BlueButton disabled={!associatedLocation} variant="contained" onClick={handleConfirm}>
          {f({id: 'confirm'})}
        </BlueButton>
      </div>
    </>
  );

  return (
    <div className={styles.container}>
      {step === 0 && renderTypeCode()}
      {step === 1 && renderValidateRoom()}
    </div>
  );
};

TvCodeDialog.propTypes = {
  hospital: PropTypes.object,
  user: PropTypes.object,
  token: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  hasAlreadyTv: PropTypes.bool
};

export default TvCodeDialog;
