import axios from 'axios';

export const clearOutsideClick = () => {
  return dispatch => {
    dispatch({type: 'PREADMISSION_PARENT_ACTION', payload: {value: false, next: null}});
  };
};

export function fetchPreadmission(user) {
  return function (dispatch) {
    return axios
      .get(`/api/proxy/preadmission/publishedpreadmission/${user._id}`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: user.token ? user.token : ''
        }
      })
      .then(res => {
        if (res.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const preadmission = res.data;

        dispatch(setPreadmission(preadmission));
        return {
          result: 'SUCCESS',
          status: 200,
          data: preadmission
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export function saveSection(user, id, section, finished) {
  return async function () {
    return axios
      .post(`/api/proxy/preadmission/save/${user._id}/${id}`, {section, finished}, {
        headers: {
          'content-type': 'application/json',
          authorization: user.token ? user.token : ''
        },
        timeout: 30000
      })
      .then(res => {
        if (res.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export function fetchProgress(user) {
  return function (dispatch) {
    return axios
      .get(`/api/proxy/preadmission/progress/${user._id}`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: user.token ? user.token : ''
        }
      })
      .then(res => {
        if (res.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const progress = res.data;

        dispatch(setProgress(progress));
        return {
          result: 'SUCCESS',
          status: 200,
          data: progress
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export function fetchUserPreadmission(user, id) {
  return function (dispatch) {
    return axios
      .get(`/api/proxy/preadmission/save/${user._id}/${id}`, {
        headers: {
          'content-type': 'application/json',
          authorization: user.token ? user.token : ''
        },
        timeout: 30000
      })
      .then(res => {
        if (res.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const preadmission = res.data;

        dispatch(setUserPreadmission(preadmission));
        return {
          result: 'SUCCESS',
          status: 200,
          data: preadmission
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

function setProgress(progress) {
  return {
    type: 'SET_PROGRESS',
    payload: {progress}
  };
}

function setPreadmission(preadmission) {
  return {
    type: 'SET_PREADMISSION',
    payload: {
      preadmission
    }
  };
}

function setUserPreadmission(preadmission) {
  return {
    type: 'SET_USER_PREADMISSION',
    payload: {
      preadmission
    }
  };
}
