import React, {useEffect} from 'react';
import socketIOClient from 'socket.io-client';
import {useDispatch} from 'react-redux';

import {formatUser} from '../actions';

export default function SocketHandler({user, logout}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && user._id) {
      const socket = new socketIOClient('', {path: '/notifywsocket/socket.io'}); // eslint-disable-line new-cap

      socket.on('connect', () => {
        console.log('connected to websockets server');
      });
      socket.on('please_connect', () => {
        socket.emit('subscribeToNotifications', {id: user._id}, r => {
          console.log(r);
        });
      });
      socket.on('status_update', data => {
        dispatch({type: 'USER_STATUS_UPDATED', payload: data});
      });
      socket.on('delete_user', () => {
        logout();
      });
      socket.on('updated_user', data => {
        const payload = formatUser(data);
        dispatch({type: 'SET_USER', payload});
      });
      socket.on('documents_notify', data => {
        dispatch({type: 'USER_DOCUMENTS_NOTIFY', payload: data});
      });
      socket.on('preadmission_notify', data => {
        dispatch({type: 'USER_PREADMISSION_NOTIFY', payload: data});
      });
      socket.on('preadmission_version_updated', data => {
        dispatch({type: 'USER_PREADMISSION_UPDATED', payload: data});
      });
      socket.on('updated_modules', data => {
        dispatch({type: 'HOSPITAL_MODULES_UPDATED', payload: data});
      });
      socket.on('updated_hospital_name', data => {
        dispatch({type: 'SET_APPLICATION_NAME', payload: data});
      });
      socket.on('updated_hospital_logo', () => {
        dispatch({type: 'UPDATED_HOSPITAL'});
      });
      socket.on('updated_hospital_tvcoax_process', data => {
        dispatch({type: 'SET_TVCOAX_PROCESS', payload: data});
      });
    }
  }, [dispatch, logout, user]);

  return (
    <>
    </>
  );
}
