import {combineReducers} from 'redux';
import paymentReducer from './payment';
import userReducer from './user';
import documentReducer from './documents';
import surveysReducer from './survey';
import preadmissionReducer from './preadmission';

import {
  SET_HOSPITALS,
  SET_HOSPITAL,
  SET_LOCALE,
  SET_APPLICATION_NAME,
  SET_TVCOAX_PROCESS,
  FETCH_HOSPITALS_DONE,
  FETCH_HOSPITALS_FAILED,
  GET_HOSPITAL,
  GET_HOSPITAL_DONE,
  GET_HOSPITAL_FAILED,
  GET_HOSPITALS_BY_GROUP,
  GET_HOSPITALS_BY_GROUP_DONE,
  GET_HOSPITALS_BY_GROUP_FAILED,
  LOGOUT_BY_TIMEOUT,
  LOGGED,
  HOSPITAL_MODULES_UPDATED,
  UPDATED_HOSPITAL,
  HOSPID_NOT_FOUND
} from '../actions/types';

const defaultState = {
  locale: 'fr',
  isLogoutByTimeout: false,
  hospIDNotFound: null
};

const defaultReducer = function (state = defaultState, action) { // eslint-disable-line default-param-last
  switch (action.type) {
    case LOGOUT_BY_TIMEOUT:
      return Object.assign({}, state, {
        isLogoutByTimeout: true
      });
    case LOGGED:
      return Object.assign({}, state, {
        isLogoutByTimeout: false
      });
    case SET_LOCALE:
      return Object.assign({}, state, {
        locale: action.payload
      });
    case FETCH_HOSPITALS_DONE:
      return {...state, fetchHospitalsDone: true};
    case FETCH_HOSPITALS_FAILED:
      return {...state, fetchHospitalsError: true};
    case SET_HOSPITALS:
      if (action.payload.hospitals) {
        return {...state, hospitals: action.payload};
      }

      break;
    case SET_HOSPITAL:
      if (action.payload.hospital) {
        const whiteList = initWhiteList(action.payload.hospital);
        localStorage.setItem(
          'whiteList',
          JSON.stringify(initWhiteList(action.payload.hospital))
        );
        localStorage.setItem(
          'hospital',
          JSON.stringify(action.payload.hospital)
        );
        const hospital = Object.assign({}, state.hospital, {
          ...action.payload,
          whiteList
        });
        return {...state, hospital};
      }

      break;
    case HOSPITAL_MODULES_UPDATED:
      const hospital = {...state.hospital, modules: action.payload.modules};
      const whiteList = initWhiteList(hospital);

      return {...state, hospital: {...state.hospital, whiteList}};

    case 'CLEAN_STATE':
      return {
        ...state,
        /*   Hospital: {
          hospital: {},
          whiteList: []
        }, */
        applicationName: '',
        tvCoaxProcess: {},
        getHospitalDone: false,
        getHospitalsByGroupDone: false
      };
    case GET_HOSPITAL:
      return {...state, getHospitalError: null};
    case GET_HOSPITAL_DONE:
      return {...state, getHospitalDone: true};
    case GET_HOSPITAL_FAILED:
      return {...state, getHospitalError: 'linkIncorrect'};
    case GET_HOSPITALS_BY_GROUP:
      return {...state, getHospitalsByGroupError: null};
    case GET_HOSPITALS_BY_GROUP_DONE:
      return {...state, getHospitalsByGroupDone: true};
    case GET_HOSPITALS_BY_GROUP_FAILED:
      return {...state, getHospitalsByGroupError: 'linkIncorrect'};
    case SET_APPLICATION_NAME:
      return {...state, applicationName: action.payload};
    case SET_TVCOAX_PROCESS:
      return {...state, tvCoaxProcess: action.payload};
    case UPDATED_HOSPITAL:
      return {...state, hospitalWatcher: Math.random()};
    case HOSPID_NOT_FOUND:
      return {...state, hospIDNotFound: action.payload};
    default:
      return state;
  }
};

const uiReducer = (state = {}, action) => { // eslint-disable-line default-param-last
  switch (action.type) {
    case 'SNACKBAR_SUCCESS':
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.message,
        snackbarType: 'success'
      };
    case 'SNACKBAR_INFO':
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.message,
        snackbarType: 'info'
      };
    case 'SNACKBAR_ERROR':
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.message,
        snackbarType: 'error'
      };
    case 'SNACKBAR_WARNING':
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.message,
        snackbarType: 'warning'
      };
    case 'SNACKBAR_CLEAR':
      return {
        ...state,
        snackbarOpen: false
      };
    default:
      return state;
  }
};

function initWhiteList(hospital) {
  const whiteList = ['personalData'];
  const available = [
    ['purchasesPanel', 'multimedia', 'payment'],
    'fillSurvey',
    'documents',
    'faq',
    'preadmission',
    'concierge'
  ];

  hospital.modules.forEach(({activated}, index) => {
    if (activated) {
      if (typeof available[index] === 'string') {
        whiteList.push(available[index]);
      } else {
        available[index].forEach(item => {
          whiteList.push(item);
        });
      }
    }
  });
  return whiteList;
}

export default combineReducers({
  default: defaultReducer,
  user: userReducer,
  ui: uiReducer,
  payment: paymentReducer,
  surveys: surveysReducer,
  documents: documentReducer,
  preadmission: preadmissionReducer
});
