import {
  FETCH_SURVEYS_DONE,
  FETCH_SURVEY_RESPONSES_DONE,
  SET_SELECTED_SURVEY,
  SET_RESPONSES_SELECTED_SURVEY,
  POST_SURVEY_RESPONSES,
  POST_SURVEY_RESPONSES_DONE,
  POST_SURVEY_RESPONSES_FAILED
} from '../actions/types';

const initialState = {
  questions: [],
  currentIndex: 0,
  currentQuestion: {},
  surveys: [],
  selectedSurvey: {},
  surveyResponsesSent: false,
  errorSendSurveyResponses: false
};

export default function (state = initialState, action) { // eslint-disable-line default-param-last
  let current;
  switch (action.type) {
    case POST_SURVEY_RESPONSES:
      return Object.assign({}, state, {
        errorSendSurveyResponses: false,
        surveyResponsesSent: false
      });
    case POST_SURVEY_RESPONSES_DONE:
      return Object.assign({}, state, {
        errorSendSurveyResponses: false,
        surveyResponsesSent: true
      });
    case POST_SURVEY_RESPONSES_FAILED:
      return Object.assign({}, state, {
        errorSendSurveyResponses: true
      });
    case SET_RESPONSES_SELECTED_SURVEY:
      return Object.assign({}, state, {
        selectedSurvey: Object.assign({}, state.selectedSurvey, {
          responses: action.payload
        })
      });
    case SET_SELECTED_SURVEY:
      return Object.assign({}, state, {
        selectedSurvey: action.payload.responses ? action.payload : Object.assign(action.payload, {responses: []})
      });
    case FETCH_SURVEYS_DONE:
      return Object.assign({}, state, {
        errorSendSurveyResponses: false,
        surveys: action.payload
      });
    case FETCH_SURVEY_RESPONSES_DONE:
      const {responses, endDate} = action.payload;
      const {surveyId} = action.payload;

      if (responses && responses.length > 0) {
        const indexSurvey = state.surveys.findIndex(survey => survey._id === surveyId);
        const updatedSurveys = [...state.surveys];
        updatedSurveys[indexSurvey] = Object.assign(
          {},
          state.surveys[indexSurvey],
          {
            responses,
            responseDate: endDate
          }
        );
        return Object.assign({}, state, {
          surveys: updatedSurveys
        });
      }

      return Object.assign({}, state);
    case 'LOAD_QUESTIONS':
      return Object.assign({}, state, {
        questions: action.payload,
        currentIndex: 0,
        currentQuestion: action.payload[0]
      });
    case 'NEXT':
      current = state.currentIndex;
      const max = state.questions.length;

      if (current >= max - 1) {
        return;
      }

      current += 1;

      return Object.assign({}, state, {
        currentIndex: current,
        currentQuestion: state.questions[current]
      });
    case 'PREV':
      current = state.currentIndex;

      if (current <= 0) {
        return;
      }

      current -= 1;

      return Object.assign({}, state, {
        currentIndex: current,
        currentQuestion: state.questions[current]
      });

    default:
      return state;
  }
}
