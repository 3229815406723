import axios from 'axios';
import {get} from 'lodash';
import {setKnownUser, setUser} from './user';

export function login(body) {
  return function (dispatch) {
    return axios
      .post('/api/users/login', body, {
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => {
        if (response.status === 403) {
          return {
            result: 'ERROR',
            msg: 'wrongMailPassword'
          };
        }

        if (response.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const {data} = response;
        dispatch(setUser(data));
        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(error => {
        if (get(error, 'response.status') === 403) {
          if (get(error, 'response.data.message') === 'Account not activated') {
            return {
              result: 'NOTACTIVATED',
              title: 'accountNotActivated',
              msg: 'accountNotActivatedDesc'
            };
          }

          return {
            result: 'ERROR',
            msg: 'wrongMailPassword'
          };
        }

        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export function resendActivationMail(email, hospID, errMessage, successMessage) {
  return function (dispatch) {
    return axios
      .post(`/api/users/reset/mail/${hospID}`, {email: email.toLowerCase()}, {
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => {
        if (response.status === 404) {
          return {
            result: 'ERROR',
            title: 'error',
            msg: 'wrongCode'
          };
        }

        if (response.status !== 200) {
          dispatch({type: 'SNACKBAR_ERROR', message: errMessage});
        }

        dispatch({type: 'SNACKBAR_SUCCESS', message: successMessage});
      })
      .catch(error => {
        if (error && error.response.status !== 200) {
          dispatch({type: 'SNACKBAR_ERROR', message: errMessage});
        }
      });
  };
}

export const checkEmail = (hospID, mail) => {
  return function () {
    return axios({
      method: 'get',
      url: '/api/users/check',
      params: {
        mail,
        hospID
      }
    })
      .then(response => {
        if (response.status === 409) {
          return {
            result: 'ERROR',
            title: 'error',
            msg: 'mailAlreadyUse'
          };
        }

        if (response.status !== 200) {
          return {
            result: 'ERROR',
            title: 'error',
            msg: 'serverError'
          };
        }

        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(error => {
        if (get(error, 'response.status') === 409) {
          return {
            result: 'ERROR',
            title: 'error',

            msg: 'mailAlreadyUse'
          };
        }

        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
};

export function signup(body) {
  return function () {
    return axios
      .post('/api/users', body, {
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => {
        if (response.status === 409) {
          return {
            result: 'ERROR',
            title: 'error',
            msg: 'mailAlreadyUse'
          };
        }

        if (response.status !== 200) {
          return {
            result: 'ERROR',
            title: 'error',
            msg: 'serverError'
          };
        }

        return {
          result: 'SUCCESS',
          msgAdd: 'confimMail',
          confirmText: 'welcomReturn',
          userId: response.data._id,
          token: response.data.token || '',
          status: 200
        };
      })
      .catch(error => {
        if (get(error, 'response.status') === 409) {
          return {
            result: 'ERROR',
            title: 'error',

            msg: 'mailAlreadyUse'
          };
        }

        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export const checkCode = (hospID, name, code) => {
  return function () {
    return axios({
      method: 'get',
      url: `/api/users/check/${hospID}/code`,
      params: {
        name,
        code
      }
    })
      .then(response => {
        if (response.status !== 200) {
          return {
            result: 'ERROR',
            title: 'error',
            msg: 'serverError'
          };
        }

        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(error => {
        if (get(error, 'response.status') === 409) {
          return {
            result: 'ERROR',
            title: 'error',

            msg: 'codeAlreadyUsed'
          };
        }

        if (get(error, 'response.status') === 404) {
          return {
            result: 'ERROR',
            title: 'error',

            msg: 'wrongActivationCode'
          };
        }

        if (get(error, 'response.status') === 403) {
          return {
            result: 'ERROR',
            title: 'error',

            msg: 'codeNoMatch'
          };
        }

        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
};

export const fetchGTU = async id => {
  return new Promise(resolve => {
    axios
      .get(`/api/hospitals/${id}/gtu/data`)
      .then(response => {
        if (response.status !== 200) {
          resolve(false);
        } else {
          resolve({
            result: 'SUCCESS',
            data: response.data
          });
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const fetchGDPR = async id => {
  return new Promise(resolve => {
    axios
      .get(`/api/hospitals/${id}/gdpr/data`)
      .then(response => {
        if (response.status !== 200) {
          resolve(false);
        } else {
          resolve({
            result: 'SUCCESS',
            data: response.data
          });
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const fetchActivationCodeMsg = async hospID => {
  return new Promise(resolve => {
    axios
      .get(`/api/hospitals/${hospID}/customized-msg/activation-code`)
      .then(response => {
        if (response.status !== 200) {
          resolve(false);
        } else {
          resolve({
            result: 'SUCCESS',
            data: response.data
          });
        }
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export function forgotPassword(body) {
  return function () {
    return axios
      .post('/api/users/changepassword', body, {
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => {
        if (response.status === 403) {
          return {
            result: 'ERROR',
            title: 'error',
            msg: 'wrongMail'
          };
        }

        if (response.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        return {
          result: 'SUCCESS',
          msg: 'forgottenConfirmation',
          status: 200
        };
      })
      .catch(error => {
        if (get(error, 'response.status') === 403) {
          if (get(error, 'response.data.message') === 'Account not activated') {
            return {
              result: 'NOTACTIVATED',
              title: 'accountNotActivated',
              msg: 'accountNotActivatedDesc'
            };
          }

          return {
            result: 'ERROR',
            title: 'error',

            msg: 'wrongMail'
          };
        }

        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export function resetPassword(body) {
  return function (dispatch) {
    return axios
      .get(`/api/users/token/${body.passwordToken}`)
      .then(response => {
        if (response.status === 404) {
          return {
            result: 'ERROR',
            title: 'error',
            msg: 'wrongCode'
          };
        }

        if (response.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const {data} = response;
        dispatch(setEmptyUser(data));
        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(error => {
        if (get(error, 'response.status') === 404) {
          return {
            result: 'ERROR',
            title: 'error',

            msg: 'wrongCode'
          };
        }

        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

function setEmptyUser({token, _id}) {
  return {
    type: 'SET_EMPTY_USER',
    payload: {
      token,
      user: {_id}
    }
  };
}

export function newPassword(user) {
  return function () {
    return axios
      .put(
        `/api/users/reset/${user._id}`,
        {password: user.password},
        {
          headers: {
            authorization: user.token ? user.token : '',
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        if (response.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(() => {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export function accountValidation(body) {
  return axios
    .post('/api/users/cloud/validation', body, {
      headers: {'Content-Type': 'application/json'}
    })
    .then(response => {
      if (response.status !== 200) {
        return {
          result: 'ERROR',
          title: 'error',
          msg: 'serverError'
        };
      }

      return {
        result: 'SUCCESS',
        userId: response.data._id,
        token: response.data.token || '',
        status: 200
      };
    })
    .catch(error => {
      if (error) {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      }
    });
}

export function updatePassword(user) {
  return function (dispatch) {
    return axios
      .put(`/api/users/modify/${user._id}`, user, {
        headers: {
          authorization: user.token ? user.token : '',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const user = response.data;
        dispatch(setKnownUser(user));
        return {
          result: 'SUCCESS',
          status: 200
        };
      })
      .catch(error => {
        if (get(error, 'response.status') === 403) {
          return {
            result: 'ERROR',
            msg: 'oldPasswordIncorrect'
          };
        }

        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}
