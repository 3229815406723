import React, {useEffect, useState, useRef} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useIntl} from 'react-intl';

import WhiteIconButton from '../../shared/white-icon-button';
import BlueIconButton from '../../shared/blue-icon-button';
import styles from './action-bar.module.scss';

const Actions = ({
  setCurrent,
  current,
  save,
  finish,
  preadmission,
  disabled,
  progress,
  files,
  fields,
  lastPage,
  matchSize
}) => {
  const matches = useMediaQuery(`(min-width:${matchSize}px)`);
  const {formatMessage: f} = useIntl();
  const [max, setMax] = useState(0);
  const [currentFinish, setCurrentFinish] = useState(false);
  const [sectionFilled, setSectionFilled] = useState(false);
  const didMountRef = useRef(false);
  const progressRef = useRef(progress);

  useEffect(() => {
    if (preadmission && preadmission.sections) {
      setMax(preadmission.sections.length);
    }
  }, [preadmission]);

  useEffect(() => {
    const expected = current.content.length;
    const filled = Object.keys(fields).length + Object.keys(files).length;
    if (filled === expected) {
      setSectionFilled(true);
    } else {
      setSectionFilled(false);
    }
  }, [files, fields]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (didMountRef.current) {
      if (progress >= 100) {
        setCurrentFinish(true);
      }

      progressRef.current = progress;
    } else {
      didMountRef.current = true;
    }
  }, [progress]);

  return (
    <div className={`${!matches ? styles.Fixed : styles.Actions}`}>
      {max > 1 &&
      <>

        {(currentFinish && !disabled) || (progress === 100 && !disabled) || (sectionFilled && lastPage && !disabled) ? (
          <BlueIconButton
            icon="done_all"
            matchSize="600"
            text={f({id: 'finish'})}
            onClick={() => {
              finish();
            }}
          />
        ) : (
          <WhiteIconButton
            right
            icon="chevron_right"
            matchSize="600"
            text={f({id: 'next'})}
            onClick={() => {
              if (!disabled) {
                save();
              }

              if (current.index === max - 1) {
                setCurrent({...preadmission.sections[0], index: 0});
              } else {
                setCurrent({
                  ...preadmission.sections[current.index + 1],
                  index: current.index + 1
                });
              }
            }}
          />
        )}
        {progress === 100 && !disabled && <span style={{color: '#2ca061'}}>{f({id: 'finishedPreadmission'})}</span>}
        <WhiteIconButton
          icon="chevron_left"
          matchSize="600"
          text={f({id: 'previous'})}
          onClick={() => {
            if (current.index === 0) {
              setCurrent({...preadmission.sections[max - 1], index: max - 1});
            } else {
              setCurrent({
                ...preadmission.sections[current.index - 1],
                index: current.index - 1
              });
            }

            if (!disabled) {
              save();
            }
          }}
        />
      </>}
      {max <= 1 && !disabled &&
      <BlueIconButton
        icon="done_all"
        matchSize="600"
        disabled={progress < 100 && !sectionFilled}
        text={f({id: 'finish'})}
        onClick={() => {
          finish();
        }}
      />}
    </div>
  );
};

export default Actions;
