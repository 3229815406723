import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import Card from './menu-card';

import styles from './home-page.module.scss';

export default function Page({pages, hospID, user, onUpdate}) {
  const {formatMessage: f} = useIntl();

  const formatFirstName = name => {
    const formatted = formatCommon(name);
    const splitStr = formatted.split('-');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join('-');
  };

  const formatCommon = name => {
    const splitStr = name.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(' ');
  };

  return (
    <div className={styles.HomePage}>
      <div className={styles.HomeHeader}>

        {hospID && (
          <img
            id="logo"
            src={`/api/hospitals/${hospID}/application/logo`}
            alt="Logo"
            className={styles.HomeImg}
          />
        )}
        <span className={styles.HomeTitle}>{f({id: 'welcome'})} {formatFirstName(user.firstname)} {formatCommon(user.lastname)}.</span>
      </div>
      {pages.map(item => (
        <Card
          key={item.identity}
          className={styles.HomeCard}
          icon={item.icon}
          title={f({id: item.title})}
          onTap={item.onClick ? item.onClick : () => onUpdate(`${item.identity}`)}
        />
      ))}

    </div>
  );
}

Page.propTypes = {
  pages: PropTypes.array.isRequired,
  hospID: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired
};
