import axios from 'axios';
import {get} from 'lodash';
import {formatPayment} from './payment';

import {
  CLEAN_STATE,
  SET_HOSPITAL,
  SET_HOSPITALS,
  SET_APPLICATION_NAME,
  SET_TVCOAX_PROCESS,
  FETCH_HOSPITALS,
  FETCH_HOSPITALS_DONE,
  GET_HOSPITAL,
  SET_LOCALE,
  GET_HOSPITAL_DONE,
  GET_HOSPITALS_BY_GROUP,
  GET_HOSPITALS_BY_GROUP_DONE,
  LOGOUT,
  LOGOUT_BY_TIMEOUT,
  LOGGED,
  FETCH_HOSPID
} from './types';

export function fetchAppName({hospID, token}) {
  return axios
    .get(`/api/hospitals/${hospID}/application/name`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: token ? token : ''
      }
    })
    .then(res => {
      if (res.status !== 200) {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      }

      const appName = res.data;
      return appName;
    });
}

export function fetchTvCoaxProcess({hospID, token}) {
  return axios
    .get(`/api/hospitals/${hospID}/tvcoax`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: token ? token : ''
      }
    })
    .then(res => {
      if (res.status !== 200) {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      }

      return res.data;
    })
    .catch(error_ => {
      if (get(error_, 'response.status') === 404) {
        return {
          result: 'ERROR',
          msg: 'wrongLink'
        };
      }

      return {
        result: 'ERROR',
        msg: 'serverError'
      };
    });
}

export function clean() {
  return function (dispatch) {
    dispatch(cleanState());
  };
}

export const success = message => {
  return dispatch => {
    dispatch({type: 'SNACKBAR_SUCCESS', message});
  };
};

export const error = message => {
  return dispatch => {
    dispatch({type: 'SNACKBAR_ERROR', message});
  };
};

export const info = message => {
  return dispatch => {
    dispatch({type: 'SNACKBAR_INFO', message});
  };
};

export const warning = message => {
  return dispatch => {
    dispatch({type: 'SNACKBAR_WARNING', message});
  };
};

export const clearSnackbar = () => {
  return dispatch => {
    dispatch({type: 'SNACKBAR_CLEAR'});
  };
};

export function initStoreFromStorage() {
  return async function (dispatch) {
    if (localStorage.getItem('hospital')) {
      const hospital = JSON.parse(localStorage.getItem('hospital'));
      dispatch(setHospital(hospital));
      const name = await fetchAppName(hospital);
      dispatch(setAppName(name));
    }

    if (localStorage.getItem('user')) {
      const userId = localStorage.getItem('user');
      let token;
      if (localStorage.getItem('token')) {
        token = localStorage.getItem('token');
      }

      try {
        // Test if logged user is still present in database in first place
        const {data} = await axios.get(`/api/users/cloud/${userId}`, {
          headers: {
            'Content-Type': 'application/json',
            authorization: token ? token : ''
          }
        });
        if (data) {
          data.token = token;
          dispatch(setUser(data));
          if (data.location && data.service) {
            dispatch(setUserLocation({location: data.location, service: data.service}));
          }
        } else {
          localStorage.removeItem('user');
          localStorage.removeItem('token');
        }
      } catch (error_) {
        console.trace('User not found', error_);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
      }
    }
  };
}

export function validateUser(userId) {
  return function (dispatch) {
    let user;
    return axios.post(` /api/users/activate/${userId}`).then(res => {
      if (res.status !== 200) {
        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      }

      user = res.data;
      const {token} = res.data;
      if (user.customerID) {
        user = {...user, isFilled: true};
      }

      dispatch(setUser({...user, token}));
      return axios.get(`/api/hospitals/${user.hospID}`);
    })
      .then(res => {
        if (res.status !== 200) {
          return {
            result: 'ERROR',
            msg: 'serverError'
          };
        }

        const hospital = res.data;
        localStorage.setItem('hospital', JSON.stringify(hospital));

        dispatch(setHospital(hospital));
        return fetchAppName(hospital);
      })
      .then(name => {
        dispatch(setAppName(name));
        return {
          result: 'SUCCESS',
          status: 200,
          isAssociated: user.customerID,
          adminCreated: user.adminCreated,
          user
        };
      })
      .catch(error_ => {
        if (get(error_, 'response.status') === 404) {
          return {
            result: 'ERROR',
            msg: 'wrongLink'
          };
        }

        if (get(error_, 'response.status') === 401) {
          return {
            status: 401,
            result: 'ERROR',
            msg: 'wrongLink'
          };
        }

        if (get(error_, 'response.status') === 403) {
          return {
            status: 403,
            result: 'ERROR',
            msg: 'wrongLink',
            data: error_.response.data
          };
        }

        return {
          result: 'ERROR',
          msg: 'serverError'
        };
      });
  };
}

export function fetchHospID({name, logout}) {
  return dispatch => {
    dispatch({type: FETCH_HOSPID, payload: {name, logout}});
  };
}

export function fetchHospitals() {
  return dispatch => {
    dispatch({type: FETCH_HOSPITALS});
  };
}

export function fetchHospitalsDone(hospitals) {
  return dispatch => {
    dispatch({
      type: FETCH_HOSPITALS_DONE,
      payload: hospitals
    });
  };
}

export function getHospitalById(hospID) {
  return dispatch => {
    dispatch({
      type: GET_HOSPITAL,
      payload: hospID
    });
  };
}

export function getHospitalDone(hospital) {
  return dispatch => {
    dispatch({
      type: GET_HOSPITAL_DONE,
      payload: hospital
    });
  };
}

export function getHospitalsByGroup(groupID) {
  return dispatch => {
    dispatch({
      type: GET_HOSPITALS_BY_GROUP,
      payload: groupID
    });
  };
}

export function updateHospital(hospital) {
  return async function (dispatch) {
    const name = await fetchAppName(hospital);
    dispatch(setAppName(name));
    const processConf = await fetchTvCoaxProcess(hospital);
    dispatch(setTvCoaxProcess(processConf));
    dispatch(setHospital(hospital));
  };
}

export function getHospitalsByGroupDone(hospitals) {
  return dispatch => {
    dispatch({
      type: GET_HOSPITALS_BY_GROUP_DONE,
      payload: hospitals
    });
  };
}

export function setHospitals(data) {
  return {
    type: SET_HOSPITALS,
    payload: {hospitals: data}
  };
}

function cleanState() {
  return {
    type: CLEAN_STATE,
    payload: {}
  };
}

function setHospital(data) {
  return {
    type: SET_HOSPITAL,
    payload: {hospital: data}
  };
}

export function setLocale(locale) {
  return {
    type: SET_LOCALE,
    payload: locale
  };
}

export function setAppName(name) {
  return {
    type: SET_APPLICATION_NAME,
    payload: name
  };
}

export function setTvCoaxProcess(conf) {
  return {
    type: SET_TVCOAX_PROCESS,
    payload: conf
  };
}

export function logout() {
  return function (dispatch) {
    dispatch({type: LOGOUT});
  };
}

export function logoutByTimeout() {
  return function (dispatch) {
    dispatch({type: LOGOUT_BY_TIMEOUT});
  };
}

export function userIsLogged() {
  return function (dispatch) {
    dispatch({type: LOGGED});
  };
}

export function callCustomAction(action, redirect) {
  return function (dispatch) {
    dispatch({type: action.type, payload: {value: action.value, next: redirect}});
  };
}

function setUser(data) {
  const payload = formatUser(data);

  return {
    type: 'SET_USER',
    payload
  };
}

function setUserLocation(data) {
  return {
    type: 'SET_USER_LOCATION',
    payload: data
  };
}

export function formatUser(data) {
  let isFilled = false;
  if (
    data.firstname &&
    data.lastname &&
    data.birthdate &&
    data.address &&
    data.address.street &&
    data.address.postal &&
    data.address.city
  ) {
    isFilled = true;
  }

  const user = {
    user: {
      ...data,
      paymentExp: data.paymentval ? formatPayment(data.paymentval) : null,
      isFilled
    }
  };
  if (data.token) {
    user.token = data.token;
  }

  return user;
}

export const sendMail = async userID => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/users/reset/mailfromuser/${userID}`)
      .then(response => {
        if (response.status !== 200) {
          reject();
        }

        resolve(true);
      })
      .catch(() => {
        reject();
      });
  });
};
