import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';

const LoadingSearchHospital = () => {
  const {formatMessage: f} = useIntl();
  const hospIDNotFound = useSelector(state => state.hospIDNotFound);

  return (
    <div style={styles.container}>
      {
        hospIDNotFound ?
          (
            <>
              <p>{f({id: 'loadingFindHospital'})}</p>
              <CircularProgress/>
            </>
          ) : (
            <p>{f({id: 'hospitalNotFound'})}</p>
          )
      }
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
};

export default LoadingSearchHospital;
