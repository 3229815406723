import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Link as ButtonLink} from 'react-router-dom';
import {get, find, reduce} from 'lodash';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Warning from '@material-ui/icons/Error';
import PhoneInput from 'react-phone-input-2';

import {updateUser, validateUserSubscription, checkUserValidity} from '../../../actions/user';
import {error, success, warning} from '../../../actions';

import styles from './personal-data-form.module.scss';

import 'react-phone-input-2/lib/bootstrap.css';
import './phone-input-theme.scss';

import FormInput from '../../shared/FormInput/form-input';
import BirthdatePicker from '../../shared/BirthdatePicker/birthdate-picker';
import LocationForm from '../../shared/LocationForm/location-form';

import TvCodePage from '../TvCodePage/tv-code-page';

class PersonnalDataForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hospital: this.props.hospital ||
        null,

      loading: false,
      user: this.props.user || {},
      token: this.props.token || null,
      unchanged: true,
      civility: '',
      firstname: '',
      lastname: '',
      birthdate: '',
      personnalTph: '',
      address: '',
      postCode: '',
      city: '',
      mail: '',
      emptyFields: [],
      badFormat: false,
      errorEmail: null,
      errorPostal: null,
      errorBirthdate: null,
      alert: {
        open: false,
        title: '',
        text: ''
      },
      focus: false,
      width: window.innerWidth,
      isLocationPageOpen: false,
      userInfoToUpdate: {},
      isTvCodePageOpen: false,
      userNotValid: false,
      errorLastname: false,
      errorFirstname: false
    };
    this.setCivility = value => {
      this.setState({
        civility: value,
        unchanged: false
      });
    };

    this.setBirthDate = value => {
      this.setState({
        birthdate: value,
        unchanged: false,
        userNotValid: false
      });
    };

    this.setFirstName = value => {
      this.setState({
        firstname: value,
        errorFirstname: false,
        unchanged: false,
        userNotValid: false
      });
    };

    this.setName = value => {
      this.setState({
        lastname: value,
        unchanged: false,
        errorLastname: false,
        userNotValid: false
      });
    };

    this.setPhone = value => {
      this.setState({
        personnalTph: value,
        unchanged: false,
        badFormat: false
      });
    };

    this.setAddress = value => {
      this.setState({
        address: value,
        unchanged: false
      });
    };

    this.setPostCode = value => {
      this.setState({
        postCode: value,
        unchanged: false
      });
    };

    this.setCity = value => {
      this.setState({
        city: value,
        unchanged: false
      });
    };

    this.setErrorEmail = value => {
      this.setState({
        errorEmail: value
      });
    };

    this.setMail = value => {
      this.setState({
        mail: value,
        unchanged: false
      });
    };

    this.handleAlertClose = () => {
      this.setState({
        alert: {
          open: false,
          title: '',
          text: ''
        }
      });
    };

    this.handleFocus = evt => {
      this.setState({focus: Boolean(evt)});
    };

    this.isEmpty = field => {
      return Boolean(find(this.state.emptyFields, item => {
        return item === field;
      }));
    };

    this.isInvalidEmail = email => {
      const regex = /^[a-z\d._%+-]+@[a-z\d.-]+\.[a-z]{2,10}$/;
      return !regex.test(email);
    };

    this.isInvalidPost = post => {
      const isInvalid = Number.isNaN(post) || post.length !== 5;
      return isInvalid;
    };

    this.hasEmptyRequiredFields = () => {
      const fieldsToCheck = [
        'civility',
        'firstname',
        'lastname',
        'address',
        'postCode',
        'city',
        'mail',
        'birthdate'
      ];

      const emptyFields = reduce(
        fieldsToCheck,
        (acc, field) => {
          if (!get(this.state, field, '').trim().length) {
            acc.push(field);
          }

          return acc;
        },
        []
      );
      this.setState({emptyFields});
      return emptyFields.length;
    };

    this.saveInfo = async () => {
      if (this.hasEmptyRequiredFields() > 1) {
        this.props.error(this.props.intl.formatMessage({id: 'missingFields'}));
        return;
      }

      if (this.hasEmptyRequiredFields() === 1) {
        return;
      }

      if (this.isInvalidEmail(this.state.mail)) {
        this.setErrorEmail('invalidEmail');
        return;
      }

      if (this.isInvalidPost(this.state.postCode)) {
        this.setState({
          errorPostal: 'invalidPostal'
        });
        return;
      }

      if (this.state.birthdate === 'NaNNaNNaN') { // The datepicker return 'NaNNaNNaN' when the birthdate is invalid
        this.setState({
          errorBirthdate: 'invalidBirthdate'
        });
        return;
      }

      const alphanumericTextRegex = new RegExp(/[a-z]/i);
      if (!alphanumericTextRegex.test(this.state.firstname)) {
        this.setState({errorFirstname: 'invalidFirstname'});
        return;
      }

      if (!alphanumericTextRegex.test(this.state.lastname)) {
        this.setState({errorLastname: 'invalidLastname'});
        return;
      }

      let {user} = this.state;
      const token = this.state.token ? this.state.token : '';
      const body = {
        token,
        isFirstConnection: this.props.isFirstConnection,
        hospID: get(this.state, 'hospital.hospID'),
        civility: this.state.civility,
        firstname: this.state.firstname.trim(),
        lastname: this.state.lastname.trim(),
        birthdate: this.state.birthdate,
        mail: this.state.mail,
        personnalTph: this.state.personnalTph,
        address: {
          street: this.state.address.trim(),
          postal: this.state.postCode,
          city: this.state.city.trim()
        }
      };

      user = {...user, ...body};

      // Check the user validity only when it is the first subscription
      if (this.props.isFirstConnection) {
        const {result, msg} = await this.props.checkUserValidity(user, this.props.hospital);
        if (result === 'ERROR') {
          if (msg === 'userNotExistsInPMS') {
            this.setState({userNotValid: true});
          }

          this.props.error(this.props.intl.formatMessage({id: msg}));
          return;
        }
      }

      if (this.props.tvCoaxProcess?.location?.isEnabled && !get(this.state, 'user.isFilled')) {
        this.setState({userInfoToUpdate: user}, () => {
          this.setState({isLocationPageOpen: true});
        });
      } else if (this.props.tvCoaxProcess?.code?.isEnabled && !get(this.state, 'user.isFilled') && Boolean(this.state.hospital?.preferences?.tvCodes && Object.keys(this.state.hospital?.preferences?.tvCodes).length > 0)) {
        this.setState({userInfoToUpdate: user}, () => {
          this.setState({isTvCodePageOpen: true});
        });
      } else {
        this.sendUpdateUserRequest(user);
      }
    };

    this.sendUpdateUserRequest = async (user, location) => {
      let response;
      const data = {...user, service: this.props.appName, location: location || user.location};
      if (this.props.isFirstConnection) {
        response = await this.props.validateUserSubscription(data, this.props.hospital);
      } else {
        response = await this.props.updateUser(data, this.props.hospital);
      }

      if (response.result === 'ERROR') {
        if (response.msg === 'conflictEmail') {
          this.setErrorEmail('emailAlreadyUsed');
        } else {
          this.props.error(this.props.intl.formatMessage({id: response.msg}));
        }

        if (this.state.isLocationPageOpen) {
          this.setState({isLocationPageOpen: false});
          return 'KO';
        }
      } else if (response.status === 200) {
        this.props.success(this.props.intl.formatMessage({id: 'dataUpdateConfirm'}));
        this.props.onUpdate('personalHome');
        return 'OK';
      }
    };

    this.onUpdateLocation = (location, service) => {
      this.setState(prev => ({...prev, user: {...prev.user, location, service}}));
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({width: window.innerWidth}));
    if (this.state.user && this.state.token) {
      this.setState({
        civility: get(this.state, 'user.civility', ''),
        firstname: get(this.state, 'user.firstname', ''),
        lastname: get(this.state, 'user.lastname', ''),
        birthdate: get(this.state, 'user.birthdate', ''),
        personnalTph: get(this.state, 'user.personnalTph', ''),
        address: get(this.state, 'user.address.street'),
        postCode: get(this.state, 'user.address.postal'),
        city: get(this.state, 'user.address.city'),
        mail: get(this.state, 'user.mail')
      });
    }
  }

  componentDidUpdate(props) {
    if (props.hospital && props.hospital.hospID !== get(this.state, 'hospital.hospID')) {
      this.setState({hospital: props.hospital});
    }

    if (props.user && props.user.customerID !== get(this.state, 'user.customerID')) {
      this.setState({
        user: props.user,
        civility: props.user.civility || '',
        firstname: props.user.firstname || '',
        lastname: props.user.lastname || '',
        birthdate: get(this.state, 'user.birthdate') || '',
        personnalTph: get(this.state, 'user.personnalTph') || '',
        address: get(props, 'user.address.street'),
        postCode: get(props, 'user.address.postal'),
        city: get(props, 'user.address.city'),
        mail: get(props, 'user.mail')
      });
    }

    if (props.token && props.token !== this.state.token) {
      this.setState({token: props.token});
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;
    if (nextProps.hospital) {
      newProps = newProps ? {...newProps, hospital: nextProps.hospital} : {hospital: nextProps.hospital};
    }

    if (nextProps.user) {
      newProps = newProps ? {...newProps, user: nextProps.user} : {user: nextProps.user};
    }

    if (nextProps.token) {
      newProps = newProps ? {...newProps, token: nextProps.token} : {token: nextProps.token};
    }

    return newProps;
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    const isMobileSize = width => {
      return width <= 600;
    };

    const handleClose = () => {
      this.handleAlertClose();
    };

    const handleDateChange = date => {
      this.setBirthDate(date);
    };

    const handleChange = event => {
      this.setCivility(event.target.value);
    };

    const handleNameChange = event => {
      this.setName(event.target.value);
    };

    const handleFirstNameChange = event => {
      this.setFirstName(event.target.value);
    };

    const handleAddressChange = event => {
      this.setAddress(event.target.value);
    };

    const handlePostCodeChange = event => {
      this.setPostCode(event.target.value);
    };

    const handleCityChange = event => {
      this.setCity(event.target.value);
    };

    const handleMailChange = event => {
      this.setMail(event.target.value);
    };

    const handleOpenLocationPage = value => {
      this.setState({isLocationPageOpen: value});
    };

    const civilities = [
      {
        value: 'Mr.',
        label: f({id: 'mr.'})
      },
      {
        value: 'Mrs.',
        label: f({id: 'mrs.'})
      }
    ];
    const buttonText = get(this.state, 'user.isFilled') ? 'edit' : 'validate';

    const renderLocationForm = () => (
      <LocationForm
        {...this.props}
        handleOpenLocationPage={handleOpenLocationPage}
        userInfoToUpdate={this.state.userInfoToUpdate}
        handleSendUpdateUserRequest={this.sendUpdateUserRequest}
        onUpdateLocation={this.onUpdateLocation}
      />
    );

    const renderTVCodePage = () => (
      <TvCodePage
        token={this.props.token}
        hospital={this.props.hospital}
        user={this.state.userInfoToUpdate}
        handleSendUpdateUserRequest={this.sendUpdateUserRequest}
        onUpdate={this.props.onUpdate}
        onBackButton={() => this.setState({isTvCodePageOpen: false})}/>
    );

    const renderPersonalInfosForm = () => (
      <div className="userPage">
        <div className="pageActions">
          {get(this.state, 'user.isFilled') && (
            <>
              <Breadcrumbs aria-label="breadcrumb">
                <ButtonLink data-testid="pdf-back" color="inherit" to="/personalData">
                  {f({id: 'myAccount'})}
                </ButtonLink>
                <ButtonLink data-testid="pdf-return" color="inherit" to="/personalData/myInformations">
                  {f({id: 'myInformations'})}
                </ButtonLink>
                <ButtonLink
                  data-testid="pdf-current"
                  color="textPrimary"
                  to="/personalData/edit"
                  aria-current="page"
                >
                  {f({id: 'edition'})}
                </ButtonLink>
              </Breadcrumbs>
              <ButtonLink data-testid="pdf-smallscreen-back" className="smallScreenBack" to="/personalData/myInformations">
                <ArrowBack/>
              </ButtonLink>
            </>

          )}
        </div>
        <div className={`${isMobileSize(this.state.width) ? styles.MobilePageContent : 'pageContent'}`}>
          <div style={{marginTop: isMobileSize(this.state.width) ? 20 : 70}} className={`${isMobileSize(this.state.width) ? styles.MobileHeader : 'header'}`}>

            {!get(this.state, 'user.isFilled') && <h3>{f({id: 'personalData'})}</h3>}
            {this.state.hospital?.config?.subscriptionMessage && <p style={{maxWidth: '50%', marginLeft: 'auto', marginRight: 'auto'}}>{this.state.hospital.config.subscriptionMessage}</p>}

            <p data-testid="pdf-text1">{f({id: 'informationConfidentiality'})}</p>
            <p data-testid="pdf-text2">{f({id: 'cnilPolicy'})}</p>
          </div>

          <div className={`${isMobileSize(this.state.width) ? styles.MobilePersonalDataForm : 'personnalDataForm'}`}>
            <form noValidate className="root" autoComplete="off">
              <div className={`${isMobileSize(this.state.width) ? styles.MobileContent : 'content'}`}>
                <div className={`${isMobileSize(this.state.width) ? styles.MobileFormData : 'form-data left'}`}>
                  <div className={styles.FormContent}>
                    <FormInput
                      select
                      required
                      data-testid="personal-data-form-civility"
                      label={f({id: 'civility'})}
                      id="civility"
                      error={this.isEmpty('civility')}
                      value={this.state.civility}
                      onChange={handleChange}
                    >
                      {civilities.map(civ => (
                        <MenuItem key={civ.value} value={civ.value}>
                          {civ.label}
                        </MenuItem>
                      ))}
                    </FormInput>
                    {this.isEmpty('civility') && <span className={styles.ErrorMsg}>{f({id: 'emptyCivility'})}</span>}
                  </div>

                  <div className={styles.FormContent}>
                    <FormInput
                      required
                      data-testid="personal-data-form-firstname"
                      className={styles.Input}
                      placeholder={f({id: 'firstname'})}
                      label={f({id: 'firstname'})}
                      type="text"
                      id="firstname"
                      error={this.state.errorFirstname || this.state.userNotValid || this.isEmpty('firstname')}
                      value={this.state.firstname || ''}
                      InputProps={{
                        endAdornment: (
                          <>
                            {(this.state.errorFirstname || this.state.userNotValid || this.isEmpty('firstname')) && (
                              <InputAdornment position="end">
                                <Warning className="red"/>
                              </InputAdornment>
                            )}
                          </>
                        )
                      }}
                      onChange={handleFirstNameChange}
                    />
                    {this.isEmpty('firstname') && <span className={styles.ErrorMsg}>{f({id: 'emptyFirstname'})}</span>}
                    {this.state.errorFirstname && <span className={styles.ErrorMsg}>{f({id: this.state.errorFirstname})}</span>}
                  </div>

                  <div className={styles.FormContent}>
                    <FormInput
                      required
                      data-testid="personal-data-form-lastname"
                      className={styles.Input}
                      placeholder={f({id: 'lastname'})}
                      label={f({id: 'lastname'})}
                      error={this.state.errorLastname || this.state.userNotValid || this.isEmpty('lastname')}
                      type="text"
                      id="lastname"
                      value={this.state.lastname || ''}
                      InputProps={{
                        endAdornment: (
                          <>
                            {(this.state.errorLastname || this.state.userNotValid || this.isEmpty('lastname')) && (
                              <InputAdornment position="end">
                                <Warning className="red"/>
                              </InputAdornment>
                            )}
                          </>
                        )
                      }}
                      onChange={handleNameChange}
                    />
                    {this.isEmpty('lastname') && <span className={styles.ErrorMsg}>{f({id: 'emptyLastname'})}</span>}
                    {this.state.errorLastname && <span className={styles.ErrorMsg}>{f({id: this.state.errorLastname})}</span>}
                  </div>

                  <div className={styles.FormContent}>
                    <BirthdatePicker
                      data-testid="personal-data-form-birthdate"
                      locale={this.props.lang}
                      invalidDateMessage={this.isEmpty('birthdate') ? true : ''}
                      id="birthdate"
                      mobile={isMobileSize(this.state.width)}
                      label={f({id: 'birthdate'})}
                      error={this.state.userNotValid || this.state.errorBirthdate || this.isEmpty('birthdate')}
                      format="YYYYMMDD"
                      value={this.state.birthdate}
                      maxDateMessage={f({id: 'invalidBirthdate'})}
                      minDateMessage={f({id: 'invalidBirthdate'})}
                      onChange={date => handleDateChange(date)}/>
                    {this.isEmpty('birthdate') && <span className={styles.ErrorMsg}>{f({id: 'emptyBirthdate'})}</span>}
                    {this.state.errorBirthdate && <span className={styles.ErrorMsg}>{f({id: 'invalidBirthdate'})}</span>}
                  </div>

                  <div className={styles.FormContent}>
                    <FormInput
                      required
                      data-testid="personal-data-form-email"
                      className={styles.Input}
                      placeholder={f({id: 'email'})}
                      label={f({id: 'email'})}
                      error={this.isEmpty('mail') || Boolean(this.state.errorEmail)}
                      type="email"
                      id="email"
                      value={this.state.mail || ''}
                      InputProps={{
                        endAdornment: (
                          <>
                            {(this.isEmpty('mail') || this.state.errorEmail) && (
                              <InputAdornment position="end">
                                <Warning className="red"/>
                              </InputAdornment>
                            )}
                          </>
                        )
                      }}
                      onChange={handleMailChange}
                    />
                    {this.isEmpty('mail') && <span className={styles.ErrorMsg}>{f({id: 'emptyEmail'})}</span>}
                    {this.state.errorEmail && <span className={styles.ErrorMsg}>{f({id: this.state.errorEmail})}</span>}
                  </div>

                </div>

                <div className="form-data left">
                  <div className={styles.FormContent}>
                    <PhoneInput
                      className={styles.PhoneNumber}
                      placeholder={f({id: 'tel'})}
                      label={f({id: 'tel'})}
                      country="fr"
                      autoFormat={false}
                      containerClass="react-tel-input"
                      inputProps={{
                        dataTestId: 'personal-data-form-tel'
                      }}
                      value={this.state.personnalTph || ''}
                      onChange={value => {
                        const personnalTph = value[0] === '+' ? value : `+${value}`;
                        this.setPhone(personnalTph);
                      }}/>
                    {this.state.badFormat && <div className={styles.ErrorMsg}>{f({id: 'wrongTelFormat'})}</div>}
                  </div>

                  <div className={styles.FormContent}>
                    <FormInput
                      required
                      data-testid="personal-data-form-address"
                      className={styles.Input}
                      placeholder={f({id: 'address'})}
                      label={f({id: 'address'})}
                      error={this.isEmpty('address')}
                      type="text"
                      id="address"
                      value={this.state.address || ''}
                      InputProps={{
                        endAdornment: (
                          <>
                            {this.isEmpty('address') && (
                              <InputAdornment position="end">
                                <Warning className="red"/>
                              </InputAdornment>
                            )}
                          </>
                        )
                      }}
                      onChange={handleAddressChange}
                    />
                    {this.isEmpty('address') && <span className={styles.ErrorMsg}>{f({id: 'emptyAddress'})}</span>}
                  </div>

                  <div className={styles.FormContent}>
                    <FormInput
                      required
                      data-testid="personal-data-form-postal"
                      className={styles.Input}
                      placeholder={f({id: 'postal'})}
                      label={f({id: 'postal'})}
                      type="text"
                      id="postal"
                      error={this.isEmpty('postCode') || this.state.errorPostal}
                      value={this.state.postCode || ''}
                      InputProps={{
                        endAdornment: (
                          <>
                            {(this.isEmpty('postCode') || this.state.errorPostal) && (
                              <InputAdornment position="end">
                                <Warning className="red"/>
                              </InputAdornment>
                            )}
                          </>
                        )
                      }}
                      onChange={handlePostCodeChange}
                    />
                    {this.isEmpty('postCode') && <span className={styles.ErrorMsg}>{f({id: 'emptyPostal'})}</span>}
                    {!this.isEmpty('postCode') && this.state.errorPostal && <span className={styles.ErrorMsg}>{f({id: 'invalidPostalFormat'})}</span>}
                  </div>

                  <div className={styles.FormContent}>
                    <FormInput
                      required
                      data-testid="personal-data-form-city"
                      className={styles.Input}
                      placeholder={f({id: 'city'})}
                      label={f({id: 'city'})}
                      type="text"
                      id="city"
                      error={this.isEmpty('city')}
                      value={this.state.city || ''}
                      InputProps={{
                        endAdornment: (
                          <>
                            {this.isEmpty('city') && (
                              <InputAdornment position="end">
                                <Warning className="red"/>
                              </InputAdornment>
                            )}
                          </>
                        )
                      }}
                      onChange={handleCityChange}
                    />
                    {this.isEmpty('city') && <span className={styles.ErrorMsg}>{f({id: 'emptyCity'})}</span>}

                  </div>

                </div>
              </div>
              <div className="formActions">
                {get(this.state, 'user.isFilled') && (
                  <ButtonLink
                    data-testid="personal-data-form-cancel-button"
                    id="cancel"
                    className="buttonLink"
                    style={{flex: 1}}
                    to="/personalData/myInformations"
                  >
                    {f({id: 'cancel'})}
                  </ButtonLink>
                )}

                <Button
                  data-testid="personal-data-form-next-button"
                  id="validate"
                  variant="contained"
                  className={`${isMobileSize(this.state.width) ? styles.MobileValidateButton : 'flat blue validateButton'}`}
                  disabled={buttonText === 'edit' ? this.state.unchanged : false}
                  style={{flex: 1}}
                  onClick={this.saveInfo}
                >
                  {f({id: buttonText})}
                </Button>
              </div>
            </form>
            <div className={styles.InfoMsg}>
              <span data-testid="pdf-mandatory" className="red">*&nbsp;</span>{f({id: 'mandatoryInformation'})}
            </div>
          </div>
        </div>
        <Dialog
          open={this.state.alert.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleClose}
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.alert.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alert.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testid="pdf-button2" color="primary" onClick={handleClose}>
              {this.state.alert.confirmText || 'Ok'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );

    // Global render
    if (this.props.tvCoaxProcess?.location?.isEnabled && this.state.isLocationPageOpen) {
      return renderLocationForm();
    }

    if (this.props.tvCoaxProcess?.code?.isEnabled && this.state.isTvCodePageOpen) {
      return renderTVCodePage();
    }

    return renderPersonalInfosForm();
  }
}

const mapStateToProps = state => {
  return {
    hospital: get(state, 'default.hospital.hospital'),
    user: get(state, 'user.user'),
    token: get(state, 'user.token'),
    tvCoaxProcess: get(state, 'default.tvCoaxProcess')
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    updateUser,
    validateUserSubscription,
    checkUserValidity,
    success,
    error,
    warning
  })(PersonnalDataForm)
);
