const load3DSecure = (form, successCallback, errorCallback, isPayline = false) => {
  // Create the form to do the 3D Secure process
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', '');
  iframe.style.position = 'absolute';
  iframe.style.zIndex = 99999;

  if (isPayline) {
    iframe.style.width = '50%';
    iframe.style.height = '50%';
    iframe.style.position = 'absolute';
    iframe.style.top = '50%';
    iframe.style.left = '50%';
    iframe.style.transform = 'translate(-50%, -50%)';
    iframe.style.background = 'white';
  } else {
    iframe.style.top = 0;
    iframe.style.width = '100vw';
    iframe.style.height = '100vh';
  }

  const submitButton = document.createElement('input');
  submitButton.setAttribute('type', 'submit');
  submitButton.style.display = 'none';
  form.append(submitButton);

  iframe.addEventListener('load', () => {
    iframe.contentDocument.body.append(form);
    submitButton.click();
  }, {once: true});

  // When we receive message from payment server, we handle it.
  // If ok, do the success callback (need to a payment with id3d into this callback)
  // If ko, display an error message (via the error callback)
  window.addEventListener('message', evt => {
    iframe.remove(); // Hide the iframe that contains the payment up2pay page
    const {status, session, ID3D} = evt.data;

    const secureError = evt.data['3DERROR'];

    if (status === 'OK') {
      console.log('3D Secure authorization succeed');
      successCallback(ID3D, session);
    } else {
      console.log('3D Secure authorization failed');
      errorCallback(secureError);
    }
  }, {once: true});

  document.querySelectorAll('body')[0].append(iframe);
};

const loadPaybox3DSecure = ({
  amount,
  cardNumber,
  cardExpiration,
  city = 'Paris',
  countryLocaleCode = 'fr', // 'fr' or 'en' or 'de' or 'es' or 'it'
  currency = 978, // Put 978 for €, 826 for £
  cardCvv,
  email = 'comptabilite@hoppen.care',
  firstname,
  lastname,
  urlHttpDirect,
  urlRetour,
  zipCode = '35000'
}, successCallback, errorCallback) => {
  console.log('Submit 3D Secure');

  let countryCode = 250; // FR

  switch (countryLocaleCode) {
    case 'fr':
      countryCode = 250;
      break;
    case 'en':
      countryCode = 826;
      break;
    case 'de':
      countryCode = 276;
      break;
    case 'es':
      countryCode = 724;
      break;
    case 'it':
      countryCode = 380;
      break;
    default:
      break;
  }

  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', 'https://tpeweb.e-transactions.fr/cgi/RemoteMPI.cgi');

  const fields = {
    Address1: '8 square du chene germain',
    Amount: amount,
    CCExpDate: cardExpiration,
    CCNumber: cardNumber,
    City: city,
    CountryCode: countryCode,
    Currency: currency,
    CVVCode: cardCvv,
    EmailPorteur: email,
    FirstName: firstname,
    LastName: lastname,
    IdMerchant: '370644504',
    IdSession: Date.now(),
    TotalQuantity: '1',
    URLHttpDirect: urlHttpDirect,
    URLRetour: urlRetour,
    ZipCode: zipCode
  };

  Object.keys(fields).forEach(name => {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', name);
    input.setAttribute('value', fields[name]);
    form.append(input);
  });

  return load3DSecure(form, successCallback, errorCallback);
};

const loadPayline3DSecure = ({redirect, method, fields}, paymentRedirect, successCallback, errorCallback) => {
  const form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', redirect);

  const inputChartset = document.createElement('input');
  inputChartset.setAttribute('type', 'hidden');
  inputChartset.setAttribute('name', '_charset_');
  inputChartset.setAttribute('value', 'UTF-8');
  form.append(inputChartset);

  Object.keys(fields).forEach(name => {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', name);
    input.setAttribute('value', fields[name].replace('<TERM_URL_VALUE>', paymentRedirect));
    form.append(input);
  });

  const submitButton = document.createElement('input');
  submitButton.setAttribute('type', 'submit');
  submitButton.style.display = 'none';
  form.append(submitButton);

  return load3DSecure(form, successCallback, errorCallback, true);
};

export {
  loadPaybox3DSecure,
  loadPayline3DSecure
};
