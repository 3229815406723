import React from 'react';
import {get} from 'lodash';
import {FormattedNumber, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';

export default function PaymentConfirmationDialog({
  supply,
  card,
  refillOnly,
  command,
  user,
  walletOk
}) {
  const {formatMessage: f} = useIntl();
  const locale = useSelector(state => state.default.locale);
  const formatFirstName = name => {
    const splitStr = name.toLowerCase().split('-');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join('-');
  };

  const formatLastName = name => {
    return name.toUpperCase();
  };

  const PatientInformation = () => (
    <p>
      <span data-testid="pcd-text1" className="bold">{f({id: 'patient'})} </span>
      <span data-testid="pcd-text2">
        {formatFirstName(get(user, 'firstname', ''))} {formatLastName(get(user, 'lastname', ''))}
      </span>
    </p>
  );

  const ListOfPlans = () => (
    <div className="flex left column start">
      <span data-testid="pcd-text3" className="bold">{f({id: 'forfaitListTitle'})}</span>
      {get(command, 'details') && (
        <ul>
          {get(command, 'details').map((item, index) => (
            item.qty > 0 && (
              <li key={`alertitemlist-${index}`} data-testid={`pcd-li-${index}`}>
                {item.qty} x {item.plan}
              </li>
            )
          ))}
        </ul>
      )}
      {!get(command, 'details') && (
        <span data-testid="pcd-text4">{f({id: 'accountSupply'})}</span>
      )}
    </div>
  );

  const PaymentMethod = () => (
    walletOk ? (
      <p>
        <span data-testid="pcd-text5" className="bold"> {f({id: 'paymentMethod'})}</span>
        {f({id: 'currentWalletMeanOfPayment'})}
      </p>
    ) : (
      <div className="flex column">
        {(card || user.payment) && (
          <>
            <span data-testid="pcd-text6" className="bold"> {f({id: 'paymentMethod'})}</span>
            <CardPaymentMethod/>
          </>
        )}
      </div>
    )
  );

  const CardPaymentMethod = () => {
    if (card) {
      return (
        <div className="flex column">
          <span data-testid="pcd-text7" className="left">
            {f({id: 'cardNumber'})} **** **** ****{' '}
            {card.card.substring(12) || '****'}
          </span>
          <span data-testid="pcd-text8" className="left">
            {f({id: 'expirationDate'})} {`${card.date.substring(0, 2)}/${card.date.substring(2, 4)}`}
          </span>
        </div>
      );
    }

    return (
      <div>
        <span data-testid="pcd-text9" className="left">
          {f({id: 'cardNumber'})} **** **** ****{' '}
          {'****'}
        </span>
        <span data-testid="pcd-text10" className="left">
          {f({id: 'expirationDate'})} {user.paymentExp}
        </span>
      </div>
    );
  };

  const PlansTotalAmount = () => (
    <div>
      <span data-testid="pcd-text11" className={`${supply ? '' : 'bold'}`}>{f({id: 'forfaitAmountTotal'})}</span>
      <span data-testid="pcd-text12">
        <FormattedNumber value={get(command, 'total', 0)} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>
      </span>
    </div>
  );

  const SupplyAmount = ({value}) => (
    <div>
      <span data-testid="pcd-text13" className="bold">{f({id: 'supplyAmountLabel'})}</span>
      <span data-testid="pcd-text14">
        <FormattedNumber value={value} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>
      </span>
    </div>
  );

  const RefillOnlyConfirmation = () => (
    <>
      <div className="flex column">
        <span data-testid="pcd-text15" className="bold"> {f({id: 'paymentMethod'})}</span>
        {(card || user.payment) && <CardPaymentMethod/>}
      </div>
      <br/>

      <SupplyAmount value={get(command, 'total', 0)}/>
    </>
  );

  const BuyPlansConfirmation = () => (
    <>
      <ListOfPlans/>
      <PaymentMethod/>
      <br/>
      <PlansTotalAmount/>
      <br/>
      {supply > 0 && !walletOk && <SupplyAmount value={supply}/>}
    </>
  );

  return (
    <div>
      <PatientInformation/>
      {refillOnly ? <RefillOnlyConfirmation/> : <BuyPlansConfirmation/>}
    </div>
  );
}
