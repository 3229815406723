import React from 'react';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {Divider} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import FileCard from './file-card';
import FileActions from './file-actions';

import styles from './documents-list-item.module.scss';

const computeEnabledState = (isOK, hasNotify, displayNotify) => {
  if (isOK && !hasNotify) {
    return (
      <>
        {isOK && !hasNotify && (
          <CheckCircleIcon htmlColor="green" className={styles.selfCenter}/>
        )}
      </>
    );
  }

  if (isOK && hasNotify) {
    return (
      <>
        {isOK && hasNotify && (
          <ErrorIcon htmlColor="orange" className={`${styles.selfCenter} ${styles.pulseButton} ${styles.Button}`} onClick={() => displayNotify(hasNotify, isOK)}/>
        )}
      </>
    );
  }
};

const actions = (data, user, onEdit, description, category) => {
  return (
    <FileActions user={user} data={data} editFile={onEdit} description={description} category={category}/>
  );
};

const DocumentListItem = ({type, user, files, onUpload, notify}) => {
  const {formatMessage: f} = useIntl();

  const getFileProperty = property => {
    if (type?.typeName && Object.keys(files).length > 0) {
      return property ? (files[type.typeName] && files[type.typeName][property] || false) : (files[type.typeName] || false);
    }

    return false;
  };

  return (
    <div className={styles.DocumentList2}>
      <div className={styles.ListItemCard}>
        <div className={styles.DocumentContent}>
          {computeEnabledState(
            getFileProperty('title'),
            getFileProperty('notify'),
            notify
          )}
          <div className={styles.DocumentCard}>
            <span data-testid="dli-text1" className={styles.FileTypeName}>{type.typeName}</span>
            <span data-testid="dli-text2" className={styles.FileDescText}>{type.typeDesc}</span>
          </div>

        </div>
        <div className={styles.MinMaxContentWidth}>
          {getFileProperty('title') && (
            <>
              {actions(getFileProperty(), user, onUpload, type.typeDesc, type.category)}
            </>
          )}
          {!getFileProperty('title') && (
            <>
              <FileCard
                type={type.typeName}
                category={type.category}
                description={f({id: 'add'})}
                fileDesc={type.typeDesc}
                onAdd={onUpload}
              />
            </>
          )}
        </div>

      </div>

      <Divider/>
    </div>
  );
};

DocumentListItem.propTypes = {
  type: PropTypes.object.isRequired,
  onUpload: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  files: PropTypes.object.isRequired
};

export default DocumentListItem;
