import PersonnalData from './personal-data';
import PersonnalDataForm from '../user/PersonnalDataForm/personal-data-form';
import PersonnalDataShow from '../user/PersonnalDataForm/personal-data-show';
import PasswordForm from '../authentication/PasswordForm/password-form';
import ActivationCode from '../authentication/ActivationCodeInput/activation-code';
import ActiveServices from '../user/ActiveServices/active-services';
import CGUPanel from '../user/CguPanel/cgu-panel';
import Invoice from '../user/Invoice/invoice';
import PaymentHistory from '../payment/PurchasesPanel/History/history';

const personnalDataPanel = {
  title: 'myAccount',
  icon: 'person',
  identity: 'personalData',
  mainComponent: PersonnalData,
  pages: [
    {
      title: 'edit',
      component: PersonnalDataForm,
      parent: 'personalData'
    },
    {
      title: 'myInformations',
      component: PersonnalDataShow,
      parent: 'personalData'
    },
    {
      title: 'modifyPassword',
      component: PasswordForm,
      parent: 'personalData'
    },
    {
      title: 'myActivationCode',
      component: ActivationCode,
      parent: 'personalData'
    },
    {
      title: 'myActiveServices',
      component: ActiveServices,
      parent: 'personalData',
      condition: 'payment'
    },
    {
      title: 'legal',
      component: CGUPanel,
      parent: 'personalData'
    },
    {
      title: 'myInvoices',
      component: Invoice,
      parent: 'personalData'
    },
    {
      title: 'paymentHistory',
      component: PaymentHistory,
      parent: 'personalData'
    }
  ]
};

export default personnalDataPanel;
