import personnalDataPanel from '../components/PersonnalDataPanel';
import multimediaPanel from '../components/payment/MultimediaPanel/multimedia-panel';
import documentsPanel from '../components/documents/documents-panel';
import {surveyPanel} from '../components/survey/survey-panel';
import preadmissionPanel from '../components/preadmission/preadmission-panel';

const conciergePanel = {
  title: 'myConcierge',
  icon: 'shopping_cart',
  identity: 'concierge',
  mainComponent: () => {},
  onClick: () => window.open('https://chicconciergerie.com/'),
  pages: []
};

export const HomePersonalData = personnalDataPanel.mainComponent;

export default [personnalDataPanel, multimediaPanel, conciergePanel, surveyPanel, documentsPanel, preadmissionPanel];
