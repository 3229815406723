import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

export default withStyles({
  root: {
    '& label': {
      minWidth: '-webkit-fill-available',
      marginLeft: '0.5em',
      textAlign: 'start',
      cursor: 'text',
      '& span': {
        color: 'red'
      }
    },
    '& label.Mui-focused': {
      minWidth: '20em'
    },
    '& label.MuiFormLabel-filled': {
      minWidth: '20em'
    }
  }

})(TextField);
