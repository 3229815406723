import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const IconButton = withStyles({
  root: {
    backgroundColor: 'white',
    border: '1px solid #2196F3',
    borderRadius: '5px',
    fontWeight: 'bold',
    color: '#2196F3',
    //  MinWidth: "8em",
    margin: '0.5em',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#2196F3',
      borderColor: '#007bff',
      color: 'white',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
      borderColor: '#005cbf'
    }
  }
})(Button);

const BlueIconButton = ({icon, text, onClick, matchSize}) => {
  const matches = useMediaQuery(`(min-width:${matchSize}px)`);

  return (
    <IconButton data-testid="white-icon-button" startIcon={matches && <Icon>{icon}</Icon>} onClick={onClick}>
      {!matches && <Icon>{icon}</Icon> }
      {matches && <span>{text}</span>}

    </IconButton>
  );
};

export default BlueIconButton;
