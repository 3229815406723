import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import TextField from '@material-ui/core/TextField/TextField';
import {FormControl, InputLabel, MenuItem, Select, Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {useDispatch} from 'react-redux';

import BlueButton from '../../blue-button';
import WhiteButton from '../../white-button';
import {getAssociatedLocationFromTvCode, allocateUserLocation, updateUserLocation, fetchUserPlans, fetchTvCodeMsg} from '../../../actions/user';
import {error, success} from '../../../actions';
import styles from './tv-code-page.module.scss';
import {convertTextInMultilines} from '../../shared/convert-text-in-multilines';

const TvCodePage = ({user, onBackButton, handleSendUpdateUserRequest, token, hospital, onUpdate}) => {
  const {formatMessage: f} = useIntl();
  const [tvCode, setTvCode] = useState('');
  const [availableLocations, setAvailableLocations] = useState([]);
  const [confirmPageOpen, setConfirmPageOpen] = useState(false);
  const [associatedLocation, setAssociatedLocation] = useState(null);
  const [tvCodeMessage, setTvCodeMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTvCodeMsg(user?.hospID || hospital?.hospID)
      .then(({result, data}) => {
        if (result === 'SUCCESS' && data) {
          setTvCodeMessage(data);
        }
      });
  }, [hospital, user]);

  const handleValidate = async () => {
    if (!tvCode) {
      return handleSendUpdateUserRequest(user);
    }

    try {
      const locations = await getAssociatedLocationFromTvCode(tvCode, token, hospital?.hospID);
      setAvailableLocations(locations);

      if (locations.length === 1) {
        setAssociatedLocation(locations[0]);
      }

      setConfirmPageOpen(true);
    } catch (error_) {
      console.error('Failed to get associated location', error_);
      dispatch(error(f({id: 'tvCodeNotValid'})));
    }
  };

  const handleConfirm = async () => {
    const isSuccess = await handleSendUpdateUserRequest(user, associatedLocation);
    if (isSuccess && associatedLocation) {
      const location = {locID: associatedLocation.locID, room: associatedLocation.room};
      const service = {servID: associatedLocation?.service?.servID, name: associatedLocation?.service?.name};

      const body = {token: token || '', hospID: hospital?.hospID, location, service};
      const {status, msg} = await dispatch(updateUserLocation(user._id, body));

      if (status === 200) {
        dispatch(success(f({id: 'dataUpdateConfirm'})));
        // Send allocate request to SI if user has TV plan
        if (user.plans?.length > 0) {
          const response = await dispatch(fetchUserPlans({customerID: user.uuid, token}));
          if (response.data?.items?.length > 0) {
            const body = {customerID: user.uuid, hospID: hospital.hospID, location};
            await dispatch(allocateUserLocation(user._id, token, body));
          }
        }
      } else {
        dispatch(error(f({id: msg || 'error'})));
      }
    }

    onUpdate('personalHome');
    onBackButton();
  };

  return (
    <div className={styles.container} dataTestId="tv-code-page">
      <h1 className={styles.title}>{f({id: 'fillTvCode'})}</h1>
      <p className={styles.subtitle}>{f({id: 'fillTvCodeDescription'})}</p>

      <TextField
        style={{margin: 40}}
        value={tvCode}
        variant="filled"
        placeholder={f({id: 'tvCode'})}
        data-testid="tv-code-page-tv-code-input"
        onChange={evt => setTvCode(evt.target.value)}/>

      {tvCodeMessage &&
        <div className={styles.tvCodeMsgContainer}>
          <InfoOutlinedIcon className="grey"/>
          {convertTextInMultilines(tvCodeMessage)}
        </div>}

      <div className={styles.buttons}>
        <WhiteButton
          className={styles.button}
          variant="outlined"
          data-testid="tv-code-page-tv-cancel-button"
          onClick={onBackButton}
        >
          {f({id: 'cancel'})}
        </WhiteButton>
        <BlueButton
          className={styles.button}
          variant="contained"
          data-testid="tv-code-page-next-button"
          onClick={handleValidate}
        >
          {f({id: 'validate'})}
        </BlueButton>
      </div>

      <Dialog open={confirmPageOpen} dataTestId="tv-code-page-dialog-validate-room" onClose={() => setConfirmPageOpen(false)}>
        <DialogTitle className={styles.checkRoomTitle}>{f({id: 'checkingTheRoom'})}</DialogTitle>
        <DialogContent>
          {
            availableLocations.length > 1 ? (
              <p style={{fontWeight: 'bold'}}>{f({id: 'checkingTheRoomDescriptionDouble'}, {service: availableLocations[0]?.service?.name || '/'})}</p>
            ) : (
              <>
                <p style={{fontWeight: 'bold'}}>{f({id: 'checkingTheRoomDescription1'}, {room: associatedLocation?.room || '/', service: associatedLocation?.service?.name || '/'})}</p>
                <p>{f({id: 'checkingTheRoomDescription2'})}</p>
                <p>{f({id: 'checkingTheRoomDescription3'})}</p>
              </>
            )
          }
          {
            availableLocations.length > 1 && (
              <FormControl className={styles.roomSelector}>
                <InputLabel>{f({id: 'room'})}</InputLabel>
                <Select data-testid="check-location-select-room" value={associatedLocation} onChange={evt => setAssociatedLocation(evt.target.value)}>
                  {
                    availableLocations.map(elt => (
                      <MenuItem key={elt._id} value={elt}>{elt.room}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            )
          }
          { availableLocations.length > 1 && <p>{f({id: 'checkingTheRoomDoubleRoomInfo'})}</p>}
        </DialogContent>
        <DialogActions style={{justifyContent: 'space-between'}}>
          <WhiteButton
            data-testid="check-location-cancel-button"
            variant="outlined" onClick={() => {
              setAvailableLocations([]);
              setConfirmPageOpen(false);
            }}
          >
            {f({id: 'cancel'})}
          </WhiteButton>
          <BlueButton
            data-testid="check-location-confirm-button"
            disabled={!associatedLocation}
            variant="contained"
            onClick={handleConfirm}
          >
            {f({id: 'confirm'})}
          </BlueButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

TvCodePage.propTypes = {
  user: PropTypes.object,
  onBackButton: PropTypes.func.isRequired,
  handleSendUpdateUserRequest: PropTypes.func.isRequired,
  token: PropTypes.string,
  hospital: PropTypes.object,
  onUpdate: PropTypes.func.isRequired
};

export default TvCodePage;
