export default function (state = {}, action = {}) {
  switch (action.type) {
    case 'CLEAN_STATE':
      return {...state, user: {}, token: '', commands: {}, apiceaUser: null, services: {}};
    case 'SET_USER':
      if (action.payload.user && action.payload.token) {
        localStorage.setItem('user', action.payload.user._id);
        localStorage.setItem('token', action.payload.token);
        return {
          ...state,
          user: action.payload.user,
          token: action.payload.token
        };
      }

      if (action.payload.user) {
        localStorage.setItem('user', action.payload.user._id);
        return {
          ...state,
          user: action.payload.user
        };
      }

      return {...state};
    case 'SET_TMP_USER':
      if (action.payload.user) {
        return {
          ...state,
          user: action.payload.user
        };
      }

      return {...state};
    case 'SET_EMPTY_USER':
      if (action.payload.user && action.payload.token) {
        return {
          ...state,
          user: action.payload.user,
          token: action.payload.token
        };
      }

      return {...state};
    case 'USER_PREADMISSION_NOTIFY':
      return {
        ...state,
        user: {...state.user, deadlinePassed: action.payload.deadlinePassed}
      };
    case 'UPDATE_WALLET':
      return {
        ...state,
        user: {...state.user, wallet: action.payload.wallet}
      };
    case 'UPDATE_PLANS':
      return {...state, user: {...state.user, plans: action.payload.plans}};
    case 'COMPLETED_INFO':
      localStorage.setItem('user', action.payload.user._id);
      const token = localStorage.getItem('token');
      return {...state, user: {...action.payload.user, token}};
    case 'SET_USER_LOCATION':
      return {
        ...state,
        user: {...state.user, service: action.payload.service, location: action.payload.location}
      };
    case 'FETCH_COMMANDS_SUCCESS':
      return {
        ...state,
        commands: {data: action.payload.data, error: false}
      };
    case 'FETCH_COMMANDS_FAILURE':
      return {
        ...state,
        commands: {error: true, errorMsg: action.payload}
      };
    case 'USER_STATUS_UPDATED':
      return {...state, user: {...state.user, status: action.payload}};
    case 'FETCH_APICEA_CUSTOMER_DONE':
      return {
        ...state,
        apiceaUser: action.payload.data
      };
    case 'CHECK_USER_SERVICE_STATUS_DONE':
      return {
        ...state,
        services: {...state.services, [action.payload.service]: action.payload.status}
      };
    default:
      return state;
  }
}
