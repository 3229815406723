import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {get, orderBy} from 'lodash';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {fetchPaymentData} from '../../../../actions/payment';
import PurchaseCard from './purchase-card';

import styles from './history.module.scss';

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      token: '',
      history: [],
      pg: 1
    };
  }

  componentDidUpdate(props) {
    if (props.user && props.user._id !== get(this.state, 'user._id')) {
      this.setState({user: props.user});
    }

    if (props.token !== this.state.token) {
      this.setState({token: props.token});
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;
    if (nextProps.user) {
      newProps = newProps ?
        {...newProps, user: nextProps.user} :
        {user: nextProps.user};
    }

    if (nextProps.token) {
      newProps = newProps ?
        {...newProps, token: nextProps.token} :
        {token: nextProps.token};
    }

    if (nextProps.history) {
      newProps = newProps ?
        {...newProps, history: nextProps.history} :
        {history: nextProps.history};
    }

    return newProps;
  }

  componentDidMount() {
    if (this.state.user) {
      const {customerID} = this.state.user;
      const {token} = this.state;
      this.props.fetchPaymentData({customerID, token}, this.state.pg);
    }
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    const {history} = this.state;

    return (
      <>
        <div className="pageActions">
          <Breadcrumbs aria-label="breadcrumb">
            <Link data-testid="history-bacl" color="inherit" to="/personalData">
              {f({id: 'myAccount'})}
            </Link>
            <Link
              data-testid="history-current"
              color="textPrimary"
              to="/personalData/paymentHistory"
              aria-current="page"
            >
              {f({id: 'paymentHistory'})}
            </Link>
          </Breadcrumbs>
          <Link data-testid="history-smallscreen-back" className="smallScreenBack" to="/personalData">
            <ArrowBack/>
          </Link>
        </div>
        <div className="pageContent">
          <div className="personnalDataShow">
            <br/>
            {history.length === 0 && (
              <span data-testid="history-text1">{f({id: 'noHistory'})}</span>
            )}

            {history.length > 0 && (
              <div className="fullWidth">
                {history.map((item, index) => (
                  <PurchaseCard
                    key={`purchase-${index}`}
                    locale={this.props.locale}
                    item={item}
                  />
                ))}
              </div>
            )}
          </div>
          <span data-testid="history-text2" className={styles.InfoMsg}>{f({id: 'PTTCnote'})}</span>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    history: orderBy(get(state, 'payment.history'), ['date'], ['desc']),
    user: get(state, 'user.user'),
    token: get(state, 'user.token'),
    locale: get(state, 'default.locale')
  };
};

export default injectIntl(
  connect(mapStateToProps, {fetchPaymentData})(History)
);
