import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Checkbox, Button, InputAdornment} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import Warning from '@material-ui/icons/Error';

import ActivationCodeInput from '../ActivationCodeInput/activation-code-input';
import ContractIframe from '../ContractIframe/contract-iframe';
import FormInput from '../../shared/FormInput/form-input';

import styles from './user-account-validation-signup.module.scss';

import {fetchGTU, fetchGDPR, accountValidation, checkCode} from '../../../actions/authentication';
import {error} from '../../../actions';
import {setUserFromAC} from '../../../actions/user';

const BlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#2196F3'
    }
  },
  checked: {}
})(props => <Checkbox {...props}/>);

export default function UserAccountValidationSignup({appName, hasMultimedia, hospID, user, onUpdate}) {
  const {formatMessage: f} = useIntl();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [codeMessage, setCodeMessage] = useState(null);
  const [valid, setValid] = useState(false);
  const [gtu, setGTU] = useState({});
  const [gdpr, setGDPR] = useState({});
  const [gtuVisible, setGtuVisible] = useState(false);
  const [gtuMSVisible, setGtuMSVisible] = useState(false);
  const [gdprVisible, setGdprVisible] = useState(false);
  const [step, setStep] = useState(1);

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [hasActivationCode, setHasActivationCode] = useState(false);
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [validateGTU, setValidateGTU] = useState('');
  const [validateRGPD, setValidateRGPD] = useState('');

  useEffect(() => {
    if (hospID) {
      fetchGTU(hospID).then(gtuData => {
        if (gtuData) {
          setGTU({...gtuData.data, type: 'gtu'});
        }
      });
      fetchGDPR(hospID).then(gdprData => {
        if (gdprData) {
          setGDPR({...gdprData.data, type: 'gdpr'});
        }
      });
    }
  }, [hospID]);

  const passwordOk = password => {
    const pwdRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\\d).{8,}$');
    const isContentOk = pwdRegex.test(password);
    if (!password || password.length < 8 || !isContentOk) {
      return false;
    }

    return true;
  };

  const isValid = () => {
    let errors = [];
    if (
      !password.length ||
      !passwordConfirm.length

    ) {
      setValid(false);
    } else if (password !== passwordConfirm) {
      setValid(false);
      errors = [...errors, 'passwordNotMatch'];
    } else if (!passwordOk(password)) {
      setValid(false);
      errors = [...errors, 'emptyPassword'];
    } else {
      if (codeMessage) {
        setCodeMessage(null);
      }

      if (!validateGTU || !validateRGPD) {
        setValid(false);
      } else {
        setValid(true);
      }
    }

    setErrors(errors);

    if (step === 2 && (!code.length || !name.length)) {
      setValid(false);
    } else if (step === 2 && code.length && name.length) {
      setValid(true);
    }
  };

  useEffect(() => {
    isValid();
  }, [password, passwordConfirm, validateGTU, validateRGPD, step, name, code]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBack = () => {
    setStep(1);
  };

  const validate = async () => {
    if (!hospID) {
      return;
    }

    let errors = [];

    if (!passwordOk(password)) {
      errors = [...errors, 'emptyPassword'];
    }

    if (password !== passwordConfirm) {
      errors = [...errors, 'passwordNotMatch'];
    }

    setErrors(errors);

    if (step === 1 && hasActivationCode) {
      setStep(2);
      return;
    }

    if (step === 2 && hasActivationCode) {
      const codeOk = await dispatch(checkCode(
        hospID,
        name,
        code
      ));
      if (codeOk.result === 'ERROR') {
        setCodeMessage(codeOk.msg);

        return;
      }
    }

    const response = await accountValidation({
      password,
      hospID,
      type: 'customer',
      gtu: validateGTU ? new Date() : false,
      gdpr: validateRGPD ? new Date() : false,
      _id: user._id
    });
    if (response.result === 'ERROR') {
      error(f({id: response.msg || 'serverError'}));
    } else if (response.status === 200) {
      if (code.length > 0 && response.userId && response.token) {
        await dispatch(setUserFromAC(
          code,
          response.userId,
          response.token
        ));
        onUpdate('personnalDataForm');
      } else {
        // Go to personal data page
        onUpdate('login');
      }

      // This.props.signup();
    }
  };

  return (
    <div className="page main login">
      <div className="pageContent noTop flex column">
        <div className="header">
          {step === 1 && <h3>{f({id: 'accountValidation'})}</h3>}
          {step === 2 && (
            <h3 data-testid="signup-text1">
              {f({id: 'accountValidation'})} - {f({id: 'activationCode'})}
            </h3>
          )}
        </div>
        <div className="loginForm self-center width30">
          <form noValidate className="root" autoComplete="off">
            {step === 1 && (
              <>
                <FormInput
                  required
                  data-testid="signup-input1"
                  label={f({id: 'password'})}
                  placeholder={f({id: 'password'})}
                  type="password"
                  id="password"
                  error={errors.includes('emptyPassword')}
                  value={password}
                  InputProps={{
                    endAdornment: (
                      <>
                        {errors.includes('emptyPassword') && (
                          <InputAdornment position="end">
                            <Warning className="red"/>
                          </InputAdornment>
                        )}
                      </>
                    )
                  }}
                  onChange={event => setPassword(event.target.value)}
                />
                <span
                  data-testid="signup-text1"
                  className={
                    errors.includes('emptyPassword') ?
                      styles.ErrorMsg :
                      styles.InfoMsg
                  }
                >
                  {f({id: 'signupNote'})}
                </span>
                <br/>
                <FormInput
                  required
                  data-testid="signup-input3"
                  label={f({id: 'passwordConfirm'})}
                  placeholder={f({id: 'passwordConfirm'})}
                  type="password"
                  id="passwordConfirm"
                  value={passwordConfirm}
                  error={errors.includes('passwordNotMatch')}
                  InputProps={{
                    endAdornment: (
                      <>
                        {errors.includes('passwordNotMatch') && (
                          <InputAdornment position="end">
                            <Warning className="red"/>
                          </InputAdornment>
                        )}
                      </>
                    )
                  }}
                  onChange={event => setPasswordConfirm(event.target.value)}
                />
                {errors.includes('passwordNotMatch') && (
                  <span data-testid="signup-text3" className={styles.ErrorMsg}>
                    {f({id: 'passwordNotMatch'})}
                  </span>
                )}
                <br/>
                <span data-testid="signup-text4" className="self-start">
                  {f({id: 'activationCodeReq'})}
                </span>
                <div className={styles.ActivationCodeDiv}>
                  <Button
                    data-testid="signup-button1"
                    id="activation-code-yes"
                    variant="outlined"
                    color="primary"
                    className={
                      hasActivationCode ?
                        styles.Selected :
                        styles.ActivationButton
                    }
                    onClick={() => {
                      setHasActivationCode(true);
                      setCode('');
                      setName('');
                    }}
                  >
                    {f({id: 'activationCodeYes'})}
                  </Button>
                  <Button
                    data-testid="signup-button2"
                    id="activation-code-no"
                    variant="outlined"
                    color="primary"
                    className={
                      !hasActivationCode ?
                        styles.Selected :
                        styles.ActivationButton
                    }
                    onClick={() => setHasActivationCode(false)}
                  >
                    {f({id: 'activationCodeNo'})}
                  </Button>
                </div>
                <br/>
                <div style={{display: 'flex', marginTop: '0.5em', width: '-webkit-fill-available'}}>
                  <BlueCheckbox
                    inputProps={{
                      'data-testid': 'signup-checkbox1'
                    }}
                    checked={validateGTU}
                    onChange={event => setValidateGTU(event.target.checked)}
                  />{' '}
                  <div data-testid="signup-text5" className={styles.CGUText}>
                    {f({id: 'legalCGUXP'})}
                    <span>&nbsp;</span>
                    <span
                      data-testid="signup-text6"
                      className={styles.Link}
                      onClick={() => setGtuVisible(true)}
                    >
                      {f({id: 'legalCGUXPLink'})}&nbsp;
                      {appName}&nbsp;
                    </span>
                    {hasMultimedia && (
                      <>
                        {f({id: 'legalCGUMS'})}
                        <span>&nbsp;</span>
                        <span
                          data-testid="signup-text7"
                          className={styles.Link}
                          onClick={() => setGtuMSVisible(true)}
                        >
                          {f({id: 'legalCGUMSLink'})}
                        </span>
                      </>
                    )}
                      .<span data-testid="signup-text8" className="red">*</span>
                  </div>
                </div>
                <div style={{display: 'flex', marginTop: '0.5em', width: '-webkit-fill-available'}}>
                  <BlueCheckbox
                    inputProps={{
                      'data-testid': 'signup-checkbox2'
                    }}
                    checked={validateRGPD}
                    onChange={event => setValidateRGPD(event.target.checked)}
                  />
                  <div data-testid="signup-text9" className={styles.CGUText}>
                    {f({id: 'legalRGPD'})}
                    <span>&nbsp;</span>
                    <span
                      data-testid="signup-text10"
                      className={styles.Link}
                      onClick={() => setGdprVisible(true)}
                    >
                      {f({id: 'legalRGPDLink'})}<span data-testid="signup-text11" className="red">*</span>
                    </span>
                  </div>
                </div>
              </>
            )}
            {step === 2 && (
              <ActivationCodeInput
                nameInput={name}
                codeInput={code}
                message={codeMessage}
                onCodeChange={evt => setCode(evt.target.value)}
                onNameChange={evt => setName(evt.target.value)}
              />
            )}
            <div style={{display: 'flex', alignItems: 'baseline'}}>
              {step === 2 && (
                <Button
                  className={styles.BackButton}
                  color="primary"
                  onClick={() => handleBack()}
                >
                  {f({id: 'return'})}
                </Button>
              )}

              <Button
                data-testid="signup-button3"
                id="validate"
                className="flat blue noTop"
                disabled={!valid}
                onClick={validate}
              >
                {f({id: 'validate'})}
              </Button>
            </div>
          </form>
        </div>
        <div className={styles.InfoMsg}>
          <span data-testid="signup-mandatory" className="red">*&nbsp;</span>{f({id: 'mandatoryInformation'})}
        </div>
      </div>
      <ContractIframe
        item={gtu}
        hospID={hospID}
        visible={gtuVisible}
        onClose={() => setGtuVisible(false)}
      />
      <ContractIframe
        item={gdpr}
        hospID={hospID}
        visible={gdprVisible}
        onClose={() => setGdprVisible(false)}
      />
      <ContractIframe
        item={{url: `/api/proxy/si/${hospID}/contracts/fr_FR-eula`}}
        visible={gtuMSVisible}
        onClose={() => setGtuMSVisible(false)}
      />
    </div>
  );
}
