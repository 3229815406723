import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import Button from '@material-ui/core/Button';
import {useSelector} from 'react-redux';
import RadioInput from '../radio-input';
import TextInput from '../text-input';
import CheckboxInput from '../checkbox-input';
import ProgressBar from '../progress-bar';

export default function QuestionPage({
  question,
  isFirstQuestion,
  isLastQuestion,
  onNextQuestion,
  onPreviousQuestion,
  onSubmitResponses,
  onSetResponses,
  onCancelSurvey,
  isReadOnly
}) {
  const {formatMessage: f} = useIntl();
  const survey = useSelector(state => state.surveys.selectedSurvey);

  const getCurrentResponse = () => survey.responses.find(res => res.question === question.order);
  const questionIsComplete = () => {
    if (!question.mandatory) {
      return true;
    }

    const currentResponse = getCurrentResponse();
    if (currentResponse && currentResponse.value && currentResponse.value.some(elt => elt.length > 0)) {
      return true;
    }

    return false;
  };

  const goToNextQuestion = () => {
    // Set false response if necessary
    if (!isReadOnly && !question.mandatory) {
      // Check if a response has been written
      if (!survey.responses.find(res => res.question === question.order)) {
        onSetResponses([], question.order);
        onNextQuestion({value: [], question: question.order});
        return;
      }
    }

    onNextQuestion();
  };

  const renderTextQuestion = () => (
    <TextInput
      data-testid="qp-input1"
      isReadOnly={isReadOnly}
      setResponse={value => onSetResponses(value, question.order)}
      currentResponse={getCurrentResponse()}/>
  );

  const renderRadioQuestion = () => (
    <RadioInput
      data-testid="qp-input2"
      isReadOnly={isReadOnly}
      possibilities={question.responses}
      setResponse={value => onSetResponses(value, question.order)}
      currentResponse={getCurrentResponse()}/>
  );

  const renderCheckboxQuestion = () => (
    <CheckboxInput
      key={question.id}
      data-testid="qp-input3"
      isReadOnly={isReadOnly}
      possibilities={question.responses}
      setResponse={value => onSetResponses(value, question.order)}
      currentResponse={getCurrentResponse()}/>
  );

  return (
    <div style={styles.container}>
      <p style={styles.title}>
        <span data-testid="qp-text1">{question.name}</span>
        { question.mandatory && <span data-testid="qp-mandatory" style={{color: 'red'}}> *</span>}
      </p>
      { question.type === 'text' && renderTextQuestion() }
      { question.type === 'radio' && renderRadioQuestion() }
      { question.type === 'checkbox' && renderCheckboxQuestion() }
      <div style={styles.bottomContainer}>
        <p>
          <span style={{color: 'red'}}>* </span>
          <span data-testid="qp-text2">{f({id: 'indicateMandatoryInfo'})}</span>
        </p>
        <ProgressBar current={question.order + 1} total={survey.questions.length}/>
        <div style={styles.containerButtons}>
          {
            isFirstQuestion ?
              <Button data-testid="qp-button1" variant="contained" size="large" style={styles.button} onClick={onCancelSurvey}>{f({id: 'return'})}</Button> :
              <Button data-testid="qp-button2" variant="contained" size="large" style={styles.button} onClick={onPreviousQuestion}>{f({id: 'previous'})}</Button>
          }
          {
            isLastQuestion ?
              <Button
                data-testid="qp-button3"
                disabled={!questionIsComplete()}
                variant="contained"
                size="large"
                style={{...styles.button, backgroundColor: questionIsComplete() ? '#2196F3' : 'grey'}}
                onClick={onSubmitResponses}
              >
                {isReadOnly ? f({id: 'exit'}) : f({id: 'send'})}
              </Button> :
              <Button
                data-testid="qp-button4"
                disabled={!questionIsComplete()}
                variant="contained"
                size="large"
                style={{...styles.button, backgroundColor: questionIsComplete() ? '#2196F3' : 'grey'}}
                onClick={goToNextQuestion}
              >
                {f({id: 'next'})}
              </Button>
          }
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: '5%',
    paddingRight: '5%'
  },
  title: {
    fontSize: '2em'
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-end',
    marginTop: 'auto',
    width: '100%'
  },
  containerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 40,
    marginTop: 20
  },
  button: {
    backgroundColor: '#2196F3',
    color: 'white',
    textTransform: 'initial',
    fontWeight: 600,
    minWidth: 120
  },
  disabledButton: {
    backgroundColor: 'grey'
  }
};

QuestionPage.propTypes = {
  question: PropTypes.object.isRequired,
  isFirstQuestion: PropTypes.bool.isRequired,
  isLastQuestion: PropTypes.bool.isRequired,
  onNextQuestion: PropTypes.func.isRequired,
  onPreviousQuestion: PropTypes.func.isRequired,
  onSubmitResponses: PropTypes.func.isRequired,
  onSetResponses: PropTypes.func.isRequired,
  onCancelSurvey: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool
};

