import React from 'react';
import {useIntl} from 'react-intl';
import {get} from 'lodash';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {sendMail} from '../../../actions';
import BlueButton from '../../blue-button';

import styles from './validation.module.scss';

const ValidationPage = ({history, success, error, onUpdate}) => {
  const {formatMessage: f} = useIntl();
  const userID = get(history, 'location.state');

  const sendActivationMail = () => {
    sendMail(userID)
      .then(() => {
        success(f({id: 'mailSent'}));
      })
      .catch(() => {
        error(f({id: 'mailError'}));
      });
  };

  return (
    <div className="page main login container">
      <div className={styles.Actions}>
        <BlueButton data-testid="validation-button1" className={styles.BackButton} startIcon={<ArrowBack/>} onClick={() => onUpdate('')}>
          <span className="label">{f({id: 'return'})}</span>
        </BlueButton>
      </div>
      <div className={styles.PageContent}>
        {!userID && (
          <span data-testid="validation-text1">{f({id: 'expiredLink'})}</span>
        )}
        {userID && (
          <>
            <span data-testid="validation-text2">{f({id: 'expiredLink2'})}</span>
            <BlueButton data-testid="validation-button2" className={styles.Button} onClick={sendActivationMail}>
              <span className="label">{f({id: 'resendActivationMail'})}</span>
            </BlueButton>
          </>
        )}
      </div>
    </div>
  );
};

export default ValidationPage;
