import React from 'react';
import {Grid, InputAdornment} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Warning from '@material-ui/icons/Error';
import {useIntl} from 'react-intl';

import FormInput from '../../shared/FormInput/form-input';
import styles from './activation-code-input.module.scss';

const ActivationCodeInput = ({nameInput, codeInput, onCodeChange, onNameChange, message}) => {
  const {formatMessage: f} = useIntl();

  return (
    <div>
      <Grid container>
        <Grid item md={12} xs={12}>
          <span>{f({id: 'activationCodeDesc'})}</span>

          <div className="flex column lightMargin">
            <div className="flex column mail-form-content">
              <FormInput
                required
                label={f({id: 'lastname'})}
                placeholder={f({id: 'lastname'})}
                value={nameInput}
                type="text"
                error={message === 'codeNoMatch'}
                InputProps={{
                  endAdornment: (
                    <>
                      {message === 'codeNoMatch' && (
                        <InputAdornment position="end">
                          <Warning className="red"/>
                        </InputAdornment>
                      )}
                    </>
                  )
                }}
                onChange={onNameChange}
              />
              {message === 'codeNoMatch' && (
                <span className={styles.ErrorMsg}>{f({id: message})}</span>
              )}

              <FormInput
                required
                label={f({id: 'activationCode'})}
                placeholder={f({id: 'activationCode'})}
                value={codeInput}
                type="text"
                error={message === 'wrongActivationCode' || message === 'codeAlreadyUsed'}
                InputProps={{
                  endAdornment: (
                    <>
                      {message && (
                        <InputAdornment position="end">
                          <Warning className="red"/>
                        </InputAdornment>
                      )}
                    </>
                  )
                }}
                onChange={onCodeChange}
              />

            </div>
            {['wrongActivationCode', 'codeAlreadyUsed'].includes(message) && (
              <span className={styles.ErrorMsg}>{f({id: message})}</span>
            )}
            <div className={styles.Helper}>
              <InfoIcon className={styles.Icon}/>
              <span>{f({id: 'activationCodeHelper'})}</span>
            </div>

          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ActivationCodeInput;
