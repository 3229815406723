import React from 'react';
import {connect} from 'react-redux';
import {FormattedNumber, injectIntl} from 'react-intl';
import {get, set, map, reduce} from 'lodash';
import axios from 'axios';
import FormControl from '@material-ui/core/FormControl';
import {Link} from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ArrowBack from '@material-ui/icons/ArrowBack';

import InfoCard from './InfoCard/info-card';
import {fetchAvailablePlans, fetchWallet, setCommand} from '../../../../actions/payment';
import BlueButton from '../../../blue-button';
import WhiteButton from '../../../white-button';
import SmartBreadCrumbs from '../../../shared/breadcrumbs-smart';
import styles from '../SupplyAccount/supply-account.module.scss';
import {getFriendPlansAboutPlansQuantity} from '../../../../utils/plans';

const availableLang = {
  en: 'en_US',
  fr: 'fr_FR'
};

class BuyDay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      command: undefined,
      commandSet: false,
      token: '',
      plans: [],
      amount: 0,
      friends: [],
      current: [],
      infoVisible: false,
      data: {}
    };

    this.getPlanLabel = row => {
      const {lang} = this.props;
      const label = get(row, `lang[${availableLang[lang]}]`, row.label);

      return label.length > 0 ? label : row.label;
    };

    this.getPlanInformation = row => {
      const {lang} = this.props;
      const info = get(row, `informations[${availableLang[lang]}]`, null);
      return info;
    };

    async function calculatePriceWithDegression(plan, customerID) {
      const buyDetails = {
        PUTC: plan.PUTC,
        article: plan._id,
        plan: plan.label,
        qty: plan.qty,
        total: plan.total
      };

      const {data} = await axios.post(`/api/proxy/si/customers/${customerID}/buy`, JSON.stringify({items: [buyDetails], simulate: true}), {
        withCredentials: true
      });
      return data.PTTC;
    }

    /**
     * Return real price of all plans in an array
     * @param {Array<Object>} plans List of plans
     * @param {String} customerID ID of the customer
     */
    this.calculatePricePlansWithDegression = async (plans, customerID) => {
      const result = [];
      for (const element of plans) {
        if (element.qty > 0) {
          result.push(await calculatePriceWithDegression(element, customerID)); // eslint-disable-line no-await-in-loop
        } else {
          result.push(0);
        }
      }

      return result;
    };

    this.updateQuantity = (event, index) => {
      const {plans} = this.state;
      const value = parseInt(event);
      set(plans, `[${index}].qty`, value);

      // Calculate friends of the selected plans
      const newFriends = getFriendPlansAboutPlansQuantity(plans);

      this.setState({friends: [...newFriends]});

      const price = get(plans, `[${index}].PUTC`, 0);

      // Get the proper total about degression
      calculatePriceWithDegression(plans[index], this.state.user.customerID)
        .then(priceWithDegression => {
          set(plans, `[${index}].total`, priceWithDegression);
        })
        .catch(error => {
          set(plans, `[${index}].total`, value * price);
          console.error(error);
        })
        .finally(() => {
          this.setState({plans, current: plans});
          this.calcAmount(plans);
        });
    };

    this.handleClose = () => {
      this.setState({infoVisible: false, data: {}});
    };

    this.openInfo = (title, informations) => {
      this.setState({infoVisible: true, data: {title, informations}});
    };

    this.calcAmount = plans => {
      const total = reduce(
        plans,
        (acc, {total}) => {
          return total ? acc + total : acc + 0;
        },
        0
      );
      this.setState({amount: total});
    };

    this.validateCommand = () => {
      const total = this.state.amount;
      const details = reduce(this.state.current, (acc, item) => {
        if (item.qty) {
          acc.push({
            qty: item.qty,
            plan: this.getPlanLabel(item),
            article: item._id,
            PUTC: item.PUTC,
            total: item.qty * item.PUTC
          });
        }

        return acc;
      }, []);
      const command = {
        type: 'buy',
        total,
        details
      };
      this.props.setCommand(command);
      this.props.onUpdate('multimedia/paymentValidation');
    };
  }

  componentDidUpdate(props, state) {
    if (props.user && props.user._id !== get(this.state, 'user._id')) {
      this.setState({user: props.user});
    }

    if (props.token !== this.state.token) {
      this.setState({token: props.token});
    }

    if (
      props.plans &&
      props.plans !== get(state, 'plans', []) && !this.state.commandSet || (props.plans && props.command && this.state.amount !== props.command.total && !this.state.commandSet)
    ) {
      if (props.plans.length > 0 && props.command) {
        this.setState({commandSet: true});
        const {command} = props;
        if (command.details) {
          const newPlans = map(props.plans, (plan, index) => {
            let value = {...plan};
            command.details.forEach(({qty, article, PUTC}) => {
              if (article === plan._id) {
                value = {...value, qty, total: qty * PUTC};
                this.setState({friends: [...get(props.plans, `[${index}.friends]`), get(props.plans, `[${index}._id]`)]});
              }
            });
            return value;
          });

          // Apply degression
          this.calculatePricePlansWithDegression(newPlans, this.state.user.customerID)
            .then(pricesInArray => {
              pricesInArray.forEach((value, index) => set(newPlans, `[${index}].total`, value));
            })
            .catch(error => {
              console.error(error);
            })
            .finally(() => {
              this.setState({
                plans: newPlans,
                current: newPlans
              });
              this.calcAmount(newPlans);
            });
        } else {
          this.setState({
            plans: props.plans
          });
        }
      } else {
        this.setState({
          plans: props.plans
        });
      }
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;
    if (nextProps.user) {
      newProps = newProps ?
        {...newProps, user: nextProps.user} :
        {user: nextProps.user};
    }

    if (nextProps.token) {
      newProps = newProps ?
        {...newProps, token: nextProps.token} :
        {token: nextProps.token};
    }

    if (nextProps.plans) {
      newProps = newProps ?
        {...newProps, plans: nextProps.plans} :
        {plans: nextProps.plans};
    }

    if (nextProps.command) {
      newProps = newProps ?
        {...newProps, command: nextProps.command} :
        {command: nextProps.command};
    }

    return newProps;
  }

  async fetchWalletData(customerID, token) {
    const wallet = await this.props.fetchWallet({customerID, token});
    this.setState({
      wallet: wallet.data || 0
    });
  }

  componentDidMount() {
    if (sessionStorage.getItem('command-step')) {
      sessionStorage.removeItem('command-step');
    }

    if (this.state.user) {
      const {customerID} = this.state.user;
      const {token} = this.state;
      this.fetchWalletData(customerID, token);
      this.props.fetchAvailablePlans({customerID, token});
    }
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    const {amount} = this.state;
    const plans = this.state.current.length > 0 ? this.state.current : this.state.plans;

    const handleChange = (event, index) => {
      const value = event.target.value ? event.target.value : 0;
      this.updateQuantity(value, index);
    };

    const isDisabled = id => {
      const hasAlmostQuantity = this.state.plans.find(plan => plan._id === id)?.qty > 0;
      return Boolean(this.state.amount && !this.state.friends.includes(id) && !hasAlmostQuantity);
    };

    const links = [
      {path: '/multimedia',
        text: 'multimediaBooking'},
      {path: '/multimedia/buyDay',
        text: 'buyMyPlans'}
    ];

    return (
      <>
        <div className="pageActions">
          <SmartBreadCrumbs links={links}/>
          <Link data-testid="bd-smallscreen-back" className="smallScreenBack" to="/multimedia">
            <ArrowBack/>
          </Link>
        </div>

        <div className="pageContent flex column">
          <p data-testid="bd-text1" className="text-align-left">{f({id: 'forfaitsInformations'})}</p>
          <div className="bold flex alignCenter">
            <InfoOutlinedIcon className="card"/> {f({id: 'friendsPlans'})}
          </div>
          <p data-testid="bd-text2" className="text-align-left">
            {f({id: 'noEnoughNote'})} <FormattedNumber value={get(this.state, 'wallet', 0)} style="currency" currency={this.props.locale === 'en' ? 'GBP' : 'EUR'}/>{f({id: 'noEnoughNote2'})}
          </p>
          <TableContainer data-testid="bd-table" component={Paper}>
            <Table className="table" aria-label="customized table">
              <TableHead>
                <TableRow className="lightblue">
                  <TableCell>{f({id: 'planTitle'})}</TableCell>
                  <TableCell align="right">{f({id: 'planPriceLabel'})}</TableCell>
                  <TableCell align="right">{f({id: 'quantityTitle'})}</TableCell>
                  <TableCell align="right">{f({id: 'ITamountOfLabel'})}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plans.map((row, index) => (
                  <TableRow key={`row-${row._id}`}>
                    <TableCell component="th" scope="row">
                      <div className={styles.CellContent}>
                        {this.getPlanLabel(row)}
                        {row.informations && this.getPlanInformation(row) && (
                          <Tooltip className="tooltipIcon" title={this.getPlanInformation(row)} onClick={() => this.openInfo(this.getPlanLabel(row), this.getPlanInformation(row))}>
                            <InfoIcon/>
                          </Tooltip>
                        )}
                      </div>

                    </TableCell>
                    <TableCell align="right">
                      <FormattedNumber value={row.PUTC} style="currency" currency={this.props.locale === 'en' ? 'GBP' : 'EUR'}/>
                    </TableCell>
                    <TableCell align="right">
                      <FormControl>
                        <Input
                          key={`row-${index}`}
                          data-testid={`bd-input-${index}`}
                          className="small"
                          id={`quantity-${index}`}
                          type="number"
                          inputProps={{min: 0, max: row.type === 'article' ? 1 : 999, style: {minWidth: 66}}}
                          placeholder="0"
                          value={row.qty || 0}
                          disabled={isDisabled(row._id)}
                          onChange={e => handleChange(e, index)}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">
                      <FormattedNumber value={row.total || 0} style="currency" currency={this.props.locale === 'en' ? 'GBP' : 'EUR'}/>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <p data-testid="bd-text3" className="text-align-right">
            {f({id: 'ITamountTotalOf'})}{' '}
            <span data-testid="bd-text4" className="wallet bold">
              <FormattedNumber value={amount} style="currency" currency={this.props.locale === 'en' ? 'GBP' : 'EUR'}/>
            </span>
          </p>
          <span className={styles.InfoMsg}>{f({id: 'PTTCnote'})}</span>
          <div className={styles.Actions}>
            <WhiteButton
              data-testid="bd-button1"
              id="validate"
              variant="contained"
              onClick={() => this.props.onUpdate('multimedia')}
            >
              {f({id: 'previous'})}
            </WhiteButton>
            <BlueButton
              data-testid="bd-button2"
              id="validate"
              variant="contained"
              disabled={!plans || !plans.some(plan => plan.qty > 0)}
              onClick={this.validateCommand}
            >
              {f({id: 'next'})}
            </BlueButton>
          </div>
        </div>
        <InfoCard
          data={this.state.data}
          visible={this.state.infoVisible}
          onClose={this.handleClose}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    plans: get(state, 'payment.plans'),
    user: get(state, 'user.user'),
    token: get(state, 'user.token'),
    command: get(state, 'payment.command'),
    locale: get(state, 'default.locale')
  };
};

export default injectIntl(
  connect(mapStateToProps, {fetchAvailablePlans, fetchWallet, setCommand})(BuyDay)
);
