import React from 'react';
import {connect} from 'react-redux';
import {find, get} from 'lodash';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';

import {fetchHospitals, getHospitalById} from '../actions';
import HospitalCard from './hospital-card';

import '../styles/main.scss';

class HospitalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitals: []
    };
    this.selectHospital = e => {
      const id = e;
      this.hospital = find(
        this.state.hospitals,
        hospital => hospital._id === id
      );
      this.props.getHospitalById(this.hospital._id);
      this.props.onHospitalSelect({...this.hospital, from: 'group'});
    };
  }

  static propTypes = {
    getHospitalById: PropTypes.func.isRequired,
    onHospitalSelect: PropTypes.func.isRequired,
    hospitals: PropTypes.array,
    fetchHospitals: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hospitals && get(nextProps, 'hospitals', []).length !== get(prevState, 'hospitals', []).length) {
      return {hospitals: nextProps.hospitals};
    }

    return null;
  }

  componentDidUpdate(props) {
    if (props.hospitals && props.hospitals !== this.state.hospitals) {
      this.setState({hospitals: props.hospitals});
    }
  }

  componentDidMount() {
    this.props.fetchHospitals();
  }

  render() {
    const hospitals = this.state.hospitals || [];

    return (
      <div className="page login main">
        <h2 className="withTop2"> <FormattedMessage id="selectHospital"/> </h2>
        <div className="hospitalList">
          {hospitals.map(item => (
            <HospitalCard
              key={item.hospID}
              className="card"
              logo={`/api/hospitals/${item._id}/application/logo`}
              id={item._id}
              title={item.name}
              onTap={this.selectHospital}
            />
          ))}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return ({
    hospitals: get(state, 'default.hospitals.hospitals')
  });
};

export default connect(mapStateToProps, {
  getHospitalById,
  fetchHospitals
})(HospitalList);
