import axios from 'axios';
import {
  FETCH_HOSPITALS,
  FETCH_HOSPITALS_DONE,
  FETCH_HOSPITALS_FAILED,
  GET_HOSPITAL,
  GET_HOSPITAL_DONE,
  GET_HOSPITAL_FAILED,
  GET_HOSPITALS_BY_GROUP,
  GET_HOSPITALS_BY_GROUP_DONE,
  GET_HOSPITALS_BY_GROUP_FAILED,
  SET_HOSPITAL,
  LOGOUT,
  FETCH_HOSPID, HOSPID_NOT_FOUND
} from '../actions/types';

import {fetchHospitalsDone, getHospitalDone, getHospitalsByGroupDone, updateHospital, setHospitals} from '../actions';
import {setFavicon} from '../utils';

export const httpRequestsMiddleware = store => next => action => {
  switch (action.type) {
    case LOGOUT:
      axios.post('/api/users/logout')
        .then(res => console.log('User logout :', res))
        .catch(error => console.error('Failed to logout user', error));
      break;
    case FETCH_HOSPITALS:
      axios.get('/api/hospitals')
        .then(({data}) => {
          return store.dispatch(fetchHospitalsDone(data));
        })
        .catch(error => next({type: FETCH_HOSPITALS_FAILED, payload: error}));
      break;
    case FETCH_HOSPITALS_DONE:
      next({type: FETCH_HOSPITALS_DONE});
      return store.dispatch(setHospitals(action.payload));
    case GET_HOSPITAL:
      const hospID = action.payload;
      axios.get(`/api/hospitals/${hospID}`)
        .then(({data}) => store.dispatch(getHospitalDone(data)))
        .catch(error => {
          next({type: GET_HOSPITAL_FAILED, payload: error});
        });
      next(action);
      break;
    case GET_HOSPITAL_DONE:
      next({type: GET_HOSPITAL_DONE});
      return store.dispatch(updateHospital(action.payload));
    case GET_HOSPITALS_BY_GROUP:
      const groupID = action.payload;
      axios.get(`/api/hospitals/group/${groupID}`)
        .then(({data}) => {
          return store.dispatch(getHospitalsByGroupDone(data));
        })
        .catch(error => {
          next({type: GET_HOSPITALS_BY_GROUP_FAILED, payload: error});
        });
      next(action);
      break;
    case GET_HOSPITALS_BY_GROUP_DONE:
      next({type: GET_HOSPITALS_BY_GROUP_DONE});
      return store.dispatch(setHospitals(action.payload));
    case SET_HOSPITAL:
      next(action);
      setFavicon(action.payload.hospital);
      break;
    case FETCH_HOSPID:
      if (action.payload.name.length > 0) {
        axios.get(`/api/hospitals/hospIDByName/${action.payload.name}`)
          .then(({data}) => {
            document.cookie = `hospID=${data.hospID}`;
            next({type: HOSPID_NOT_FOUND, payload: false});

            // Handle establishment change
            if (localStorage.getItem('path-establishment') !== action.payload.name) {
              action.payload.logout();
            }

            localStorage.setItem('path-establishment', action.payload.name);
          })
          .catch(error => {
            console.error(error);
            next({type: HOSPID_NOT_FOUND, payload: true});
          });
      }

      break;
    default:
      return next(action);
  }
};
