import React from 'react';
import Modal from 'react-awesome-modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styles from './info-card.module.scss';

const InfoCard = ({visible, data, onClose}) => {
  return (
    <Modal
      visible={visible}
      effect="fadeInUp"
      width="30%"
      height="20%"
      onClickAway={() => onClose()}
    >
      <div className={styles.MiniModal}>
        <div className={styles.Header}>
          <h3 data-testid="info-card-title">{data.title}</h3>
          <IconButton data-testid="info-card-button1" className={styles.CloseButton} onClick={onClose}>
            <CloseIcon/>
          </IconButton>
        </div>

        <span data-testid="info-card-text-1" className={styles.Content}>{data.informations}</span>
      </div>
    </Modal>
  );
};

export default InfoCard;
