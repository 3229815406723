import ListSurveys from './ListSurveys/list-surveys';
import SurveyPage from './SurveyPage/survey-page';

export const surveyPanel = {
  title: 'mySurveys',
  icon: 'edit',
  identity: 'fillSurvey',
  mainComponent: ListSurveys,
  pages: [{
    title: 'surveyPage',
    component: SurveyPage,
    parent: 'fillSurvey'
  }]
};

export default surveyPanel;
