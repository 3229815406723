import React from 'react';
import {Router, Switch, Route, Redirect} from 'react-router-dom';
import {withCookies} from 'react-cookie';
import {createBrowserHistory as createHistory} from 'history';
import {IntlProvider, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import IdleTimer from 'react-idle-timer';
import SocketHandler from './components/socket-handler';
import LoadingSearchHospital from './components/loading-search-hospital';
import Login from './components/authentication/Login/login';
import Signup from './components/authentication/SignUp/signup';
import ForgottenPassword from './components/authentication/ForgottenPassword/forgotten-password';
import PersonnalDataForm from './components/user/PersonnalDataForm/personal-data-form';
import ResetPassword from './components/authentication/ResetPassword/reset-password';
import NewPassword from './components/authentication/ResetPassword/new-password';
import Validation from './components/authentication/Validation/validation';
import AccountValidationPage from './components/authentication/AccountValidationPage/user-account-validation-signup';

import PropTypes from 'prop-types';
import {get, reduce, split, find} from 'lodash';
import plugins, {HomePersonalData} from './plugins';

import './app.css';

import NavigationBar from './components/navigation-bar';

import HospitalList from './components/hospital-list';
import Notifications from './components/notifications';
import LangChooser from './components/lang-chooser';
import Page from './components/page';
import Home from './components/home';

import frTranslations from './lang/fr.json';
import enTranslations from './lang/en.json';

import {
  initStoreFromStorage,
  clean,
  error,
  success,
  validateUser,
  updateHospital,
  fetchHospitals,
  getHospitalById,
  getHospitalsByGroup,
  logout,
  logoutByTimeout,
  userIsLogged,
  fetchHospID,
  fetchTvCoaxProcess,
  setTvCoaxProcess
} from './actions';
import Valid3dsTransaction from './components/valid3ds-transaction';

const translations = {
  fr: frTranslations,
  en: enTranslations
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this._initstate = {
      currentPage: '/',
      availablePages: [],
      // Hospital: {},
      hospID: undefined,
      user: {},
      token: undefined,
      whiteList: [],
      appName: '',
      tvCoaxProcess: {},
      timeOutStatus: false,
      userIsLogin: false,
      hospitalWatcher: 0,
      firstConnection: false,
      validTransactionMode: false,
      userSupply: 0
    };

    this.state = this._initstate;

    this.history = createHistory(this.props);
    this.unlisten = () => {
      this.history.listen(({pathname}) => {
        const parent = get(split(pathname, '/'), '[1]');
        this.setActualPage(parent);
      });
    };

    this.display = (page, params) => {
      this.setState({
        currentPage: page
      });
      if (params) {
        this.history.push(`/${page}`, params);
      } else {
        this.history.push(`/${page}`);
      }

      document.body.scrollTop = 0;
    };

    this.logged = noRedirect => {
      if (this.state.token) {
        localStorage.setItem('token', this.state.token);
      }

      if (this.state.user) {
        localStorage.setItem('user', this.state.user._id);
      }

      this.setState({
        userIsLogin: true,
        timeOutStatus: false
      });

      localStorage.setItem(
        'userIsLogin',
        JSON.stringify(this.state.userIsLogin)
      );
      if (!noRedirect) {
        if (get(this.state, 'user.isFilled')) {
          this.display('personalHome');
        } else {
          this.setState({firstConnection: true});
          this.display('personalData/edit');
        }
      }

      this.props.userIsLogged();
    };

    this.logout = noRedirect => {
      //    LocalStorage.removeItem('hospital');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('userIsLogin');
      this.props.logout();

      if (!noRedirect) {
        if (this.state.timeOutStatus) {
          this.setState({
            user: {},
            token: undefined,
            userIsLogin: false
          });
        } else {
          this.props.clean();
          this.setState(this._initstate);
        }

        this.display('logout');
      }
    };

    this.signup = () => {
      this.display('login');
    };

    this.activatePassword = () => {
      this.display('resetPassword');
    };

    this.changePassword = () => {
      this.display('reinitPassword');
    };

    this.setActualPage = page => {
      this.setState({currentPage: page});
    };

    this.redirectLogin = () => {
      this.logout();
    };

    this.validateUser = async user => {
      if (this.state.user) {
        this.logout(true);
      }

      const res = await this.props.validateUser(user);
      if (res.status === 200) {
        if (res.user && res.user.hospID) {
          document.cookie = `hospID=${res.user.hospID}`;
        }

        this.setState({wait: false});
        if (res.adminCreated) {
          if (res.isAssociated) {
            this.setState(prevState => ({
              user: {...prevState.user, isFilled: true}
            }));
            this.display('personalData/myInformations');
          } else {
            this.setState({
              firstConnection: true
            });
            this.display('account-validation');
          }
        } else if (res.isAssociated) {
          this.setState(prevState => ({
            user: {...prevState.user, isFilled: true}
          }));
          this.display('personalData/myInformations');
        } else {
          this.setState({
            firstConnection: true
          });
          this.display('personnalDataForm');
        }

        this.logged(true);
      } else {
        this.setState({wait: false});

        if (res.status === 401) {
          this.logout(true);
          this.display('validation', user);
        }

        if (res.status === 403) {
          if (res.data?.hasPassword) {
            this.logout(true);
            this.display('validation', false);
          } else {
            this.setState(prevState => ({
              user: {...prevState.user, ...res.data}
            }));
            this.display('account-validation');
            this.logged(true);
          }
        } else {
          this.logout(true);
          this.props.error(<FormattedMessage id={res.msg}/>);
        }
      }
    };
  }

  static propTypes = {
    clean: PropTypes.func.isRequired,
    validateUser: PropTypes.func.isRequired,
    error: PropTypes.func.isRequired,
    initStoreFromStorage: PropTypes.func.isRequired,
    hospital: PropTypes.object,
    user: PropTypes.object,
    token: PropTypes.string,
    whiteList: PropTypes.array,
    locale: PropTypes.string.isRequired
  };

  componentDidMount() {
    const page = get(this.history, 'location.pathname');
    if (page && page.includes('/3ds')) {
      this.setState({validTransactionMode: true});
      return;
    }

    this.props.initStoreFromStorage();
    const hash = get(this.history, 'location.hash');

    if (hash && hash.match(/^#\/validation\/([a-f\d]{24})$/)) {
      const user = hash.match(/^#\/validation\/([a-f\d]{24})$/)[1];

      // Clear current hospID cookie (avoid bad hospID)
      document.cookie = 'hospID=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

      this.setState({wait: true});
      this.validateUser(user);
    } else if (page) {
      const actualPage = get(split(page, '/'), '[1]');
      this.setActualPage(actualPage);
      this.props.fetchHospID({name: actualPage, logout: this.logout});
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidUpdate(props) {
    if (this.state.validTransactionMode) {
      return;
    }

    if (get(this.props, 'allCookies.hospID') && get(this.props, 'allCookies.hospID') !== get(this.state, 'hospID')) {
      const cookie = get(this.props, 'allCookies.hospID');
      this.props.getHospitalById(cookie);
      this.setState({hospID: cookie});
    }

    if (this.props.hospital && this.props.hospital.hospID !== get(props, 'hospital.hospID')) {
      this.setState({hospital: props.hospital});

      if (this.state.currentPage !== 'signup') {
        this.display('login', this.props.hospital);
      }

      if (this.props.hospital.hospID) {
        fetchTvCoaxProcess({hospID: this.props.hospital.hospID, token: this.state.token})
          .then(this.props.setTvCoaxProcess)
          .catch(error_ => console.error(error_));
      }
    }

    if (this.props.getHospitalDone !== props.getHospitalDone) {
      if (!this.props.hospital && this.state.currentPage !== 'signup') {
        this.display('login', this.props.hospital);
      }
    }

    if (props.user && props.user._id !== get(this.state, 'user._id')) {
      this.setState({user: props.user});
    }

    if (props.token !== this.state.token) {
      this.setState({token: props.token});
    }

    if (props.hospitalWatcher && props.hospitalWatcher !== this.state.hospitalWatcher) {
      this.setState({hospitalWatcher: props.hospitalWatcher});
    }

    if (props.whiteList && props.whiteList.length !== get(this.state, 'whiteList', []).length) {
      this.setState({whiteList: props.whiteList});
    }

    if (this.props.getHospitalError && props.getHospitalError !== this.props.getHospitalError) {
      this.props.error(
        <FormattedMessage id={this.props.getHospitalError || 'error'}/>
      );
    }

    if (this.props.getHospitalsByGroupError && props.getHospitalsByGroupError !== this.props.getHospitalsByGroupError) {
      this.props.error(
        <FormattedMessage id={this.props.getHospitalsByGroupError || 'error'}/>
      );
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;

    if (nextProps.hospital) {
      newProps = newProps ?
        {...newProps, hospital: nextProps.hospital} :
        {hospital: nextProps.hospital};
    }

    if (nextProps.user) {
      newProps = newProps ?
        {...newProps, user: nextProps.user} :
        {user: nextProps.user};
    }

    if (nextProps.token) {
      newProps = newProps ?
        {...newProps, token: nextProps.token} :
        {token: nextProps.token};
    }

    if (nextProps.getHospitalDone) {
      newProps = newProps ?
        {...newProps, getHospitalDone: nextProps.getHospitalDone} :
        {getHospitalDone: nextProps.getHospitalDone};
    }

    if (nextProps.applicationName) {
      newProps = newProps ?
        {...newProps, appName: nextProps.applicationName} :
        {appName: nextProps.applicationName};
    }

    if (nextProps.tvCoaxProcess) {
      newProps = newProps ?
        {...newProps, tvCoaxProcess: nextProps.tvCoaxProcess} :
        {tvCoaxProcess: nextProps.tvCoaxProcess};
    }

    if (nextProps.whiteList) {
      newProps = newProps ?
        {...newProps, whiteList: nextProps.whiteList} :
        {whiteList: nextProps.whiteList};
    }

    return newProps;
  }

  render() {
    const chooser = <LangChooser/>;
    const whiteListedPlugins = reduce(
      plugins,
      (acc, plugin) => {
        const authorized = find(this.state.whiteList, item => {
          return item === plugin.identity;
        });
        if (authorized) {
          acc.push(plugin);
        }

        return acc;
      },
      []
    );
    const isConditionInWhiteList = condition => {
      const authorized = find(this.state.whiteList, item => {
        return item === condition;
      });
      return authorized;
    };

    const userIsLogin = localStorage.getItem('userIsLogin');

    const pluginsPagesRoutes = reduce(
      whiteListedPlugins,
      (acc, plugin) => {
        plugin.pages.forEach(page => {
          if (page.condition) {
            if (isConditionInWhiteList(page.condition)) {
              acc.push(page);
            }
          } else {
            acc.push(page);
          }
        });
        return acc;
      },
      []
    );

    const isFilled =
      this.state.user && userIsLogin ? this.state.user.isFilled : false;
    const hospID = this.state.hospital ? this.state.hospital.hospID : null;

    // Count idling time
    const maxIdlingTime = window._env_.MAX_IDLING_TIME;
    let idleTimer = null;

    const onAction = () => {
      if (
        this.state.timeOutStatus &&
        (this.state.currentPage === 'login' ||
          this.state.currentPage === 'home')
      ) {
        return;
      }

      this.setState({
        timeOutStatus: false
      });
    };

    const onActive = () => {
      if (
        this.state.timeOutStatus &&
        (this.state.currentPage === 'login' ||
          this.state.currentPage === 'home')
      ) {
        return;
      }

      this.setState({
        timeOutStatus: false
      });
    };

    const onIdle = () => {
      if (
        this.state.currentPage === 'login' ||
        this.state.currentPage === 'home' ||
        this.state.currentPage === '3ds'
      ) {
        return;
      }

      idleTimer.reset();
      this.props.logoutByTimeout();
      this.setState({timeOutStatus: true});
      this.logout();
    };

    return (
      <>
        <IdleTimer
          ref={ref => {
            idleTimer = ref;
          }}
          element={document}
          debounce={250}
          timeout={maxIdlingTime}
          onActive={onActive}
          onIdle={onIdle}
          onAction={onAction}
        />
        <IntlProvider
          locale={this.props.locale}
          messages={translations[this.props.locale]}
        >
          <div className="App">
            <Notifications/>
            { !this.state.hospital && !this.state.validTransactionMode && <LoadingSearchHospital/>}
            <Router history={this.history}>
              {!this.state.wait && (
                <>

                  <nav>
                    <NavigationBar
                      title={this.state.appName}
                      langChooser={chooser}
                      hospID={hospID}
                      isVisible={isFilled}
                      firstname={get(this.state, 'user.firstname')}
                      lastname={get(this.state, 'user.lastname')}
                      user={this.state.user}
                      whiteList={this.state.whiteList}
                      plugins={whiteListedPlugins}
                      actual={this.state.currentPage}
                      hiddenIfClosed={['multimedia']}
                      hospitalWatcher={this.state.hospitalWatcher}
                      onLogout={() => this.logout()}
                      onTimeout={this.props.logoutByTimeout}
                      onUpdate={this.display}
                    />
                  </nav>
                  <Switch>
                    {get(this.state, 'hospital.hospID') && (
                      <>

                        {get(this.state, 'token') ? (
                          <>
                            <Route exact path="/">
                              <Redirect to="/personalHome"/>
                            </Route>
                            <Route path="/hospital/">
                              <Redirect to="/personalHome"/>
                            </Route>
                            <Route path="/personalHome">
                              <>
                                <CssBaseline/>
                                {get(this.state, 'user.isFilled') ?
                                  (
                                    <>
                                      <Hidden smUp>
                                        <Home
                                          pages={whiteListedPlugins}
                                          user={{
                                            firstname: get(this.state, 'user.firstname'),
                                            lastname: get(this.state, 'user.lastname')
                                          }}
                                          hospID={get(this.state, 'hospital.hospID')}
                                          onUpdate={e => this.display(e)}
                                        />
                                      </Hidden>
                                      <Hidden xsDown>
                                        <Page>
                                          <HomePersonalData
                                            lang={this.props.locale}
                                            token={this.state.token}
                                            user={this.props.user}
                                            hospital={this.state.hospital}
                                            applicationName={this.state.appName}
                                            tvCoaxProcess={this.state.tvCoaxProcess}
                                            authorized={this.state.whiteList}
                                            onPageSelect={e => this.display(e)}
                                          />
                                        </Page>
                                      </Hidden>
                                    </>
                                  ) : (
                                    <Redirect to="/personnalDataForm"/>
                                  )}

                              </>
                            </Route>
                            <Route path="/personnalDataForm">
                              <PersonnalDataForm
                                lang={this.props.locale}
                                className={this.state.knownUser ? 'menuOpen' : ''}
                                user={this.props.user}
                                appName={this.state.appName}
                                tvCoaxProcess={this.state.tvCoaxProcess}
                                isFirstConnection={this.state.firstConnection || false}
                                onUpdate={e => this.display(e)}
                              />
                            </Route>

                            {pluginsPagesRoutes.map((page, index) => (
                              <Route
                                key={`plugin-page-route-${index}`}
                                path={`/${page.parent}/${page.title}`}
                              >
                                <Page>
                                  {React.createElement(page.component, {
                                    lang: this.props.locale,
                                    token: this.state.token,
                                    user: this.props.user,
                                    hospital: this.state.hospital,
                                    applicationName: this.state.appName,
                                    isFirstConnection: this.state.firstConnection,
                                    authorized: this.state.whiteList,
                                    userSupply: this.state.userSupply,
                                    onUpdate: e => this.display(e),
                                    onUpdateUserSupply: value => this.setState({userSupply: value})
                                  })}
                                </Page>
                              </Route>
                            ))}

                            {whiteListedPlugins.map((plugin, index) => (
                              <Route
                                key={`plugin-route-${index}`}
                                exact
                                path={`/${plugin.identity}`}
                              >
                                <Page>
                                  {React.createElement(plugin.mainComponent, {
                                    token: this.state.token,
                                    lang: this.props.locale,
                                    user: this.props.user,
                                    onPageSelect: (page, params) => {
                                      this.display(page, params);
                                    },
                                    authorized: this.state.whiteList
                                  })}
                                </Page>
                              </Route>
                            ))}

                            <Route path="/reinitPassword">
                              <NewPassword
                                lang={this.props.locale}
                                onUpdate={() => this.redirectLogin()}
                              />
                            </Route>

                            <Route path="/validation">
                              <Validation
                                lang={this.props.locale}
                                hospID={get(this.state, 'hospital.hospID')}
                                history={this.history}
                                success={this.props.success}
                                error={this.props.error}
                                onUpdate={() => this.display('login')}
                              />
                            </Route>
                            <Route path="/account-validation">
                              <AccountValidationPage
                                lang={this.props.locale}
                                hospID={get(this.state, 'hospital.hospID')}
                                history={this.history}
                                success={this.props.success}
                                error={this.props.error}
                                user={this.state.user}
                                appName={this.state.appName}
                                hasMultimedia={Boolean(
                                  get(this.state, 'hospital.modules[0].activated')
                                )}
                                onUpdate={page => this.display(page)}
                              />
                            </Route>

                            <Route path="/login">
                              <Redirect to="/personalHome"/>
                            </Route>
                            <Route exact path="/resetPassword">
                              <ResetPassword
                                lang={this.props.locale}
                                onSuccess={() => this.changePassword()}
                              />
                            </Route>
                            <Route exact path="/changepassword">
                              <ForgottenPassword
                                lang={this.props.locale}
                                hospID={get(this.state, 'hospital.hospID')}
                                onSuccess={() => this.activatePassword()}
                                onUpdate={e => this.display(e)}
                              />
                            </Route>
                          </>
                        ) : (
                          <>
                            <Route path="/login">
                              <Login
                                isLogoutByTimeout={this.props.isLogoutByTimeout}
                                lang={this.props.locale}
                                hospID={this.state.hospital.hospID}
                                source={this.history}
                                onLogin={() => this.logged()}
                              />
                            </Route>
                            <Route exact path="/signup">
                              <Signup
                                history={this.history}
                                lang={this.props.locale}
                                hospital={this.props.hospital}
                                hospID={get(this.state, 'hospital.hospID')}
                                appName={this.state.appName}
                                hasMultimedia={Boolean(
                                  get(this.state, 'hospital.modules[0].activated')
                                )}
                                onSignup={() => this.signup()}
                              />
                            </Route>
                            <Route exact path="/resetPassword">
                              <ResetPassword
                                lang={this.props.locale}
                                onSuccess={() => this.changePassword()}
                              />
                            </Route>
                            <Route exact path="/changepassword">
                              <ForgottenPassword
                                lang={this.props.locale}
                                hospID={get(this.state, 'hospital.hospID')}
                                onSuccess={() => this.activatePassword()}
                                onUpdate={e => this.display(e)}
                              />
                            </Route>
                            <Route path="/choseHospital">
                              <HospitalList
                                onHospitalSelect={e => this.display('login', e)}
                              />
                            </Route>
                            <Route path="/validation">
                              <Validation
                                lang={this.props.locale}
                                hospID={get(this.state, 'hospital.hospID')}
                                history={this.history}
                                success={this.props.success}
                                error={this.props.error}
                                onUpdate={() => this.display('login')}
                              />
                            </Route>
                            <Route path="/account-validation">
                              <AccountValidationPage
                                lang={this.props.locale}
                                hospID={get(this.state, 'hospital.hospID')}
                                history={this.history}
                                success={this.props.success}
                                error={this.props.error}
                                appName={this.state.appName}
                                user={this.state.user}
                                hasMultimedia={Boolean(
                                  get(this.state, 'hospital.modules[0].activated')
                                )}
                                onUpdate={page => this.display(page)}
                              />
                            </Route>
                            <Route path="/hospital/">
                              <Login
                                isLogoutByTimeout={this.props.isLogoutByTimeout}
                                lang={this.props.locale}
                                hospID={this.state.hospital.hospID}
                                source={this.history}
                                onLogin={() => this.logged()}
                              />
                            </Route>
                            <Route exact path="/">
                              <Redirect to="/login"/>
                            </Route>
                          </>
                        )}
                        <Route path="/logout">
                          <Login
                            isLogoutByTimeout={this.props.isLogoutByTimeout}
                            lang={this.props.locale}
                            hospID={this.state.hospital.hospID}
                            source={this.history}
                            onLogin={() => this.logged()}
                          />
                        </Route>
                      </>
                    )}
                  </Switch>
                </>
              )}
              <Route path="/3ds/:hospID/:transactionId">
                <Valid3dsTransaction
                  lang={this.props.locale}
                  setCurrentPage={() => this.setState({currentPage: '3ds'})}
                  disableTransactionMode={() => this.setState({validTransactionMode: false})}
                  goToSignUpPage={fromVad => this.display('signup', {fromVad})}/>
              </Route>
            </Router>
            <SocketHandler user={this.state.user} logout={this.logout}/>
          </div>
        </IntlProvider>
      </>
    );
  }
}

const mapStateToProps = state => ({
  hospital: get(state, 'default.hospital.hospital'),
  whiteList: get(state, 'default.hospital.whiteList'),
  token: get(state, 'user.token'),
  user: get(state, 'user.user'),
  locale: get(state, 'default.locale'),
  userIsLogin: get(state, 'default.userIsLogin'),
  applicationName: get(state, 'default.applicationName'),
  tvCoaxProcess: get(state, 'default.tvCoaxProcess'),
  fetchHospitalsDone: get(state, 'default.fetchHospitalsDone'),
  fetchHospitalsError: get(state, 'default.fetchHospitalsError'),
  getHospitalDone: get(state, 'default.getHospitalDone'),
  getHospitalError: get(state, 'default.getHospitalError'),
  getHospitalsByGroupDone: get(state, 'default.getHospitalsByGroupDone'),
  getHospitalsByGroupError: get(state, 'default.getHospitalsByGroupError'),
  isLogoutByTimeout: get(state, 'default.isLogoutByTimeout'),
  hospitalWatcher: get(state, 'default.hospitalWatcher'),
  hospIDNotFound: get(state, 'default.hospIDNotFound')
});
export default connect(mapStateToProps, {
  initStoreFromStorage,
  clean,
  validateUser,
  error,
  success,
  updateHospital,
  fetchHospitals,
  getHospitalById,
  getHospitalsByGroup,
  logout,
  logoutByTimeout,
  userIsLogged,
  fetchHospID,
  setTvCoaxProcess
})(withCookies(App));
