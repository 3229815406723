import React from 'react';
import BlueIconButton from '../../shared/blue-icon-button';

export default function FileCard(props) {
  const type = props.type || '';
  const {description, fileDesc, category} = props;
  const onAdd = () => {
    props.onAdd({type, description: fileDesc, category});
  };

  return (
    <BlueIconButton data-testid="fc-button1" icon="add" matchSize="600" text={description} onClick={onAdd}/>
  );
}
