import React from 'react';

// Replace "\n" to "<br/>" in order to set a new line
export const convertTextInMultilines = message => {
  const texts = message.split('\n').map((item, index) => {
    return (
      <React.Fragment key={index}>{item}<br/></React.Fragment>
    );
  });
  return <span data-testid="pdl-location-msg" style={{textAlign: 'center', marginLeft: '0.5em'}}>{texts}</span>;
};
