import React from 'react';
import {useIntl, FormattedNumber} from 'react-intl';
import moment from 'moment';

import styles from './history.module.scss';

export default function PurchaseCard(props) {
  const {formatMessage: f} = useIntl();

  const item = props.item || '';

  const formatDate = item => {
    if (item.date) {
      return moment(item.date).utcOffset(0).format('LLL');
    }

    return '/';
  };

  return (
    <div className="card">
      {item.isCommand && (
        <div className={styles.HistoryCard}>
          <span data-testid="BuyPlanCardTitle" className={styles.Title}>
            {item.PUTC > 0 ? f({id: 'commandOf'}) : f({id: 'refundCommand'})}
          </span>
          <span data-testid="BuyPlanCardDate">{formatDate(item)}</span>
          <div className={styles.HistoryLine}>
            <span data-testid="BuyPlanCardLabel">{item.PUTC > 0 ? item.label : `${f({id: 'refund'})} ${item.label}`}</span>
            <span data-testid="BuyPlanCardQtyXAmount">
              {item.qty} x  <FormattedNumber value={item.PUTC} style="currency" currency={props.locale === 'en' ? 'GBP' : 'EUR'}/>
            </span>
          </div>

          <span data-testid="purchase-card-text4" className={styles.HistoryLineBold}>
            <span data-testid="purchase-card-text5">{f({id: 'amount'})}</span>
            <span data-testid="BuyPlanCardTotal"><FormattedNumber value={item.PTTC} style="currency" currency={props.locale === 'en' ? 'GBP' : 'EUR'}/></span>
          </span>
        </div>
      )}
      {item.isTransaction && (
        <div data-testid="TransactionCard" className={styles.HistoryCard}>
          <span data-testid="TransactionCardTitle" className={styles.Title}>
            { item.amount > 0 ? f({id: 'walletSupplyOf'}) : f({id: 'walletRefund'})}
          </span>
          <span data-testid="TransactionCardDate"> {formatDate(item)}</span>
          <span data-testid="purchase-card-text8" className={styles.HistoryLineBold}>
            <span data-testid="purchase-card-text9">{f({id: 'amount'})}</span>
            <span data-testid="TransactionCardAmount"><FormattedNumber value={item.amount} style="currency" currency={props.locale === 'en' ? 'GBP' : 'EUR'}/></span>
          </span>
        </div>
      )}
    </div>
  );
}
