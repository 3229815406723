import React, {useState, useEffect} from 'react';

import {Modal, Button, Breadcrumbs} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useDispatch, useSelector} from 'react-redux';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import {useIntl} from 'react-intl';
import {Link} from 'react-router-dom';

import BlueIconButton from '../../shared/blue-icon-button';
import {clearOutsideClick} from '../../../actions/preadmission';
import styles from './header.module.scss';

const primaryColor = '#2196F3';
const secondaryColor = '#0069d9';
const thirtyColor = '#0062cc';

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

const BlueButton = withStyles({
  root: {
    backgroundColor: primaryColor,
    border: `2px solid ${primaryColor}`,
    color: 'white',
    margin: '0.5em',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: secondaryColor,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: thirtyColor
    },
    '&:disabled': {
      backgroundColor: '#f1f1f1'
    }
  }
})(Button);

const WhiteButton = withStyles({
  root: {
    border: `2px solid ${primaryColor}`,
    color: primaryColor,
    margin: '0.5em',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#f1f1f1',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none'
    }
  }
})(Button);

const Header = ({display, current, matchSize, setCurrent, date, save, finished}) => {
  const matches = useMediaQuery(`(min-width:${matchSize}px)`);
  const outsideClik = useSelector(state => state.preadmission.openDialog);
  const redirect = useSelector(state => state.preadmission.redirect);
  const {formatMessage: f} = useIntl();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (display) {
      if (outsideClik) {
        if (current && !finished) {
          setOpen(true);
        } else {
          setCurrent(null);
          dispatch(clearOutsideClick());
          display(redirect);
        }
      }
    }
  }, [outsideClik]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal
        effect="fadeInUp"
        width="350"
        height="200"
        open={open}
        onClose={handleClose}
      >
        <div className={classes.paper}>
          <div className={styles.SimpleCard}>
            <div className={styles.TextCard}>
              <h2> {f({id: 'preadmissionLeavingTitle'})}</h2>
              <span className={styles.Text}>
                {f({id: 'preadmissionLeavingNote'})}
              </span>
              {date && (
                <span className={styles.Text}>
                  {f({id: 'preadmissionLeavingNoteDueDate'})} {date}.
                </span>
              )}
            </div>
            <div
              className={`${
                matches ? styles.ModalActions : styles.MobileModalActions
              }`}
            >
              <WhiteButton
                onClick={() => {
                  handleClose();
                  dispatch(clearOutsideClick());
                }}
              >
                {f({id: 'cancel'})}
              </WhiteButton>
              {!redirect ? (
                <BlueButton
                  onClick={() => {
                    handleClose();
                    setCurrent(null);
                  }}
                >
                  {f({id: 'doNotSave'})}
                </BlueButton>
              ) : (
                <BlueButton
                  onClick={() => {
                    handleClose();
                    setCurrent(null);
                    dispatch(clearOutsideClick());
                    display(redirect);
                  }}
                >
                  {f({id: 'doNotSave'})}
                </BlueButton>
              )}
              <BlueButton
                onClick={() => {
                  save();
                  setCurrent(null);
                  handleClose();
                }}
              >
                {f({id: 'save'})}
              </BlueButton>
            </div>
          </div>
        </div>
      </Modal>

      {!matches && current ? (
        <>
          <div className="pageActions">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                to="/preadmission"
                onClick={() => setOpen(finished ? null : true)}
              >
                {f({id: 'myPreadmission'})}
              </Link>
              <Link color="textPrimary" to="/preadmission" aria-current="page">
                {current.identity}
              </Link>
            </Breadcrumbs>
            <Link
              className="smallScreenBack"
              to="/preadmission"
              onClick={() => setOpen(finished ? null : true)}
            >
              <ArrowBack/>
            </Link>
          </div>
        </>
      ) : (
        <>
          {!current ? (
            <h1 className={`${current ? styles.Title : 'headerTitle'}`}>
              {f({id: 'myPreadmission'})}
            </h1>
          ) : (
            <>
              <Breadcrumbs
                aria-label="breadcrumb"
                style={{marginBottom: '1em'}}
              >
                <Link
                  color="inherit"
                  to="/preadmission"
                  onClick={() => setOpen(finished ? null : true)}
                >
                  {f({id: 'myPreadmission'})}
                </Link>
                <Link
                  color="textPrimary"
                  to="/preadmission"
                  aria-current="page"
                >
                  {finished ? f({id: 'displayOf'}) : ''} {current.identity}
                </Link>
              </Breadcrumbs>
              <BlueIconButton
                icon="arrow_back"
                matchSize="600"
                text={f({id: 'back'})}
                style={{marginBottom: '2em'}}
                onClick={() => setOpen(finished ? null : true)}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Header;
