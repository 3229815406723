import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const primaryColor = '#2196F3';
export const thirtyColor = '#90caf9';

export default withStyles({
  root: {
    backgroundColor: 'white',
    border: `1px solid ${primaryColor}`,
    borderRadius: '5px',
    fontWeight: 'bold',
    color: primaryColor,
    minWidth: '8em',
    margin: '0.5em',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: primaryColor,
      borderColor: '#007bff',
      color: 'white',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: thirtyColor,
      borderColor: '#005cbf'
    },
    '&:disabled': {
      border: 'none'
    }
  }
})(Button);
