import React, {useState} from 'react';
import {LinearProgress} from '@material-ui/core';

import FileUploader from '../Uploader/uploader';

export default function UploaderForm({fileData, input, index, updateFiles, user, section, disabled}) {
  const [uploadProgress, setUploadProgress] = useState(false);
  const [file, setFile] = useState();

  const fileReader = new FileReader();
  fileReader.addEventListener('load', async evt => {
    setUploadProgress(false);
    setFile({file: fileReader.result});

    updateFiles(index, {file: fileReader.result, name: evt.target.fileName, fileType: evt.target.fileType});
  });

  const onFileChange = file => {
    if (file) {
      setUploadProgress(true);
      fileReader.fileName = file.name;
      fileReader.fileType = file.type;
      fileReader.readAsDataURL(file);
    } else {
      updateFiles(index, null);
    }
  };

  return (
    <div>
      <FileUploader
        types={['image/png', 'image/jpeg', 'application/pdf']}
        label={input.title}
        note={input.description}
        fileLimit={1024000}
        fileData={file || fileData}
        disabled={disabled}
        user={user}
        section={section}
        index={index}
        onAddFile={file => onFileChange(file)}
      />
      {uploadProgress && <LinearProgress/>}
    </div>
  );
}
