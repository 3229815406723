import React from 'react';
import {Link} from 'react-router-dom';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBack from '@material-ui/icons/ArrowBack';

import InputAdornment from '@material-ui/core/InputAdornment';
import Warning from '@material-ui/icons/Error';

import {resetPassword} from '../../../actions/authentication';
import {error} from '../../../actions';
import FormInput from '../../shared/FormInput/form-input';

import styles from './reset-password.module.scss';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      resetCode: '',
      message: null
    };

    this.isValid = () => {
      if (!this.state.resetCode.length) {
        return true;
      }

      return false;
    };

    this.validate = async () => {
      const response = await this.props.resetPassword({
        passwordToken: this.state.resetCode
      });
      if (response.result === 'ERROR') {
        this.props.error(this.props.intl.formatMessage({id: response.msg || 'serverError'}));
        if (response.msg === 'wrongCode') {
          this.setState({message: response.msg});
        } else {
          this.setState({message: null});
        }
      } else if (response.status === 200) {
        this.setState({message: null});
        this.props.onSuccess();
      }
    };
  }

  handleCode(event) {
    if (event) {
      this.setState({resetCode: event.target.value});
    }
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    return (
      <div className="main page login">
        <div className="pageActions withTop">
          <Link data-testid="rp-back" className="backButton" to="/login">
            <ArrowBack/> <span data-testid="rp-title" className="label">{f({id: 'return'})}</span>
          </Link>
        </div>
        <div className="pageContent flex column">
          <div className="header">
            <h3 data-testid="rp-text1">{f({id: 'resetCode'})}</h3>
            <span data-testid="rp-text2"> {f({id: 'enterResetCode'})} </span>
          </div>

          <div className="loginForm self-center width30">
            <form noValidate className="root marginTop" autoComplete="off">
              <FormInput
                data-testid="rp-input1"
                placeholder={f({id: 'resetCode'})}
                label={f({id: 'resetCode'})}
                type="text"
                id="resetCode"
                value={this.state.resetCode}
                error={this.state.message === 'wrongCode'}
                InputProps={{
                  endAdornment: (
                    <>
                      {this.state.message === 'wrongCode' && (
                        <InputAdornment position="end">
                          <Warning className="red"/>
                        </InputAdornment>
                      )}
                    </>
                  )
                }}
                onChange={event => this.handleCode(event)}
              />
              {this.state.message === 'wrongCode' && (
                <span className={styles.ErrorMsgStart}>
                  {f({id: 'wrongCode'})}
                </span>
              )}
              <br/>

              <Button
                data-testid="rp-button1"
                id="validate"
                className="flat blue"
                disabled={this.isValid()}
                onClick={this.validate}
              >
                {f({id: 'validate'})}
              </Button>
            </form>
            {this.state.loading && <CircularProgress className="loading"/>}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(
  connect(null, {
    resetPassword,
    error
  })(ResetPassword)
);
