import React from 'react';
import {useIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from '@material-ui/core';

const NotifyDialog = ({isOpen, onClose, data}) => {
  const {formatMessage: f} = useIntl();
  return (
    <Dialog aria-labelledby="question-dialog-title" open={isOpen} onClose={onClose}>
      <DialogTitle id="question-dialog-title">{data.type ? f({id: data.type}) : null} - {data.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="question-dialog-description">
          {data.message ? f({id: data.message}) : 'defaultNonComforming'}
        </DialogContentText>
        <br/>

      </DialogContent>
      <DialogActions>
        <Button data-testid="nd-button1" className="buttonColor blue" color="primary" onClick={onClose}>{f({id: 'close'})}</Button>
      </DialogActions>
    </Dialog>
  );
};

NotifyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default NotifyDialog;
