import React from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {connect} from 'react-redux';

import {findIndex} from 'lodash';
import {FormattedMessage} from 'react-intl';
import {setLocale} from '../actions';

class LangChooser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedIndex: 0
    };
    this.setAnchorEl = value => {
      this.setState({anchorEl: value});
    };

    this.setSelectedIndex = value => {
      this.setState({selectedIndex: value});
    };

    this.options = [
      {code: 'FR', lng: 'frFR', i18n: 'fr'},
      {code: 'GB', lng: 'enUS', i18n: 'en'}
    ];
  }

  static propTypes = {
    setLocale: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired
  }

  componentDidMount() {
    let start = findIndex(this.options, ({lng}) => {
      const language = localStorage.getItem('language');
      return lng === this.props.locale || lng === language;
    });
    start = start === -1 ? 0 : start;
    this.props.setLocale(this.options[start].i18n);
    this.setSelectedIndex(start);
  }

  render() {
    const handleClickListItem = event => {
      this.setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
      this.setSelectedIndex(index);
      this.props.setLocale(this.options[index].i18n);
      localStorage.setItem('language', this.options[index].lng);
      this.setAnchorEl(null);
    };

    const handleClose = () => {
      this.setAnchorEl(null);
    };

    return (
      <div className="langChooser">
        <List component="nav" aria-label="Device settings">
          <ListItem
            button
            aria-haspopup="true"
            aria-controls="lock-menu"
            onClick={handleClickListItem}
          >
            <ReactCountryFlag
              svg
              className="flag-icon"
              countryCode={this.options[this.state.selectedIndex].code}
              alt={'flag-' + this.options[this.state.selectedIndex].code}
              style={{
                fontSize: '1.5em',
                lineHeight: '1.5em'
              }}
            />
            <span className="flag-text">
              <FormattedMessage id={this.options[this.state.selectedIndex].lng}/>
            </span>
            <ArrowDropDownIcon className="small-icon"/>
          </ListItem>
        </List>
        <Menu
          keepMounted
          id="lock-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={handleClose}
        >
          {this.options.map((option, index) => (
            <MenuItem
              key={option.code}
              selected={index === this.state.selectedIndex}
              onClick={event => handleMenuItemClick(event, index)}
            >
              <ReactCountryFlag
                svg
                className="flag-icon"
                alt={'flag-' + option.code}
                countryCode={option.code}
              />
              <FormattedMessage id={option.lng}/>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

export default connect(state => ({
  locale: state.default.locale
}), {
  setLocale
})(LangChooser);
