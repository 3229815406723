import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {useDispatch} from 'react-redux';
import {SET_SELECTED_SURVEY} from '../../../actions/types';
import BlueIconButton from '../../shared/blue-icon-button';

export default function SurveyListItem({survey, onPageSelect}) {
  const {formatMessage: f, formatDate} = useIntl();
  const isFilled = Boolean(survey.responseDate);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClickSurvey = () => {
    dispatch({
      type: SET_SELECTED_SURVEY,
      payload: survey
    });

    // In portal case
    if (onPageSelect) {
      onPageSelect('fillSurvey/surveyPage', {
        readOnly: isFilled
      });
    } else {
      // In standalone case
      history.push('surveyPage', {
        readOnly: isFilled
      });
    }
  };

  return (
    <Grid container style={{paddingLeft: 10, paddingRight: 10}}>
      <Grid item xs={8} style={{display: 'flex', flexDirection: 'row'}} onClick={handleClickSurvey}>
        { isFilled &&
        <div data-testid="sli-icon-filled" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CheckCircleIcon htmlColor="green"/>
        </div>}
        <div style={styles.containerSurveyInformations}>
          <p data-testid="sli-text1" style={{...styles.surveyTitle}}>{survey.title}</p>
          { isFilled && <p data-testid="sli-text2" style={styles.surveyDescription}>{`${f({id: 'filledAt'})} ${formatDate(survey.responseDate)}`}</p>}
          { !isFilled && survey.description && <p data-testid="sli-text3" style={styles.surveyDescription}>{survey.description}</p>}
        </div>
      </Grid>

      <Grid item xs={4} style={styles.buttonContainer}>
        {
          isFilled ?
            <BlueIconButton data-testid="sli-button1" icon="visibility" matchSize="600" text={f({id: 'consult'})} onClick={handleClickSurvey}/> :
            <BlueIconButton data-testid="sli-button2" icon="edit" matchSize="600" text={f({id: 'complete'})} onClick={handleClickSurvey}/>
        }
      </Grid>
    </Grid>
  );
}

const styles = {
  containerSurveyInformations: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    cursor: 'pointer',
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10,
    minHeight: 50
  },
  surveyTitle: {
    margin: 0,
    color: '#2196F3',
    fontSize: 14,
    fontWeight: 'bold'
  },
  surveyDescription: {
    margin: 0,
    fontSize: '1.2em',
    fontStyle: 'italic',
    color: 'grey'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    backgroundColor: '#2196F3',
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 14,
    minWidth: 120
  }
};

SurveyListItem.propTypes = {
  survey: PropTypes.object.isRequired,
  onPageSelect: PropTypes.func
};
