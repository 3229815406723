import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {get} from 'lodash';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {buy, refill, success, error, warning, fetchWallet, setCommand, getPaymentMethod} from '../../../../actions/payment';
import payboxLogo from '../../../../assets/paybox.png';
import paylineLogo from '../../../../assets/payline.png';
import BlueButton from '../../../blue-button';
import WhiteButton from '../../../white-button';
import PaymentConfirmationDialog from './payment-confirmation-dialog';
import RefillForm from './refill-form';
import CreditCardForm from '../../CreditCardForm/credit-card-form';
import SmartBreadCrumbs from '../../../shared/breadcrumbs-smart';
import styles from './payment-validation.module.scss';

class PaymentValidation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      token: '',
      command: [],
      valid: false,
      loading: false,
      alert: {
        open: false,
        title: '',
        text: ''
      },
      card: null,
      cancealable: false,
      wallet: 0,
      walletOk: false,
      newWallet: 0,
      supply: 0,
      afterSupplyWallet: 0,
      refillOnly: get(this.state, 'command.type') === 'supply',
      step: -1,
      cardError: false,
      cardIsValid: false,
      numberError: false,
      dateError: '',
      cryptoError: false,
      cardInput: {},
      nextButtonDisabled: false,
      overdraft: this.props.hospital?.process?.payment?.overdraft,
      links: [
        {
          path: '/multimedia',
          text: 'multimediaBooking'
        }
      ],
      paymentType: null
    };

    this.redirect = supply => {
      const page = supply ? 'multimedia/supplyWallet' : 'multimedia/buyDay';
      this.props.onUpdate(page);
    };

    this.handleAlertClose = () => {
      this.setState({
        alert: {
          open: false,
          title: '',
          text: ''
        }
      });
    };

    this.validateCard = async () => {
      if (this.testInputsValidity()) {
        return false;
      }

      const card = this.state.cardInput.number.replace(/\s/g, '');
      const okDate = moment(this.state.cardInput.date, 'MM/YY');

      const cardToSave = {
        card,
        date: okDate.format('MMYY'),
        crypto: this.state.cardInput.crypto
      };

      this.setState({card: cardToSave});
      return true;
    };

    this.testInputsValidity = () => {
      const card = this.state.cardInput.number.replace(/\s/g, '');
      const okDate = moment(this.state.cardInput.date, 'MM/YY', true);

      // Credit cards regex
      const cardRegex = new RegExp('^\\d{16}$');
      const cryptoRegex = new RegExp('^\\d{3,4}$');

      const today = moment();
      const validity = moment(okDate).endOf('month');
      let error = false;

      if (!cardRegex.exec(card)) {
        this.setState({numberError: true});
        error = true;
      } else {
        this.setState({numberError: false});
      }

      if (!okDate.isValid()) {
        this.setState({dateError: this.props.intl.formatMessage({id: 'invalidDate'})});
        error = true;
      } else if (today.isAfter(validity)) {
        this.setState({dateError: this.props.intl.formatMessage({id: 'futureDate'})});
        error = true;
      } else {
        this.setState({dateError: ''});
      }

      if (!cryptoRegex.exec(this.state.cardInput.crypto)) {
        this.setState({cryptoError: true});
        error = true;
      } else {
        this.setState({cryptoError: false});
      }

      return error;
    };

    this.checkNextStep = () => {
      const isInOverdraftWithoutCredit = this.state.supply === 0 && this.state.wallet >= -Math.abs(this.state.overdraft);
      if (this.state.step === 0 && this.state.refillOnly) {
        this.setState({step: 2});
        sessionStorage.setItem('command-step', 2);
      } else if (this.state.step === 0 && this.state.walletOk) {
        this.validate(false);
      } else if (this.state.step === 0 && !this.state.walletOk) {
        if (isInOverdraftWithoutCredit) {
          this.setState({step: 4});
          sessionStorage.setItem('command-step', 4);
        } else {
          this.setState({step: 2});
          sessionStorage.setItem('command-step', 2);
        }
      } else if (this.state.step === 1) {
        this.setState({step: 2});
        sessionStorage.setItem('command-step', 2);
      } else if (this.state.step === 2) {
        this.validate();
      } else if (this.state.step === 4) {
        const withRefill = this.state.refillOnly || (!isInOverdraftWithoutCredit && !this.state.walletOk);
        this.confirmBuy(withRefill, this.state.card);
      }
    };

    this.checkPreviousStep = () => {
      const isInOverdraftWithoutCredit = this.state.supply === 0 && this.state.wallet >= -Math.abs(this.state.overdraft);
      this.setState({cardIsValid: false});
      if (this.state.step === 0 && this.state.refillOnly) {
        this.redirect(true);
      } else if (this.state.step === 0) {
        this.redirect(false);
      } else if (this.state.step === 1) {
        this.setState({step: 0});
        sessionStorage.setItem('command-step', 0);
      } else if (this.state.step === 2 && this.state.refillOnly) {
        this.props.onUpdate('multimedia/supplyWallet');
      } else if (this.state.step === 2) {
        this.setState({step: 0});
        sessionStorage.setItem('command-step', 0);
      } else if (this.state.step === 4) {
        const prevStep = isInOverdraftWithoutCredit ? 0 : 2;
        this.setState({step: prevStep});
        sessionStorage.setItem('command-step', prevStep);
      }
    };

    this.validate = async (checkCard = true) => {
      if (checkCard) {
        const isValid = await this.validateCard();
        if (!isValid) {
          return;
        }
      }

      if ((!this.state.walletOk || this.state.refillOnly) && this.state.card) {
        this.setState({loading: false, step: 4, cardError: false});
        sessionStorage.setItem('command-step', 4);
      } else if ((!this.state.walletOk || this.state.refillOnly) && !this.state.card) {
        this.setState({loading: false, step: 4});
        sessionStorage.setItem('command-step', 4);
      } else {
        this.setState({loading: false});
        this.confirmBuy(false, this.state.card);
      }
    };

    this.confirmBuy = async (withRefill, card) => {
      const type = get(this.state, 'command.type');
      this.setState({loading: true});

      if (withRefill) {
        const command = this.state.refillOnly ?
          this.state.command :
          {...this.state.command, total: this.state.supply};

        const res = await this.props.refill(
          command,
          {...this.state.user, lang: this.props.lang},
          this.state.token,
          card = {
            porteur: card?.card,
            dateval: card?.date,
            cvv: card?.crypto
          },
          this.state.paymentType
        );

        if (res.status !== 200) {
          if (res.msg && typeof res.msg === 'object') {
            this.props.error(res.msg[this.props.intl.locale || 'en'] || this.props.intl.formatMessage({id: 'error'}));
          } else if (res.msg === '3dsError') {
            if (res.error3DS) {
              this.props.error(this.props.intl.formatMessage({id: '3dsErrorWithCode'}) + res.error3DS);
            } else {
              this.props.error(this.props.intl.formatMessage({id: res.msg || 'error'}));
            }
          } else {
            this.props.error(this.props.intl.formatMessage({id: res.msg || 'error'}));
          }

          this.setState({
            loading: false
          });
        } else if (!this.state.refillOnly) {
          this.buyItems(type);
        } else if (this.state.refillOnly) {
          this.buyItems(type, Boolean(res.result === 'soft decline'));
        }
      } else {
        this.buyItems(type);
      }
    };

    this.buyItems = async (type, stay) => {
      if (type === 'buy') {
        const buyRes = await this.props.buy(
          this.state.command,
          this.state.user,
          this.state.token,
          this.state.tvCoaxProcess
        );
        if (buyRes.status !== 200) {
          this.props.error(
            buyRes.msg ?
              this.props.intl.formatMessage({id: buyRes.msg}) :
              'error'
          );
          this.setState({
            loading: false
          });
        } else {
          this.setState({loading: false});
        }
      } else if (type === 'supply') {
        this.setState({loading: false});
      }

      if (sessionStorage.getItem('command-step')) {
        sessionStorage.removeItem('command-step');
      }

      if (!stay) {
        this.props.onUpdate('multimedia/paymentConfirmation');
      }
    };
  }

  async fetchInfo() {
    const {customerID} = this.state.user;
    const {token} = this.state;
    const wallet = await this.props.fetchWallet({customerID, token});
    const step = get(this.state, 'command.type') === 'supply' ? 2 : 0;
    const newWallet =
      get(this.state, 'command.type') === 'supply' ?
        wallet.data + get(this.state, 'command.total', 0) :
        wallet.data - get(this.state, 'command.total', 0);
    this.setState({
      wallet: wallet.data || 0,
      newWallet,
      walletOk: newWallet >= 0,
      afterSupplyWallet: wallet.data || 0,
      refillOnly: get(this.state, 'command.type') === 'supply',
      step
    });

    if (newWallet >= 0) {
      this.setState({nextButtonDisabled: false});
    }

    sessionStorage.setItem('command-step', step);
  }

  componentDidMount() {
    // Get the payment method
    this.props.onUpdateUserSupply(0);
    getPaymentMethod(this.props?.hospital?.hospID, this.state.user)
      .then(paymentMethod => {
        this.setState({
          paymentType: paymentMethod?.items?.find(i => ['paybox', 'payline'].includes(i.name) && i.enable)?.name
        });
      });

    if (sessionStorage.getItem('command-step')) {
      sessionStorage.removeItem('command-step');
      this.props.onUpdate('multimedia');
    } else if (this.state.user) {
      this.fetchInfo();
      if (get(this.state, 'user.payment')) {
        this.setState({
          cancealable: true
        });
      }

      if (get(this.state, 'command.type') === 'supply') {
        if (this.state.step === 0) {
          this.setState({step: 2});
          sessionStorage.setItem('command-step', 2);
        }

        this.setState({
          links: [
            {path: '/multimedia', text: 'multimediaBooking'},
            {path: '/multimedia/supplyWallet', text: 'supplyWallet'},
            {path: '/multimedia/supplyWallet', text: 'paymentManage'}
          ]
        });
      } else {
        this.setState({
          links: [
            {path: '/multimedia', text: 'multimediaBooking'},
            {path: '/multimedia/buyDay', text: 'supplyWallet'}
          ]
        });
      }
    }
  }

  componentDidUpdate(props, state) {
    if (props.user && props.user._id !== get(this.state, 'user._id')) {
      this.setState({user: props.user});
      if (props.user.payment) {
        this.setState({
          cancealable: true
        });
      }
    }

    if (props.token !== this.state.token) {
      this.setState({token: props.token});
    }

    if (props.command !== this.state.command) {
      this.setState({
        command: props.command,
        refillOnly: get(props, 'command.type') === 'supply'
      });
    }

    if (props.tvCoaxProcess !== this.state.tvCoaxProcess) {
      this.setState({tvCoaxProcess: props.tvCoaxProcess});
    }

    if (this.state.step === 0 && state.walletOk !== this.state.walletOk) {
      this.setState({
        links: [
          {path: '/multimedia', text: 'multimediaBooking'},
          {path: '/multimedia/buyDay', text: 'buyDay'},
          {path: '/multimedia/paymentValidation', text: 'buyDayActionConfirm'}
        ]
      });
    }

    if (state.step !== this.state.step) {
      if (this.state.step === 0) {
        if (get(props, 'command.type') !== 'supply' && this.state.walletOk) {
          this.setState({
            links: [
              {path: '/multimedia', text: 'multimediaBooking'},
              {path: '/multimedia/paymentValidation', text: 'buyDayActionConfirm'}
            ]
          });
        } else if (get(props, 'command.type') !== 'supply' && !this.state.walletOk) {
          this.setState({
            links: [
              {path: '/multimedia', text: 'multimediaBooking'},
              {path: '/multimedia/buyDay', text: 'supplyWallet'}
            ]
          });
        }
      } else if (this.state.step === 4) {
        if (get(props, 'command.type') !== 'supply') {
          this.setState({
            links: [
              {path: '/multimedia', text: 'multimediaBooking'},
              {path: '/multimedia/buyDay', text: 'buyDay'},
              {path: '/multimedia/paymentValidation', text: 'buyDayActionConfirm'}
            ]
          });
        } else if (get(props, 'command.type') === 'supply') {
          this.setState({
            links: [
              {path: '/multimedia', text: 'multimediaBooking'},
              {path: '/multimedia/supplyWallet', text: 'supplyWallet'},
              {path: '/multimedia/supplyWallet', text: 'supplyActionConfirm'}

            ]
          });
        }
      } else if (this.state.step > 0) {
        if (get(props, 'command.type') !== 'supply') {
          this.setState({
            links: [
              {path: '/multimedia', text: 'multimediaBooking'},
              {path: '/multimedia/buyDay', text: 'buyDay'},
              {path: '/multimedia/buyDay', text: 'paymentManage'}
            ]
          });
        } else if (get(props, 'command.type') === 'supply') {
          this.setState({
            links: [
              {path: '/multimedia', text: 'multimediaBooking'},
              {path: '/multimedia/supplyWallet', text: 'supplyWallet'},
              {path: '/multimedia/supplyWallet', text: 'paymentManage'}

            ]
          });
        }
      }
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;
    if (nextProps.user) {
      newProps = newProps ?
        {...newProps, user: nextProps.user} :
        {user: nextProps.user};
    }

    if (nextProps.token) {
      newProps = newProps ?
        {...newProps, token: nextProps.token} :
        {token: nextProps.token};
    }

    if (nextProps.command) {
      newProps = newProps ?
        {...newProps, command: nextProps.command} :
        {command: nextProps.command};
    }

    if (nextProps.tvCoaxProcess) {
      newProps = newProps ?
        {...newProps, tvCoaxProcess: nextProps.tvCoaxProcess} :
        {tvCoaxProcess: nextProps.tvCoaxProcess};
    }

    return newProps;
  }

  isNextButtonDisabled() {
    // If the command has no items, the next button is disabled
    if (this.state.command && this.state.command.details && !this.state.command.details.some(plan => plan.qty > 0)) {
      return true;
    }

    // If is loading -> next button disabled
    if (this.state.loading) {
      return true;
    }

    if (this.state.step === 0 && this.state.nextButtonDisabled) {
      return true;
    }

    return false;
  }

  render() {
    const {formatMessage: f} = this.props.intl;

    const type = get(this.state, 'command.type');
    const refillOnly = type === 'supply';

    const total = get(this.state, 'command.total');
    const handleClose = () => {
      this.handleAlertClose();
    };

    const handleSupplyChange = value => {
      if (Math.sign(value) === -1 || Object.is(Math.sign(value), -0)) {
        value *= -1;
      }

      this.setState({
        supply: value,
        afterSupplyWallet: this.state.wallet + value
      });
    };

    const isLastPaymentStep = this.state.step === 4 || (this.state.step === 0 && this.state.walletOk && !refillOnly);

    return (
      <div className="paiement">
        <div className="pageActions">
          <SmartBreadCrumbs links={this.state.links}/>
        </div>
        <div className="pageContent left-align-flex ">
          {this.state.step === 0 && (
            <>
              {!refillOnly && this.state.walletOk && (
                <PaymentConfirmationDialog
                  walletOk={this.state.walletOk}
                  refillOnly={refillOnly}
                  supply={this.state.supply}
                  user={this.state.user}
                  command={this.state.command}
                />
              )}

              {!refillOnly && !this.state.walletOk && (
                <RefillForm
                  refillOnly={refillOnly}
                  wallet={this.state.wallet}
                  total={total}
                  authorizedOverdraft={this.state.overdraft}
                  handleSupplyChange={handleSupplyChange}
                  onNextButtonDisabledUpdated={isDisabled => this.setState({nextButtonDisabled: isDisabled})}
                  onUpdateUserSupply={this.props.onUpdateUserSupply}
                />
              )}
            </>
          )}

          {/* REFILL REQUIRED FORM */}
          {this.state.step === 1 && (
            <RefillForm
              refillOnly={refillOnly}
              wallet={this.state.wallet}
              total={total}
              authorizedOverdraft={this.state.overdraft}
              handleSupplyChange={handleSupplyChange}
              onNextButtonDisabledUpdated={isDisabled => this.setState({nextButtonDisabled: isDisabled})}
              onUpdateUserSupply={this.props.onUpdateUserSupply}
            />
          )}

          {/* CREDIT CARD FORM */}

          {this.state.step === 2 && (
            <CreditCardForm
              setCardValid={value => this.setState({cardIsValid: value})}
              cancel={this.checkPreviousStep}
              cardError={this.state.cardError}
              dateError={this.state.dateError}
              cryptoError={this.state.cryptoError}
              numberError={this.state.numberError}
              setCard={value => this.setState({cardInput: value})}
            />
          )}

          {this.state.step === 4 && (
            <>
              <div className="flex spaceBetween fullWidth wrap">
                {this.state.paymentType === 'paybox' && <img src={payboxLogo} width={200} alt="paybox"/>}
                {this.state.paymentType === 'payline' && <img src={paylineLogo} width={200} alt="payline"/>}
              </div>

              <PaymentConfirmationDialog
                refillOnly={this.state.refillOnly}
                walletOk={this.state.walletOk}
                card={this.state.card}
                supply={this.state.supply || false}
                user={this.state.user}
                command={this.state.command}
              />
            </>
          )}
          <div className="fullWidth flex column center">
            <div className="flex fullWidth spaceBetween marginTop">
              <WhiteButton
                data-testid="pv-button1"
                variant="contained"
                onClick={this.checkPreviousStep}
              >
                {isLastPaymentStep ? f({id: 'cancel'}) : f({id: 'previous'})}
              </WhiteButton>
              <div className={styles.Wrapper}>

                <BlueButton
                  data-testid="pv-button2"
                  variant="contained"
                  disabled={this.isNextButtonDisabled()}
                  onClick={this.checkNextStep}
                >
                  {isLastPaymentStep ? f({id: 'confirm'}) : f({id: 'next'})}
                </BlueButton>
                {this.state.loading && <CircularProgress size={24} className={styles.LoadingSpinner}/>}
              </div>

            </div>
          </div>
        </div>

        <Dialog
          open={this.state.alert.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleClose}
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.alert.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alert.text}
            </DialogContentText>
            {this.state.alert.text2}
          </DialogContent>
          <DialogActions>
            <div className="flex spaceBetween fullWidth center column">
              <div className="flex fullWidth spaceBetween">
                <WhiteButton
                  data-testid="pv-button3"
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  {this.state.alert.cancelText ?
                    f({id: this.state.alert.cancelText}) :
                    'Ok'}
                </WhiteButton>
                {this.state.alert.confirmText && (
                  <BlueButton
                    data-testid="pv-button4"
                    variant="contained"
                    onClick={this.state.alert.confirmAction}
                  >
                    {this.state.alert.confirmText ?
                      f({id: this.state.alert.confirmText}) :
                      'Ok'}
                  </BlueButton>
                )}
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: get(state, 'user.user'),
    token: get(state, 'user.token'),
    command: get(state, 'payment.command'),
    tvCoaxProcess: get(state, 'default.tvCoaxProcess')
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    buy,
    refill,
    error,
    success,
    warning,
    fetchWallet,
    setCommand
  })(PaymentValidation)
);
