/**
 * Return the friends plans about the quantity of plans.
 * @param {Array<Object>} plans List of available plans
 */
export function getFriendPlansAboutPlansQuantity(plans) {
  // Calculate friends of the selected plans
  const newFriends = new Set(plans.map(plan => plan._id)); // By default all plans are friends
  plans.forEach(plan => {
    if (plan?.qty > 0) {
      // "subscription" plan type is always a friend
      newFriends.forEach(newFriend => {
        if (!plan.friends.includes(newFriend)) {
          newFriends.delete(newFriend);
        }
      });
    }
  });

  return newFriends;
}
