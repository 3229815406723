import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '45%',
    minWidth: '280px',
    maxWidth: '540px'
  }
}));

export default function ModalComponent(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const {handleClose} = props;
  const {content} = props;

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <div className={classes.paper}>
        {React.createElement(content.component, content.params)}
      </div>
    </Modal>
  );
}
