import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

export default function HospitalCard({logo, title = '', id, onTap}) {
  const onClick = () => onTap(id);

  return (
    <Card className="card">
      <CardActionArea
        onClick={onClick}
      >

        <img
          className="media"
          src={logo}
          alt={title}
        />
        <CardContent className="flex center">
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

HospitalCard.propTypes = {
  logo: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.string,
  onTap: PropTypes.func.isRequired
};
