import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

export default function TextInput({setResponse, currentResponse, isReadOnly}) {
  const handleChange = evt => {
    setResponse([evt.target.value]);
  };

  const getCurrentValue = () => {
    if (currentResponse && currentResponse.value) {
      return currentResponse.value[0] || '';
    }

    return '';
  };

  return (
    <TextareaAutosize
      rowsMin={2}
      style={{width: '100%'}}
      variant="outlined"
      value={getCurrentValue()}
      disabled={isReadOnly}
      onChange={handleChange}/>
  );
}

TextInput.propTypes = {
  setResponse: PropTypes.func.isRequired,
  currentResponse: PropTypes.object,
  isReadOnly: PropTypes.bool
};
