import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {get} from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import {fetchServices, fetchLocations, updateUserLocation, allocateUserLocation, fetchUserPlans, fetchLocationMsg} from '../../../actions/user';
import {error, success, warning} from '../../../actions';

import {convertTextInMultilines} from '../convert-text-in-multilines';
import BlueButton from '../../blue-button';
import WhiteButton from '../../white-button';

import FormInputAutocomplet from '../form-input-autocomplet';
import FormAutocomplet from '../form-autocomplet';

import styles from './location-form.module.scss';

class LocationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      locations: [],
      selectedService: this.props.user?.service?.servID ? this.props.user.service : null,
      selectedLocation: this.props.user?.location?.locID ? this.props.user.location : null,
      selectedServiceByPMS: typeof this.props.user?.service === 'string' ? this.props.user.service : null,
      selectedLocationByPMS: typeof this.props.user?.location === 'string' ? this.props.user.location : null,
      hasChanged: false,
      alert: {
        open: false,
        title: '',
        text: ''
      },
      customizedLocationMsg: '',
      width: window.innerWidth
    };

    this.handleAlertClose = () => {
      this.setState({
        alert: {
          open: false,
          title: '',
          text: ''
        }
      });
    };

    this.sendSaveLocationRequest = async (selectedService, selectedLocation) => {
      const location = {
        locID: selectedLocation.locID,
        room: selectedLocation.room
      };
      const service = {
        servID: selectedService.servID,
        name: selectedService.name
      };

      const body = {
        token: this.props.token || '',
        hospID: this.props.hospital.hospID,
        location,
        service
      };

      const response = await this.props.updateUserLocation(this.props.user._id, body);
      if (response.result === 'ERROR') {
        this.props.error(this.props.intl.formatMessage({id: response.msg}));
      } else if (response.status === 200) {
        this.props.success(this.props.intl.formatMessage({id: 'dataUpdateConfirm'}));
        // Save informations to customer
        this.props.onUpdateLocation(location, service);

        // Send allocate request to SI if user has TV plan
        if (this.props.user.plans?.length > 0) {
          const response = await this.props.fetchUserPlans({customerID: this.props.user.uuid, token: this.props.token});
          if (response.data?.items?.length > 0) {
            const body = {customerID: this.props.user.uuid, hospID: this.props.hospital.hospID, location};
            await this.props.allocateUserLocation(this.props.user._id, this.props.token, body);
          }
        }
      }
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({width: window.innerWidth}));

    fetchServices(this.props.user?.hospID || this.props.hospital.hospID).then(response => {
      if (response.result === 'SUCCESS') {
        this.setState({services: response.data.items});

        // If location has allocated by PMS
        if (typeof this.props.user?.service === 'string') {
          const service = response.data.items.find(s => s.servID === this.props.user.service);
          this.setState({selectedService: service});
          this.setState({selectedServiceByPMS: service});
        }
      } else {
        this.props.error(this.props.intl.formatMessage({id: response.msg}));
      }
    });
    fetchLocationMsg(this.props.user?.hospID || this.props.hospital?.hospID).then(response => {
      if (response.result === 'SUCCESS' && response.data) {
        this.setState({customizedLocationMsg: response.data});
      }
    });

    if (this.props.user?.service && this.props.user?.location) {
      fetchLocations(this.props.user.hospID, this.props.user.service.servID || this.props.user.service)
        .then(response => {
          if (response.result === 'SUCCESS') {
            this.setState({locations: response.data.items});

            // If location has allocated by PMS
            if (typeof this.props.user.location === 'string') {
              const location = response.data.items.find(l => l.locID === this.state.selectedLocationByPMS);
              this.setState({selectedLocation: location});
              this.setState({selectedLocationByPMS: location});
            }
          } else {
            this.props.error(this.props.intl.formatMessage({id: response.msg}));
          }
        });
    }
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    const isMobileSize = width => {
      return width <= 600;
    };

    const handleClose = () => {
      this.handleAlertClose();
    };

    const handleServiceChange = newValue => {
      if (this.props.user.service?.servID !== newValue?.servID) {
        this.setState({hasChanged: true});
      } else {
        this.setState({hasChanged: false});
      }

      this.setState({
        selectedService: newValue,
        selectedLocation: null
      });

      if (!newValue) {
        this.setState({locations: []});
        this.setState({selectedLocation: null});
        return;
      }

      // Fetch locations from SI
      fetchLocations(this.props.user?.hospID, newValue.servID)
        .then(response => {
          if (response.result === 'SUCCESS') {
            this.setState({locations: response.data.items});
          } else {
            this.props.error(this.props.intl.formatMessage({id: response.msg}));
          }
        });
    };

    const handleLocationChange = newValue => {
      this.setState({selectedLocation: newValue});

      if (this.props.user.location?.locID !== newValue?.locID) {
        this.setState({hasChanged: true});
      } else {
        this.setState({hasChanged: false});
      }
    };

    const handleClickValidate = async () => {
      if (this.props.user.isFilled && Object.keys(this.state.selectedLocation).length === 0) {
        this.props.error(this.props.intl.formatMessage({id: 'errorLocationFormat'}));
        return;
      }

      if (!this.props.user.isFilled && this.props.userInfoToUpdate) {
        const isSuccess = await this.props.handleSendUpdateUserRequest(this.props.userInfoToUpdate);
        if (isSuccess && this.state.selectedService && this.state.selectedLocation) {
          this.sendSaveLocationRequest(this.state.selectedService, this.state.selectedLocation);
        }

        this.props.handleOpenLocationPage(false);
        this.props.onUpdate('personalHome');
        return;
      }

      this.sendSaveLocationRequest(this.state.selectedService, this.state.selectedLocation);
      this.props.handleOpenLocationPage(false);
    };

    const handleClickCancel = () => {
      this.props.handleOpenLocationPage(false);
    };

    return (
      <div className={styles.LocationFormRoot}>
        {this.props.user.isFilled ?
          <div className={styles.LocationHeaderPopup}>
            <span data-testid="pdl-text1" className={styles.LocationFormTitle}>{(this.props.user.service && this.props.user.location) ? f({id: 'modifyLocationTitle'}) : f({id: 'selectMyLocationTitle'})}</span>
            {(this.props.user.service && this.props.user.location) &&
              <span data-testid="pdl-text2" className={`${isMobileSize(this.state.width) ? styles.MobileLocationFormLabel : styles.LocationFormLabel}`}>
                {f({id: 'modifyLocationDescription1'})}&nbsp;<strong>{this.props.user.location.room || this.state.selectedLocationByPMS.room}</strong>&nbsp;{f({id: 'modifyLocationDescription2'})}&nbsp;<strong>{this.props.user.service.name || this.state.selectedServiceByPMS.name}</strong>.
              </span>}
            <span data-testid="pdl-text3" className={`${isMobileSize(this.state.width) ? styles.MobileLocationFormLabel : styles.LocationFormLabel}`}>
              {(this.props.user.service && this.props.user.location) ? f({id: 'modifyLocationLabel'}) : f({id: 'selectLocationLabel'})}
            </span>
          </div> :
          <div className={`${isMobileSize(this.state.width) ? styles.MobileLocationHeader : styles.LocationHeader}`}>
            <span data-testid="pdl-text1" className={styles.UnknownUserLocationFormTitle}>{f({id: 'selectLocationTitle'})}</span>
            <span data-testid="pdl-text2" className={`${isMobileSize(this.state.width) ? styles.MobileLocationFormLabel : styles.UnKnownUserLocationFormLabel}`}>{f({id: 'selectLocationLabel'})}</span>
          </div>}

        <form noValidate className="root" autoComplete="off">
          <div className={styles.LocationFormContainer}>
            <div className={`${isMobileSize(this.state.width) ? styles.MobileFormContent : styles.FormContent}`}>
              <FormAutocomplet
                id="combo-box1"
                style={{minWidth: `${isMobileSize(this.state.width) ? '16em' : '20em'}`}}
                value={this.state.selectedService}
                options={this.state.services}
                getOptionLabel={option => option.name}
                noOptionsText={f({id: 'noService'})}
                renderInput={params => {
                  return <FormInputAutocomplet {...params} required={Boolean(this.props.user.isFilled)} data-testid="pdl-input1" label={f({id: 'service'})} id="service"/>;
                }}
                onChange={(event, newValue) => {
                  handleServiceChange(newValue);
                }}
              />
            </div>
            <div className={`${isMobileSize(this.state.width) ? styles.MobileFormContent : styles.FormContent}`}>
              <FormAutocomplet
                id="combo-box2"
                style={{minWidth: `${isMobileSize(this.state.width) ? '16em' : '20em'}`}}
                value={this.state.selectedLocation}
                options={this.state.locations}
                getOptionLabel={option => option.room}
                disabled={!this.state.selectedService}
                noOptionsText={f({id: 'noRoom'})}
                renderInput={params => {
                  return <FormInputAutocomplet {...params} required={Boolean(this.props.user.isFilled)} data-testid="pdl-input2" label={f({id: 'roomNumber'})} id="location"/>;
                }}
                onChange={(event, newValue) => {
                  handleLocationChange(newValue);
                }}
              />
            </div>
            {this.state.customizedLocationMsg?.length > 0 &&
              <div className={styles.LocationMsgContainer}>
                <InfoOutlinedIcon className="grey"/>
                {convertTextInMultilines(this.state.customizedLocationMsg)}
              </div>}

            {this.props.user.isFilled && (
              <div className={`${isMobileSize(this.state.width) ? styles.MobileLocationFormLabel : styles.LocationFormLabel}`}>
                <span data-testid="pdl-mandatory" className="red">*&nbsp;</span>{f({id: 'mandatoryInformation'})}
              </div>
            )}

            <div className={`${isMobileSize(this.state.width) ? styles.MobileFormActions : styles.FormActions}`}>
              <WhiteButton
                data-testid="pdl-button1"
                id="return"
                variant="contained"
                onClick={handleClickCancel}
              >
                {f({id: 'return'})}
              </WhiteButton>
              <BlueButton
                data-testid="pdl-button2"
                id="validate"
                variant="contained"
                disabled={(this.state.selectedService && !this.state.selectedLocation) || (this.props.user.isFilled && !this.state.hasChanged) || (this.props.user.isFilled && !this.state.selectedService && !this.state.selectedLocation)}
                onClick={handleClickValidate}
              >
                {(this.props.user.service && this.props.user.location) ? f({id: 'modify'}) : f({id: 'validate'})}
              </BlueButton>
            </div>
          </div>
        </form>

        <Dialog
          open={this.state.alert.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleClose}
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.alert.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alert.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-testid="pdl-button3" color="primary" onClick={handleClose}>
              {this.state.alert.confirmText || 'Ok'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: get(state, 'user.user')
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    fetchServices,
    fetchLocations,
    updateUserLocation,
    allocateUserLocation,
    fetchUserPlans,
    success,
    error,
    warning
  })(LocationForm)
);
