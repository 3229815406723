import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as ButtonLink} from 'react-router-dom';
import {Breadcrumbs, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {useIntl, FormattedNumber} from 'react-intl';
import moment from 'moment';
import axios from 'axios';

import {fetchCommands} from '../../../actions/user';
import ViewButton from '../../shared/view-button';

/* global Blob */

const Invoice = ({lang, user}) => {
  const {formatMessage: f} = useIntl();
  const locale = useSelector(state => state.default.locale);
  const dispatch = useDispatch();
  const commands = useSelector(state => state.user.commands);

  const formatDate = date => {
    moment.locale(lang || 'fr');
    return moment(date, 'YYYYMMDD').format('L');
  };

  const getPDF = (type, cmdID) => {
    axios({
      url: `/api/proxy/si/customers/${user.customerID}/commands/${cmdID}/pdf`,
      method: 'GET',
      responseType: 'blob'
    })
      .then(response => {
        const blob = new Blob([response.data], {type: 'application/pdf'});
        const fileUrl = URL.createObjectURL(blob);
        if (type === 'view') {
          window.open(fileUrl);
        }

        if (type === 'download') {
          const link = document.createElement('a');
          link.href = fileUrl;
          link.download = 'file.pdf';
          link.click();
        }
      })
      .catch(error => {
        return error;
      });
  };

  useEffect(() => {
    dispatch(fetchCommands(user.customerID));
  }, [dispatch, user]);

  return (
    <>
      <div className="pageActions">
        <Breadcrumbs aria-label="breadcrumb">
          <ButtonLink color="inherit" to="/personalData">
            {f({id: 'myAccount'})}
          </ButtonLink>
          <ButtonLink
            color="textPrimary"
            to="/personalData/myInvoices"
            aria-current="page"
          >
            {f({id: 'myInvoices'})}
          </ButtonLink>
        </Breadcrumbs>
        <ButtonLink className="smallScreenBack" to="/personalData">
          <ArrowBack/>
        </ButtonLink>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{f({id: 'invoiceTableTitle'})}</TableCell>
              <TableCell align="center"> {f({id: 'dateTableTitle'})}</TableCell>
              <TableCell align="center">{f({id: 'amountTableTitle'})}</TableCell>
              <TableCell align="center">{f({id: 'actionTableTitle'})}</TableCell>
            </TableRow>
          </TableHead>
          {(commands && commands.data && commands.data.items.length > 0) ?
            <TableBody>
              {commands.data.items.map((item, index) => item.invoice && (
                <TableRow key={index}>
                  <TableCell align="left" component="th" scope="row">
                    {item.invoice.id}
                  </TableCell>
                  <TableCell align="center">{formatDate(item.invoice.date)}</TableCell>
                  <TableCell align="right">
                    <FormattedNumber value={item.PTTC} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>
                  </TableCell>
                  <TableCell align="center">
                    <ViewButton
                      matchSize="600"
                      icon="visibility"
                      text={f({id: 'view'})}
                      onClick={() => getPDF('view', item.cmdID)}
                    />
                    <ViewButton
                      matchSize="600"
                      icon="cloud_download"
                      text={f({id: 'download'})}
                      onClick={() => getPDF('download', item.cmdID)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody> : <TableBody/>}
        </Table>
      </TableContainer>
      {((commands && commands.data && commands.data.nb === 0) || (commands && commands.errorMsg && commands.errorMsg.includes('401'))) &&
        <Typography variant="body1" style={{marginTop: '10px'}}>{f({id: 'haveNotInvoices'})}</Typography>}
      <div style={{marginTop: '10px'}}>
        <span data-testid="invoice-text">{f({id: 'PTTCnote'})}</span>
      </div>
    </>
  );
};

export default Invoice;
