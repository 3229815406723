import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {get} from 'lodash';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';
import ArrowBack from '@material-ui/icons/ArrowBack';

class ActivationCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };
  }

  componentDidUpdate(props) {
    if (props.user && props.user._id !== get(this.state, 'user._id')) {
      this.setState({user: props.user});
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.user) {
      return {user: nextProps.user};
    }

    return null;
  }

  render() {
    const {formatMessage: f} = this.props.intl;

    return (
      <div className="personalForm">
        <div className="pageActions">
          <Breadcrumbs aria-label="breadcrumb">
            <Link data-testid="activation-code-back" color="inherit" to="/personalData">
              {f({id: 'myAccount'})}
            </Link>
            <Link
              data-testid="activation-code-current"
              color="textPrimary"
              to="/personalData/myActivationCode"
              aria-current="page"
            >
              {f({id: 'myActivationCode'})}
            </Link>
          </Breadcrumbs>
          <Link data-testid="activation-code-smallscreen-back" className="smallScreenBack" to="/personalData">
            <ArrowBack/>
          </Link>
        </div>
        <div className="pageContent">
          <div className="personnalDataShow">
            <div className="data-display">
              {get(this.state, 'user.activationCode') && (
                <p id="activationCode">
                  {f({id: 'youHaveActivationCode'})}
                  <span data-testid="activation-code-text1" className="green">
                    {this.state.user.activationCode}
                  </span>
                </p>
              )}
              {!get(this.state, 'user.activationCode') && (
                <p data-testid="activation-code-text2" id="noActivationCode">{f({id: 'youHaveNoActivationCode'})}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: get(state, 'user.user')
  };
};

export default injectIntl(connect(mapStateToProps, {})(ActivationCode));
