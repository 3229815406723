import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const primaryColor = '#2196F3';
const secondaryColor = '#1565c0';
const thirtyColor = '#90caf9';

const IconButton = withStyles({
  root: {
    backgroundColor: primaryColor,
    borderColor: '#007bff',
    color: 'white',
    margin: '0.5em',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: secondaryColor,
      borderColor: thirtyColor,
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: thirtyColor,
      borderColor: '#005cbf'
    }
  }
})(Button);

const ViewButton = ({icon, text, onClick, matchSize}) => {
  const matches = useMediaQuery(`(min-width:${matchSize}px)`);

  return (
    <IconButton startIcon={matches && <Icon>{icon}</Icon>} onClick={onClick}>
      {!matches && <Icon>{icon}</Icon> }
      {matches && <span>{text}</span>}

    </IconButton>
  );
};

export default ViewButton;
