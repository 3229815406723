import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Icon from '@material-ui/core/Icon';

import {callCustomAction} from '../actions';

import '../styles/main.scss';

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: this.props.auth || false,
      actual: this.props.actual || null,
      mobileOpen: false,
      isVisible: this.props.isVisible || false,
      hospID: this.props.hospID,
      whiteList: this.props.whiteList || [],
      plugins: this.props.plugins || [],
      title: this.props.title || '',
      value: 0,
      hiddenIfClosed: this.props.hiddenIfClosed || [],
      hospitalWatcher: 0,
      timeoutWatch: localStorage.getItem('userIsLogin')
    };

    this.handleDrawerToggle = mobile => {
      if (mobile) {
        this.setState(prevState => ({mobileOpen: !prevState.mobileOpen}));
      }
    };

    this.setValue = newValue => {
      this.setState({value: newValue});
    };

    this.redirect = () => {
      this.props.onUpdate('');
      this.setState(prevState => ({mobileOpen: !prevState.mobileOpen}));
    };
  }

  static propTypes = {
    auth: PropTypes.object,
    actual: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    isVisible: PropTypes.bool,
    hospID: PropTypes.string,
    whiteList: PropTypes.array.isRequired,
    plugins: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    langChooser: PropTypes.node.isRequired,
    onLogout: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    hospitalWatcher: PropTypes.number,
    onTimeout: PropTypes.func.isRequired
  };

  componentDidUpdate(props) {
    const timeoutWatch = localStorage.getItem('userIsLogin');

    if (timeoutWatch !== this.state.timeoutWatch) {
      this.setState({timeoutWatch});
      if (!timeoutWatch && Object.keys(this.props.user).length > 0) {
        this.props.onTimeout();
        this.props.onLogout();
      }
    }

    if (props.actual && props.actual !== this.state.actual) {
      this.setState({actual: props.actual});
    }

    if (props.isVisible !== this.state.isVisible) {
      this.setState({isVisible: props.isVisible});
    }

    if (props.hospID && props.hospID !== this.state.hospID) {
      this.setState({hospID: props.hospID});
    }

    if (props.hospitalWatcher && props.hospitalWatcher !== this.state.hospitalWatcher) {
      this.setState({hospitalWatcher: props.hospitalWatcher});
    }

    if (
      props.whiteList &&
      props.whiteList.length !== get(this.state, 'whiteList', []).length
    ) {
      this.setState({whiteList: props.whiteList});
    }

    if (
      props.plugins &&
      props.plugins.length !== get(this.state, 'plugins', []).length
    ) {
      this.setState({plugins: props.plugins});
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;
    if (nextProps.isVisible) {
      newProps = newProps ?
        {...newProps, isVisible: nextProps.isVisible} :
        {isVisible: nextProps.isVisible};
    }

    if (nextProps.actual) {
      newProps = newProps ?
        {...newProps, actual: nextProps.actual} :
        {actual: nextProps.actual};
    }

    if (nextProps.hospID) {
      newProps = newProps ?
        {...newProps, hospID: nextProps.hospID} :
        {hospID: nextProps.hospID};
    }

    if (nextProps.whiteList) {
      newProps = newProps ?
        {...newProps, whiteList: nextProps.whiteList} :
        {whiteList: nextProps.whiteList};
    }

    if (nextProps.plugins) {
      newProps = newProps ?
        {...newProps, plugins: nextProps.plugins} :
        {plugins: nextProps.plugins};
    }

    if (nextProps.title) {
      newProps = newProps ?
        {...newProps, title: nextProps.title} :
        {title: nextProps.title};
    }

    return newProps;
  }

  render() {
    const renderIcon = plugin => {
      if (plugin.customIcon) {
        if (
          get(this.props, plugin.customIcon.params) ===
          plugin.customIcon.condition
        ) {
          return (
            <Icon className={plugin.customIcon.class}>
              {plugin.customIcon.icon}
            </Icon>
          );
        }

        if (plugin.customIconAlt) {
          if (
            get(this.props, plugin.customIconAlt.params) ===
            plugin.customIconAlt.condition
          ) {
            return (
              <Icon className={plugin.customIconAlt.class}>
                {plugin.customIconAlt.icon}
              </Icon>
            );
          }
        }
      }

      return <Icon>{plugin.icon}</Icon>;
    };

    const drawer = mobile => (
      <div className="menu">
        {this.state.plugins.map((plugin, index) => {
          if (this.state.hiddenIfClosed.includes(plugin.identity) && (this.props.user.status === 'closed' || this.props.user.status === 'abandonned')) {
            return (<div/>);
          }

          return (
            <Link
              key={`plugin-${index}`}
              className={`menuItem ${
                this.state.actual === plugin.identity ? 'selected' : ''
              }`}
              to={`/${plugin.identity}`}
              onClick={e => {
                e.preventDefault();

                // Handle item which open new link only
                if (plugin.onClick) {
                  plugin.onClick();
                  return;
                }

                const plug = this.state.actual;
                if (plug) {
                  const found = this.state.plugins.find(plugin => {
                    return plugin.identity === plug && plugin.customAction;
                  });
                  if (found) {
                    const actions = found.customAction();
                    const pathToGo = plugin.identity;
                    this.props.callCustomAction(actions, pathToGo);
                  } else {
                    this.props.onUpdate(plugin.identity);
                    this.handleDrawerToggle(mobile);
                  }
                } else {
                  this.props.onUpdate(plugin.identity);
                  this.handleDrawerToggle(mobile);
                }
              }}
            >
              {renderIcon(plugin)}
              <span className="itemText">
                <FormattedMessage id={plugin.title}/>
              </span>
            </Link>
          );
        }

        )}
      </div>
    );

    const formatFirstName = (name = '') => {
      const splitStr = name.toLowerCase().split('-');
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }

      return splitStr.join('-');
    };

    const formatLastName = (name = '') => {
      return name.toUpperCase();
    };

    return (
      <div className="root">
        <CssBaseline/>
        <AppBar position="fixed" className="appBar">
          <Toolbar className="toolbar">
            <div className="titleActions">
              {this.state.isVisible && (
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  edge="start"
                  className="menuButton"
                  onClick={this.handleDrawerToggle}
                >
                  <MenuIcon/>
                </IconButton>
              )}
              <Typography noWrap variant="h6">
                {this.state.title}
              </Typography>
            </div>
            <div className="titleActions">
              {this.props.langChooser ? this.props.langChooser : null}
              {localStorage.getItem('userIsLogin') && (
                <PowerSettingsNewIcon
                  data-testid="nb-logout"
                  className="quitAction"
                  onClick={() => this.props.onLogout()}
                />
              )}
            </div>
          </Toolbar>
        </AppBar>

        {this.state.isVisible && (
          <nav className="drawer">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                variant="temporary"
                anchor="left"
                open={this.state.mobileOpen}
                classes={{
                  paper: 'drawerPaper'
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
                onClose={this.handleDrawerToggle}
              >
                <IconButton
                  className="closeMenuButton"
                  onClick={this.handleDrawerToggle}
                >
                  <CloseIcon/>
                </IconButton>
                <div className="menuHeader">
                  {this.state.hospID && (
                    <img
                      key={this.state.hospitalWatcher}
                      id="logo"
                      src={`/api/hospitals/${this.state.hospID}/application/logo`}
                      alt="Logo"
                      className="logo"
                      onClick={this.redirect}
                    />
                  )}
                  <p className="bordered">
                    {' '}
                    {formatFirstName(this.props.firstname)}{' '}
                    {formatLastName(this.props.lastname)}
                  </p>
                </div>
                {drawer(true)}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                className="drawer"
                variant="permanent"
                classes={{
                  paper: 'drawerPaper'
                }}
              >
                <div className="toolbar"/>
                <div className="sidebar shadowed">
                  <div className="menuHeader">
                    {this.state.hospID && (
                      <img
                        key={this.state.hospitalWatcher}
                        id="logo"
                        src={`/api/hospitals/${this.state.hospID}/application/logo`}
                        alt="Logo"
                        className="logo"
                      />
                    )}
                    <p className="bordered">
                      {' '}
                      {formatFirstName(this.props.firstname)}{' '}
                      {formatLastName(this.props.lastname)}{' '}
                    </p>
                  </div>
                  <div className="menu">{drawer(false)}</div>
                </div>
              </Drawer>
            </Hidden>
          </nav>
        )}
        <div className="content">
          <div className="toolbar"/>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  callCustomAction
})(NavigationBar);
