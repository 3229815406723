import React from 'react';
import PropTypes from 'prop-types';

export default function Page({children}) {
  return (
    <div className="page withMenu">
      <div style={{height: '100%', flex: 1}}>
        {children}
      </div>
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired
};
