import React from 'react';
import {connect} from 'react-redux';
import {FormattedNumber, injectIntl} from 'react-intl';
import {get} from 'lodash';

import {clearCommand, fetchWallet} from '../../../actions/payment';
import BlueButton from '../../blue-button';
import SmartBreadCrumbs from '../../shared/breadcrumbs-smart';

const RecapAmount = ({f, wallet, supply, locale}) => {
  const walletText = <FormattedNumber value={wallet} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>;
  const supplyText = <FormattedNumber value={supply} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>;
  return (
    <>
      {
        supply > 0 ? (
          <span data-testid="pc-text1">{f({id: 'paymentConfirm1'}, {supply: supplyText, wallet: walletText})} </span>
        ) : (
          <span data-testid="pc-text1">{f({id: 'paymentConfirm5'}, {wallet: walletText})} </span>
        )
      }
    </>
  );
};

const RecapPlans = ({command, f}) => (
  <>
    <p>{f({id: 'paymentConfirmPlans'})}</p>
    <ul>
      {get(command, 'details').map((item, index) => (
        item.qty > 0 && (
          <li key={`alertitemlist-${index}`} data-testid={`pc-li-${index}`}>
            {item.qty} x {item.plan}
          </li>
        )
      ))}
    </ul>
  </>
);

const RecapBuyPlans = ({f, wallet, locale, command, supply}) => (
  <>
    <RecapAmount f={f} wallet={wallet} locale={locale} supply={supply}/>
    <RecapPlans f={f} command={command}/>
  </>
);

const RecapSupplyWallet = ({f, total, locale, wallet}) => (
  <>
    <span data-testid="pc-text3">{f({id: 'walletSupplyConfirm2'})} <FormattedNumber value={total} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>.</span>
    <br/>
    <span data-testid="pc-text4"> {f({id: 'walletAfterSupply'})} <FormattedNumber value={wallet} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>.</span>
    <p data-testid="pc-text5">{f({id: 'paymentConfirm3'})}</p>
  </>
);

class PaymentConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      command: {},
      hospital: this.props.hospital || '.'
    };
    this.validate = () => {
      this.props.onUpdate('multimedia');
    };
  }

  componentWillUnmount() {
    this.props.clearCommand();
  }

  async fetchInfo() {
    const {customerID} = this.state.user;
    const {token} = this.state;
    const wallet = await this.props.fetchWallet({customerID, token});
    this.setState({
      wallet: wallet.data || 0
    });
  }

  componentDidMount() {
    this.fetchInfo();
  }

  componentDidUpdate(props) {
    if (props.user && props.user._id !== get(this.state, 'user._id')) {
      this.setState({user: props.user});
    }

    if (props.command !== this.state.command) {
      this.setState({command: props.command});
    }

    if (props.token !== this.state.token) {
      this.setState({token: props.token});
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;
    if (nextProps.user) {
      newProps = newProps ?
        {...newProps, user: nextProps.user} :
        {user: nextProps.user};
    }

    if (nextProps.token) {
      newProps = newProps ?
        {...newProps, token: nextProps.token} :
        {token: nextProps.token};
    }

    if (nextProps.hospital) {
      newProps = newProps ?
        {...newProps, hospital: nextProps.hospital} :
        {hospital: nextProps.hospital};
    }

    if (nextProps.command) {
      newProps = newProps ?
        {...newProps, command: nextProps.command} :
        {command: nextProps.command};
    }

    return newProps;
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    const {userSupply} = this.props;
    const {user, command} = this.state;

    const links = [
      {path: '/multimedia',
        text: 'multimediaBooking'},
      {path: '/multimedia/paymentConfirmation',
        text: get(command, 'details') ? 'paymentConfirm' : 'supplyConfirm'}
    ];

    return (
      <div className="paiement">
        <div className="pageActions">
          <SmartBreadCrumbs links={links}/>
        </div>
        <br/>
        <div className="pageContent leftAlign">

          {get(command, 'details') ? (
            <RecapBuyPlans
              f={f}
              command={command}
              locale={this.props.locale}
              supply={userSupply}
              wallet={get(this.state, 'wallet', 0)}/>
          ) : (
            <RecapSupplyWallet
              f={f}
              locale={this.props.locale}
              total={get(command, 'total', 0)}
              wallet={get(this.state, 'wallet', 0)}/>
          )}

          {user.activationCode && (
            <>
              <p data-testid="pc-text7">
                {f({id: 'yourActivationCode'})}
                <span style={{color: 'green'}}>{user.activationCode}</span>
              </p>
              <p data-testid="pc-text6">{f({id: 'paymentConfirm4'})}</p>
            </>
          )}

          <br/>
          <BlueButton
            data-testid="pc-button1"
            id="confirmAction"
            className="flat blue"
            color="primary"
            onClick={this.validate}
          >
            {f({id: 'back'})}
          </BlueButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: get(state, 'user.user'),
    token: get(state, 'user.token'),
    command: get(state, 'payment.command'),
    hospital: get(state, 'default.hospital.hospital.name'),
    locale: get(state, 'default.locale')
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    clearCommand,
    fetchWallet
  })(PaymentConfirmation)
);
