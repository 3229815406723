import React, {useState, useEffect, createRef} from 'react';
import {IconButton, Tooltip} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {withStyles} from '@material-ui/core/styles';
import Modal from 'react-awesome-modal';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import {useIntl} from 'react-intl';
import axios from 'axios';

import styles from './uploader.module.scss';

/* global atob Blob */

const CustomIconButton = withStyles({
  root: {
    color: '#2196F3',
    // Margin: '0.5em',
    minWidth: '40px',
    borderRadius: '50%',
    boxShadow: 'none',
    '&:hover': {
      color: '#0069d9'
    }
  }
})(IconButton);

const FileUploader = ({
  onAddFile,
  fileData,
  label,
  note,
  types,
  error,
  section,
  disabled,
  user,
  index
}) => {
  const [file, setFile] = useState(null);
  const [wrongTypeError, setWrongTypeError] = useState(false);

  const [img, setImg] = useState('test.png');
  const [visible, setVisible] = useState(false);
  const fileInputRef = createRef();
  const staticID = Date.now();
  const {formatMessage: f} = useIntl();
  const [data, setData] = useState(fileData);

  useEffect(() => {
    setData(fileData);
  }, [fileData]);

  const deleteFile = () => {
    setFile(null);
    onAddFile(null);
    setData(null);
  };

  const fetchFile = () => {
    return axios
      .get(`/api/proxy/preadmission/file/${user._id}/${section}/${index}`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: user.token ? user.token : ''
        }
      })
      .then(res => {
        const type = file ? file.type : data.fileType;
        if (res.data) {
          if (type === 'application/pdf') {
            displayFile(res.data, label);
          } else {
            setImg(`data:${type};base64,${res.data}`);
            setVisible(true);
          }
        }
      })
      .catch(error_ => {
        console.error(error_);
      });
  };

  const addFile = ev => {
    if (ev.target.files.length) {
      const {type} = ev.target.files[0];
      if (!types.includes(type)) {
        setWrongTypeError(true);
      } else {
        setWrongTypeError(false);
        setFile(ev.target.files[0]);
        onAddFile(ev.target.files[0]);
      }
    }
  };

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  };

  const displayFile = (content, filename) => {
    const blob = b64toBlob(content, 'application/pdf');
    const uriContent = URL.createObjectURL(blob);
    const newWindow = window.open('', filename);
    if (newWindow) {
      const blobHtmlElement = document.createElement('object');
      blobHtmlElement.style.position = 'fixed';
      blobHtmlElement.style.top = '0';
      blobHtmlElement.style.left = '0';
      blobHtmlElement.style.bottom = '0';
      blobHtmlElement.style.right = '0';
      blobHtmlElement.style.width = '100%';
      blobHtmlElement.style.height = '100%';
      blobHtmlElement.setAttribute('type', 'application/pdf');
      blobHtmlElement.setAttribute('data', uriContent);
      newWindow.document.title = filename;
      newWindow.document.body.append(blobHtmlElement);
    } else {
      newWindow.addEventListener('load', () => {
        const blobHtmlElement = document.createElement('object');
        blobHtmlElement.style.position = 'fixed';
        blobHtmlElement.style.top = '0';
        blobHtmlElement.style.left = '0';
        blobHtmlElement.style.bottom = '0';
        blobHtmlElement.style.right = '0';
        blobHtmlElement.style.width = '100%';
        blobHtmlElement.style.height = '100%';
        blobHtmlElement.setAttribute('type', 'application/pdf');
        blobHtmlElement.setAttribute('data', uriContent);
        newWindow.document.title = filename;
        newWindow.document.body.append(blobHtmlElement);
      });
    }

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(uriContent);
    }, 300);
  };

  const closeModal = () => {
    setVisible(false);
    setImg('');
  };

  return (
    <div className={styles.Upload}>
      <Modal
        visible={visible}
        effect="fadeInUp"
        width="0"
        height="0"
        onClickAway={() => closeModal()}
      >
        <img className={styles.ImagePreview} alt="Uploader picture" src={img}/>
      </Modal>
      <div className={styles.Content}>
        <input
          ref={fileInputRef}
          style={{display: 'none'}}
          type="file"
          id={staticID}
          accept={types.toString()}
          onChange={addFile}
        />

        <div className={styles.Files}>
          <div className={styles.EmptyLoader}>
            <span className={wrongTypeError ? styles.FieldLabelError : styles.FieldLabel}>{label}</span>
          </div>
        </div>
        <div>
          <div
            className={
              wrongTypeError || error ?
                styles.FileDivError :
                styles.FileDiv
            }
          >

            {file && (
              <div className={`${disabled ? styles.DisabledFileInput : styles.FileInput}`}>
                <div className={styles.centered}>
                  {(wrongTypeError || error) ? <ErrorIcon htmlColor="red"/> : ''}
                </div>
                <span>{file.name}</span>
              </div>
            )}
            {!file && data && (
              <div className={`${disabled ? styles.DisabledFileInput : styles.FileInput}`}>
                <div className={styles.centered}>
                  {(wrongTypeError || error) ? <ErrorIcon htmlColor="red"/> : ''}
                </div>
                <span>{data.data} </span>
              </div>
            )}
            {!file && !data && (
              <div className={`${disabled ? styles.DisabledFileInput : styles.FileInput}`}>
                <div className={styles.centered}>
                  {(wrongTypeError || error) ? <ErrorIcon htmlColor="red"/> : ''}
                </div>
                <span>{label}</span>
              </div>
            )}
            {!file && !data && (
              <Tooltip title={f({id: 'addFile'})}>
                <label className={styles.FileLabel} htmlFor={staticID}>
                  <CustomIconButton
                    className={styles.FileButton}
                    component="span"
                  >
                    <AddIcon/>
                  </CustomIconButton>
                </label>
              </Tooltip>
            )}
            {file && (
              <div style={{display: 'flex'}}>
                {/*  <CustomIconButton className={styles.FileButton}>
                  <VisibilityIcon/>
                </CustomIconButton> */}
                <Tooltip title={f({id: 'editFile'})}>
                  <label className={styles.FileLabel} htmlFor={staticID}>
                    <CustomIconButton
                      className={styles.FileButton}
                      component="span"
                    >
                      <EditIcon/>
                    </CustomIconButton>
                  </label>
                </Tooltip>
                <Tooltip title={f({id: 'deleteFile'})}>
                  <CustomIconButton
                    className={styles.FileButton}
                    onClick={deleteFile}
                  >
                    <DeleteIcon/>
                  </CustomIconButton>
                </Tooltip>
              </div>
            )}
            {!file && data && (
              <div style={{display: 'flex'}}>
                <Tooltip title={f({id: 'seeFile'})}>
                  <CustomIconButton
                    className={styles.FileButton}
                    onClick={fetchFile}
                  >
                    <VisibilityIcon/>
                  </CustomIconButton>
                </Tooltip>
                {!disabled &&
                <>
                  <Tooltip title={f({id: 'editFile'})}>
                    <label className={styles.FileLabel} htmlFor={staticID}>
                      <CustomIconButton
                        className={styles.FileButton}
                        component="span"
                      >
                        <EditIcon/>
                      </CustomIconButton>
                    </label>
                  </Tooltip>
                  <Tooltip title={f({id: 'deleteFile'})}>
                    <CustomIconButton
                      className={styles.FileButton}
                      onClick={deleteFile}
                    >
                      <DeleteIcon/>
                    </CustomIconButton>
                  </Tooltip>
                </>}

              </div>
            )}

          </div>

          <div style={{display: 'flex', justifyContent: 'space-between'}}><span className={styles.InfoMsg}>{note}</span>  <span className={wrongTypeError ? styles.ErrorMsg : styles.InfoMsg}>{f({id: 'fileTypeHelper'})}</span></div>

        </div>
      </div>
    </div>
  );
};

export default FileUploader;
