import {withStyles} from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default withStyles({
  root: {
    width: '100%',
    marginBottom: '0.25em'
  },
  hasPopupIcon: {
    paddingRight: 0
  },
  hasClearIcon: {
    paddingRight: 0
  },
  inputRoot: {
    '$hasPopupIcon &, $hasClearIcon &': {
      paddingRight: 0
    },
    '$hasPopupIcon$hasClearIcon &': {
      paddingRight: 0
    },
    '& $input': {
      minWidth: '-webkit-fill-available',
      borderRadius: '4px',
      backgroundColor: '#b9b9b947',
      marginBottom: '0.5em'
    },
    '&[class*="MuiInput-root"]': {
      '& $input:first-child': {
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
        paddingRight: '1em',
        paddingLeft: '1em',
        minWidth: '12em'
      }
    }
  },
  input: {
    paddingTop: '0.5em',
    paddingBottom: '0.5em',
    paddingRight: '1em',
    paddingLeft: '1em',
    minWidth: '12em'
  }

})(Autocomplete);
