import React from 'react';
import imageCompression from 'browser-image-compression';
import {injectIntl} from 'react-intl';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './file-uploader.module.scss';

class FileUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      uploading: false,
      type: this.props.type,
      description: this.props.description,
      uploadProgress: false,
      compressionProgress: false,
      wrongTypeError: false,
      fileLimit: this.props.fileSize || 5000000
    };
    this.onAddFile = file => {
      this.setState({file});
    };

    this.handleClose = this.props.onClose;
    this.onSubmit = () => this.props.onSubmit(this.state.file, this.state.type);
    this.fileInputRef = React.createRef();
  }

  componentDidUpdate(props) {
    if (props.uploadProgress !== this.state.uploadProgress) {
      this.setState({uploadProgress: props.uploadProgress});
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return {uploadProgress: nextProps.uploadProgress};
  }

  render() {
    const {file} = this.state;
    const {formatMessage: f} = this.props.intl;
    const addFile = async ev => {
      if (ev.target.files.length) {
        const {size, type} = ev.target.files[0];
        try {
          if (!['application/pdf', 'image/jpeg', 'image/png'].includes(type)) {
            this.setState({wrongTypeError: true});
          } else if (size < this.state.fileLimit || type === 'application/pdf') {
            this.setState({compressionProgress: true});
            this.onAddFile(ev.target.files[0]);
            this.setState({wrongTypeError: false, compressionProgress: false});
          } else if (['image/jpeg', 'image/png'].includes(type)) {
            this.setState({compressionProgress: true});
            const options = {
              maxSizeMB: 5,
              useWebWorker: true
            };
            const compressedFile = await imageCompression(ev.target.files[0], options);
            this.onAddFile(compressedFile);
            this.setState({wrongTypeError: false, compressionProgress: false});
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    return (
      <div className={styles.FileDummyDiv}>
        <div className={styles.SimpleCard}>
          <div className={styles.Header}>
            {this.props.edition && (
              <span data-testid="fu-text1" className={styles.FileTypeName}>{f({id: 'editAFile'})}</span>
            )}
            {!this.props.edition && (
              <span data-testid="fu-text2" className={styles.FileTypeName}>{f({id: 'addFileTitle'})}</span>
            )}

            <span data-testid="fu-text3" className={styles.DocumentCard}>{`${this.state.type} ${this.state.description ? '- ' + this.state.description : ''} `}</span>
          </div>
          <div className={styles.Upload}>
            <div className={styles.Content}>
              <input
                ref={this.fileInputRef}
                data-testid="fu-input1"
                style={{display: 'none'}}
                id="raised-button-file"
                type="file"
                accept="image/png, image/jpeg, application/pdf"
                onChange={addFile}
              />
              <label data-testid="fu-label1" htmlFor="raised-button-file" className={styles.Label}>
                <Button
                  data-testid="fu-button1"
                  className={styles.DocumentButton}
                  component="span"
                  disabled={this.state.uploading}
                >
                  {f({id: 'chose'})}
                </Button>
              </label>
              <div className={styles.Files}>
                {this.state.file && (
                  <div key={file.name} className="Row">
                    <span data-testid="fu-text4" className={styles.FileName}>{file.name}</span>
                  </div>
                )}
                {!this.state.file && this.props.current && (
                  <div className="Row">
                    <span data-testid="fu-text5" className={styles.FileName}>{this.props.current}</span>
                  </div>
                )}
                {!this.state.file && !this.props.current && (
                  <div className="flex column">
                    <span data-testid="fu-text6" className={styles.FileName}>{f({id: 'chooseAFile'})}</span>
                  </div>
                )}
              </div>

            </div>

            {this.state.wrongTypeError && (<span data-testid="fu-error2" className={styles.ErrorMsg}>{f({id: 'wrongTypeMsg'})}</span>)}
            {!this.state.wrongTypeError && (<span data-testid="fu-text7" className={styles.InfoMsg}>{f({id: 'fileUploadText'})}</span>)}

            <div className={styles.Actions}>
              <Button data-testid="fu-button2" className={styles.WhiteDocumentButton} onClick={this.handleClose}>
                {f({id: 'cancel'})}
              </Button>
              <div className={styles.Wrapper}>
                <Button
                  data-testid="fu-button3"
                  className={styles.DocumentButton}
                  disabled={!this.state.file || this.state.wrongTypeError || this.state.uploadProgress}
                  onClick={this.onSubmit}
                >
                  {this.props.edition ? f({id: 'edit'}) : f({id: 'add'})}
                </Button>
                {(this.state.uploadProgress || this.state.compressionProgress) && (
                  <CircularProgress
                    size={24}
                    className={styles.LoadingSpinner}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
export default injectIntl(FileUploader);
