import axios from 'axios';

export function setFavicon(hospital) {
  if (!hospital || !hospital.hospID) {
    return;
  }

  const link = document.querySelector('link[rel*=\'shortcut icon\']') || document.createElement('link');
  const linkiOS = document.querySelector('link[rel*=\'apple-touch-icon\']') || document.createElement('link');
  link.rel = 'shortcut icon';
  link.type = 'image/x-icon';
  linkiOS.rel = 'apple-touch-icon';

  axios.get(`/api/hospitals/${hospital.hospID}/application/favicon`, {
    responseType: 'blob'
  })
    .then(favicon => {
      const reader = new FileReader();
      reader.onloadend = () => {
        link.href = reader.result;
        linkiOS.href = reader.result;
        document.querySelectorAll('head')[0].append(link);
        document.querySelectorAll('head')[0].append(linkiOS);
      };

      reader.readAsDataURL(favicon.data);
    })
    .catch(error => console.error('Failed to fetch favicon', error));
}
