import React, {useState, useEffect} from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

export default function CheckboxInput({possibilities, setResponse, currentResponse, isReadOnly}) {
  const [stateResponses, setStateResponses] = useState(null);

  useEffect(() => {
    const result = possibilities.map(poss => (
      {
        key: poss,
        checked: isAlreadyChecked(poss)
      }
    ));
    setStateResponses(result);
  }, [possibilities, currentResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  function isAlreadyChecked(poss) {
    if (currentResponse && currentResponse.value) {
      return Boolean(currentResponse.value.find(elt => elt === poss));
    }

    return false;
  }

  const handleChange = (response, checked) => {
    if (isReadOnly) {
      return;
    }

    setStateResponses(prevState => {
      const index = prevState.findIndex(elt => elt.key === response);
      if (index > -1) {
        const updated = [...prevState];
        updated[index] = {
          key: response,
          checked
        };
        setResponse(updated.filter(elt => elt.checked).map(elt => elt.key));
        return updated;
      }
    });
  };

  return (
    <FormControl component="fieldset">
      <FormGroup>
        { stateResponses &&
          possibilities.map((response, index) => (
            <FormControlLabel
              key={index}
              disabled={isReadOnly && !stateResponses[index].checked}
              control={<Checkbox
                data-testid={`ci-checkbox-${index}`}
                color="primary"
                checked={stateResponses[index].checked}
                value={response}
                onChange={evt => handleChange(response, evt.target.checked)}/>}
              label={response}
            />
          ))}
      </FormGroup>
    </FormControl>
  );
}

CheckboxInput.propTypes = {
  possibilities: PropTypes.arrayOf(PropTypes.string).isRequired,
  setResponse: PropTypes.func.isRequired,
  currentResponse: PropTypes.object,
  isReadOnly: PropTypes.bool
};
