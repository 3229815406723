import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {useIntl} from 'react-intl';
import {InputAdornment} from '@material-ui/core';
import Warning from '@material-ui/icons/Error';
import FormInput from '../../shared/FormInput/form-input';

import bc from '../../../assets/card_blue.png';
import mc from '../../../assets/card_mastercard.png';
import vc from '../../../assets/card_visa.png';

import styles from './credit-card.module.scss';

export default function CreditCardForm({
  cardError,
  setCardValid,
  setCard,
  numberError,
  dateError = '',
  cryptoError
}) {
  const {formatMessage: f} = useIntl();
  const [number, setNumber] = useState('');
  const [date, setDate] = useState('');
  const [crypto, setCrypto] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const card = number.replace(/\s/g, '');
    const okDate = moment(date, 'MM/YY');
    const isOk = card.length === 16 && okDate && (crypto.length === 3 || crypto.length === 4);
    if (cardError !== error) {
      setError(cardError);
    }

    setCardValid(isOk);
    setCard({number, date, crypto});
  }, [number, date, crypto, cardError]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNoChange = event => {
    const number = event.target.value;
    setNumber(
      number
        .replace(/\s?/g, '')
        .replace(/(\d{4})/g, '$1 ')
        .trim()
    );
  };

  const handleCryptoChange = event => {
    setCrypto(event.target.value);
  };

  const handleDateChange = event => {
    let text = event.target.value;
    if (text.includes('.') || text.length > 5) {
      return;
    }

    if (text.length === 2 && date.length === 1) {
      text += '/';
    }

    setDate(text);
  };

  return (
    <>
      <div className={styles.CardDiv}>
        <div className="flex spaceBetween fullWidth">
          <span data-testid="ccf-text8" className="bold">{f({id: 'paymentInformationTitle'})} </span>
        </div>
        <div>
          <img data-testid="ccf-img1" className={styles.Card} src={vc} alt="visa card"/>
          <img
            data-testid="ccf-img2"
            className={styles.Card}
            src={mc}
            alt="mastercard"
          />
          <img data-testid="ccf-img3" className={styles.Card} src={bc} alt="blue card"/>
        </div>
        <div className={styles.PaymentForm}>
          <div className={styles.PaymentOneLine}>
            <FormInput
              data-testid="ccf-input1"
              label={f({id: 'cardNumberLabel'})}
              type="text"
              placeholder={f({id: 'cardNumberLabel'})}
              InputProps={{
                inputProps: {maxLength: 19},
                endAdornment: (
                  <>
                    {numberError && (
                      <InputAdornment position="end">
                        <Warning className="red"/>
                      </InputAdornment>
                    )}
                  </>
                )
              }}
              error={numberError}
              value={number}
              onChange={handleNoChange}
            />
            {numberError &&
            <span data-testid="ccf-text9" className={styles.ErrorMsg}>{f({id: 'invalidTypedCardNumber'})}</span>}
          </div>

        </div>

        <div className={styles.PaymentLine}>
          <div className={styles.WithInfo}>
            <FormInput
              data-testid="ccf-input2"
              label={f({id: 'cardValidity'})}
              type="text"
              placeholder={f({id: 'cardDate'})}
              error={dateError !== ''}
              value={date}
              InputProps={{
                endAdornment: (
                  <>
                    {dateError && (
                      <InputAdornment position="end">
                        <Warning className="red"/>
                      </InputAdornment>
                    )}
                  </>
                )
              }}
              onChange={handleDateChange}
            />
            {dateError &&
              <span data-testid="ccf-error-2" className={styles.ErrorMsg}>{dateError}</span>}
          </div>
          <div className={styles.WithInfo}>
            <FormInput
              data-testid="ccf-input3"
              type="text"
              label={f({id: 'crypto'})}
              placeholder={f({id: 'crypto'})}
              InputProps={{
                inputProps: {maxLength: 4},
                endAdornment: (
                  <>
                    {cryptoError && (
                      <InputAdornment position="end">
                        <Warning className="red"/>
                      </InputAdornment>
                    )}
                  </>
                )
              }}
              error={cryptoError}
              value={crypto}
              onChange={handleCryptoChange}
            />
            {cryptoError &&
              <span data-testid="ccf-error3" className={styles.ErrorMsg}>{f({id: 'invalidCrypto'})}</span>}
            <span data-testid="ccf-text10" className={styles.InfoMsg}>{f({id: 'seeBehind'})}</span>
          </div>

        </div>
      </div>

      <p style={{fontWeight: 'bold', fontStyle: 'italic'}}>{f({id: 'noSaveBankDetailsInfos'})}</p>
    </>
  );
}
