import React from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedNumber} from 'react-intl';
import {get} from 'lodash';
import moment from 'moment';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link} from 'react-router-dom';

import ArrowBack from '@material-ui/icons/ArrowBack';

import {fetchUserPlans} from '../../../actions/user';
import {fetchWallet} from '../../../actions/payment';

class ActiveServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      token: '',
      tokenPeriod: '',
      wallet: '',
      plans: {},
      intl: {}
    };
    moment.lang(this.props.intl.locale);
  }

  async fetchInfo() {
    const {customerID} = this.state.user;
    const {token} = this.state;
    const wallet = await this.props.fetchWallet({customerID, token});
    const plans = await this.props.fetchUserPlans({customerID, token});
    this.setState({
      wallet: wallet.data,
      plans: plans.data
    });
  }

  componentDidMount() {
    if (this.state.user) {
      this.fetchInfo();
    }
  }

  componentDidUpdate(props) {
    if (props.user && props.user._id !== get(this.state, 'user._id')) {
      this.setState({user: props.user});
    }

    if (props.token !== this.state.token) {
      this.setState({token: props.token});
    }

    if (props.tokenPeriod !== this.state.tokenPeriod) {
      this.setState({tokenPeriod: props.tokenPeriod});
    }

    if (props.intl !== this.state.intl) {
      this.setState({intl: props.intl});
      moment.lang(this.state.intl.locale);
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;
    if (nextProps.user) {
      newProps = newProps ?
        {...newProps, user: nextProps.user} :
        {user: nextProps.user};
    }

    if (nextProps.token) {
      newProps = newProps ?
        {...newProps, token: nextProps.token} :
        {token: nextProps.token};
    }

    if (nextProps.tokenPeriod) {
      newProps = newProps ?
        {...newProps, tokenPeriod: nextProps.tokenPeriod} :
        {tokenPeriod: nextProps.tokenPeriod};
    }

    if (nextProps.intl) {
      newProps = newProps ?
        {...newProps, intl: nextProps.intl} :
        {tokenPeriod: nextProps.intl};
    }

    return newProps;
  }

  render() {
    const {formatMessage: f} = this.props.intl;
    const {wallet} = this.state;
    const plans = get(this.state, 'plans.items', []);

    const items = plans.map((item, index) => (
      <li key={`item-${index}`}>
        <span>{item.label}</span>
      </li>
    ));

    return (
      <div
        className="personalForm"
      >
        <div className="pageActions">
          <Breadcrumbs aria-label="breadcrumb">
            <Link data-testid="active-services-back" color="inherit" to="/personalData">
              {f({id: 'myAccount'})}
            </Link>
            <Link
              data-testid="active-services-current"
              color="textPrimary"
              to="/personalData/myActiveServices"
              aria-current="page"
            >
              {f({id: 'myActiveServices'})}
            </Link>
          </Breadcrumbs>
          <Link data-testid="active-services-smallscreen-back" className="smallScreenBack" to="/personalData">
            <ArrowBack/>
          </Link>
        </div>

        <div className="pageContent">
          <div className="personnalDataShow">
            <div className="data-display">
              <p>
                <span data-testid="active-services-text1" className="bold">{f({id: 'currentWallet'})} </span>
                <span data-testid="active-services-text2" className={wallet >= 0 ? 'green' : 'red'}>
                  <FormattedNumber value={wallet} style="currency" currency={this.props.locale === 'en' ? 'GBP' : 'EUR'}/>
                </span>
              </p>
              {plans.length > 0 && (
                <div>
                  <p data-testid="active-services-text3" className="bold">{f({id: 'currentPlan1'})} {moment.duration(moment.duration(this.state.tokenPeriod).asMilliseconds() * this.state.user.tokens).humanize()} {f({id: 'currentPlan2'})}</p>
                  <ul>{items}</ul>
                </div>
              )}
              {!plans.length && <p data-testid="active-services-text4">{f({id: 'noPlans'})}</p>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: get(state, 'user.user'),
    token: get(state, 'user.token'),
    tokenPeriod: get(state, 'default.hospital.hospital.process.tokenPeriod'),
    locale: get(state, 'default.locale')
  };
};

export default injectIntl(
  connect(mapStateToProps, {fetchWallet, fetchUserPlans})(ActiveServices)
);
