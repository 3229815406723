import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {get} from 'lodash';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Divider from '@material-ui/core/Divider';
import ArrowBack from '@material-ui/icons/ArrowBack';

import {fetchWallet, fetchAvailablePlans, setCommand} from '../../../../actions/payment';
import styles from './supply-account.module.scss';

import SupplyAccountTable from './supply-account-table';
import BlueButton from '../../../blue-button';
import WhiteButton from '../../../white-button';
import SmartBreadCrumbs from '../../../shared/breadcrumbs-smart';

class SupplyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      token: '',
      addToWallet: 0,
      newWallet: 0,
      currentWallet: 0
    };
    this.validateCommand = () => {
      const total = Math.round(this.state.addToWallet * 100) / 100;

      const command = {
        total,
        type: 'supply'
      };
      this.props.setCommand(command);
      this.props.onUpdate('multimedia/paymentValidation');
    };

    this.handleChange = event => {
      let add = event.target.value ? parseFloat(event.target.value) : null;
      if (Math.sign(add) === -1 || Object.is(Math.sign(add), -0)) {
        add *= -1;
      }

      this.setState({
        addToWallet: add,
        newWallet: this.state.currentWallet + add
      });
    };

    this.formatAddToWallet = () => {
      this.setState(state => {
        return {
          addToWallet: state ? state.addToWallet.toFixed(2) : 0
        };
      }
      );
    };
  }

  async fetchInfo() {
    const {customerID} = this.state.user;
    const {token} = this.state;
    fetchAvailablePlans({customerID, token});
    const wallet = await this.props.fetchWallet({customerID, token});
    this.setState({
      currentWallet: wallet.data || 0,
      newWallet: wallet.data || 0
    });
  }

  componentDidMount() {
    if (sessionStorage.getItem('command-step')) {
      sessionStorage.removeItem('command-step');
    }

    if (this.state.user) {
      this.fetchInfo();
    }
  }

  componentDidUpdate(props) {
    if (props.user && props.user._id !== get(this.state, 'user._id')) {
      this.setState({user: props.user});
    }

    if (props.token !== this.state.token) {
      this.setState({token: props.token});
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let newProps = null;
    if (nextProps.user) {
      newProps = newProps ?
        {...newProps, user: nextProps.user} :
        {user: nextProps.user};
    }

    if (nextProps.token) {
      newProps = newProps ?
        {...newProps, token: nextProps.token} :
        {token: nextProps.token};
    }

    return newProps;
  }

  render() {
    const {formatMessage: f} = this.props.intl;

    const {newWallet} = this.state;
    const {currentWallet} = this.state;
    const links = [
      {path: '/multimedia',
        text: 'multimediaBooking'},
      {path: '/multimedia/supplyAccount',
        text: 'supplyAccount'}
    ];

    return (
      <>
        <div className="pageActions">
          <SmartBreadCrumbs links={links}/>
          <Link data-testid="sa-smallscreen-back" className="smallScreenBack" to="/multimedia">
            <ArrowBack/>
          </Link>

        </div>

        <div className="pageContent marginTop">
          <div className="flex column start left">
            <span data-testid="sa-text1" className="textLabel">{f({id: 'supplyAccountInformation'})}</span>
            <br/>
            <div className="bold flex alignCenter">
              <InfoOutlinedIcon data-testid="sa-button1" className="card"/> {f({id: 'askForRefund'})}
            </div>
            <Divider className="fullWidth marginTop"/>

            <SupplyAccountTable
              newWallet={newWallet}
              total={this.state.addToWallet}
              currentWallet={currentWallet}
              onChange={this.handleChange}
              onBlur={this.formatAddToWallet}
            />
            <br/>
            <div className={styles.Actions}>
              <WhiteButton
                data-testid="sa-button2"
                id="validate"
                variant="contained"
                onClick={() => this.props.onUpdate('multimedia')}
              >
                {f({id: 'previous'})}
              </WhiteButton>
              <BlueButton
                data-testid="sa-button3"
                id="validate"
                variant="contained"
                disabled={!this.state.addToWallet}
                onClick={this.validateCommand}
              >
                {f({id: 'next'})}
              </BlueButton>
            </div>

          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: get(state, 'user.user'),
    token: get(state, 'user.token')
  };
};

export default injectIntl(
  connect(mapStateToProps, {fetchWallet, setCommand})(SupplyAccount)
);
