import React from 'react';
import {useIntl, FormattedNumber} from 'react-intl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import {useSelector} from 'react-redux';

export default function SupplyAccountTable(props) {
  const {currentWallet} = props;
  const [newWallet, setNewWallet] = React.useState(0);
  const locale = useSelector(state => state.default.locale);

  React.useEffect(() => {
    setNewWallet(props.newWallet);
  }, [props.newWallet]);

  const {formatMessage: f} = useIntl();

  return (
    <TableContainer component={Paper}>
      <Table className="table" aria-label="customized table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <span data-testid="sat-text1" className="bold">{f({id: 'walletBeforeSupplyBis'})} </span>
            </TableCell>
            <TableCell align="right">
              <span data-testid="sat-text2">
                <FormattedNumber value={currentWallet} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>
              </span>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              <span data-testid="sat-text3" className="bold">
                {f({id: 'supplyAmount'})}
              </span>
            </TableCell>
            <TableCell align="left">
              <TextField
                data-testid="sat-textfield"
                id="supplyAccount"
                type="number"
                value={props.total || ''}
                placeholder="0,00"
                InputProps={{
                  inputProps: {min: 0, step: 0.01},
                  endAdornment: <>€</>
                }}
                onChange={props.onChange}
                onBlur={props.onBlur}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              <span data-testid="sat-text4" className="bold">{f({id: 'walletAfterSupplyBis'})} </span>
            </TableCell>
            <TableCell align="right">
              <span data-testid="sat-text5">
                <FormattedNumber value={newWallet} style="currency" currency={locale === 'en' ? 'GBP' : 'EUR'}/>
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
