import React from 'react';
import Modal from 'react-awesome-modal';

import styles from './contract-iframe.module.scss';

const ContractIframe = ({item, hospID, visible, onClose}) => {
  return (
    <Modal
      visible={visible}
      effect="fadeInUp"
      width="95%"
      height="80%"
      onClickAway={() => onClose()}
    >
      <div className={styles.Modal}>
        {item && item.url && (
          <iframe
            data-testid="contract-iframe1"
            className={styles.IFrame}
            src={item.url}
            type="application/pdf"
          />
        )}
        {item && item.doc && (
          <iframe
            data-testid="contract-iframe2"
            className={styles.IFrame}
            src={item.doc}
            type="application/pdf"
          />
        )}
        {item && !item.doc && item.file && item.type && hospID && (
          <iframe
            data-testid="contract-iframe3"
            className={styles.IFrame}
            src={`/api/hospitals/${hospID}/${item.type}`}
            type="application/pdf"
          />
        )}
      </div>
    </Modal>
  );
};

export default ContractIframe;
