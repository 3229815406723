export const FETCH_CONFIGURATION = 'FETCH_CONFIGURATION';
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
export const SET_HOSPITAL = 'SET_HOSPITAL';
export const SET_HOSPITALS = 'SET_HOSPITALS';
export const CLEAN_STATE = 'CLEAN_STATE';
export const FETCH_HOSPITALS = 'FETCH_HOSPITALS';
export const FETCH_HOSPITALS_DONE = 'FETCH_HOSPITALS_DONE';
export const FETCH_HOSPITALS_FAILED = 'FETCH_HOSPITALS_FAILED';
export const GET_HOSPITAL = 'GET_HOSPITAL';
export const GET_HOSPITAL_DONE = 'GET_HOSPITAL_DONE';
export const GET_HOSPITAL_FAILED = 'GET_HOSPITAL_FAILED';
export const GET_HOSPITALS_BY_GROUP = 'GET_HOSPITALS_BY_GROUP';
export const GET_HOSPITALS_BY_GROUP_DONE = 'GET_HOSPITALS_BY_GROUP_DONE';
export const GET_HOSPITALS_BY_GROUP_FAILED = 'GET_HOSPITALS_BY_GROUP_FAILED';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_APPLICATION_NAME = 'SET_APPLICATION_NAME';
export const SET_TVCOAX_PROCESS = 'SET_TVCOAX_PROCESS';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_BY_TIMEOUT = 'LOGOUT_BY_TIMEOUT';
export const LOGGED = 'LOGGED';
export const FETCH_HOSPID = 'FETCH_HOSPID';
export const HOSPID_NOT_FOUND = 'HOSPID_NOT_FOUND';

// Notifications from websockets
export const HOSPITAL_MODULES_UPDATED = 'HOSPITAL_MODULES_UPDATED';
export const UPDATED_HOSPITAL = 'UPDATED_HOSPITAL';

// Survey
export const LOAD_QUESTIONS = 'LOAD_QUESTIONS';
export const NEXT = 'NEXT';
export const PREV = 'PREV';
export const ANSWER = 'ANSWER';

export const FETCH_SURVEYS = 'FETCH_SURVEYS';
export const FETCH_SURVEYS_DONE = 'FETCH_SURVEYS_DONE';
export const FETCH_SURVEYS_FAILED = 'FETCH_SURVEYS_FAILED';
export const FETCH_SURVEY_RESPONSES = 'FETCH_SURVEY_RESPONSES';
export const FETCH_SURVEY_RESPONSES_DONE = 'FETCH_SURVEY_RESPONSES_DONE';
export const FETCH_SURVEY_RESPONSES_FAILED = 'FETCH_SURVEY_RESPONSES_FAILED';

export const SET_SELECTED_SURVEY = 'SET_SELECTED_SURVEY';
export const SET_RESPONSES_SELECTED_SURVEY = 'SET_RESPONSES_SELECTED_SURVEY';

export const POST_SURVEY_RESPONSES = 'POST_SURVEY_RESPONSES';
export const POST_SURVEY_RESPONSES_FAILED = 'POST_SURVEY_RESPONSES_FAILED';
export const POST_SURVEY_RESPONSES_DONE = 'POST_SURVEY_RESPONSES_DONE';

export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';
