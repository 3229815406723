export default function (state = {}, action = {}) {
  switch (action.type) {
    case 'CLEAN_STATE':
      return {...state, types: []};
    case 'SET_USER_FILES':
      return {...state, userFiles: action.payload.files};
    case 'SET_TYPES':
      return {...state, types: action.payload.types};
    default:
      return state;
  }
}
