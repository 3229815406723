import React from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';

export default function MenuCard(props) {
  const title = props.title || '';
  const icon = props.icon || null;
  const onClick = () => {
    props.onTap();
  };

  return (
    <Card data-testid={`navigation-card-${props.value}`} className="card">
      <CardActionArea
        className="area"
        onClick={onClick}
      >
        <CardContent className="cardContent">
          <Icon>{icon}</Icon>
          {title}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
